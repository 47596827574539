import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule, MatIconModule, MatSnackBarModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { DxButtonModule } from 'devextreme-angular';
import { ResponsiveColsDirective } from '../helpers/directives/responsive-cols-directive';
import { NavCardComponent } from '../layouts/dashboard/tiles/nav-card/nav-card.component';
import { ChangelogModule } from './changelog/changelog.module';
import { PageHeaderModule } from './modules/page-header/page-header.module';
import { SharedComponent } from './shared.component';
import { PermissionsDirective } from '../helpers/directives/permissions.directive';

@NgModule({
  exports: [
    CommonModule,
    TranslateModule,
    PageHeaderModule,
    MatSnackBarModule,
    ChangelogModule,
    NavCardComponent,
    ResponsiveColsDirective,
    PermissionsDirective
  ],
  declarations: [SharedComponent, ResponsiveColsDirective, NavCardComponent, PermissionsDirective

  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    TranslateModule,
    DxButtonModule
  ]
})
export class SharedModule { }
