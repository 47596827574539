import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Inject
} from "@angular/core";
import { DxFormComponent } from "devextreme-angular";
import { CinemaSurveyModel } from "../../../../models/cinema-survey-model";
import { DxGridHeading } from "../../../../helpers/dx-grid-heading";
import { ProjectorModel } from "../../../../models/projector-model";
import { AudioSystemModel } from "../../../../models/audio-system-model";
import { ScreenServerModel } from "../../../../models/screen-server-model";
import { ApiUrlService } from "../../../../shared/services/api-url.service";
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { CinemaRegistrationModel } from "../../../../models/cinema-registration-model";
import { TmsCinemaSystem } from "../../../../models/enums/tms-cinema-system.enum";
import { CashSystems } from "../../../../models/enums/cash-systems.enum";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { PhoneCodeModel } from "../../../../models/phone-code-model";
import { ScreenServerDataModel } from "../../../../models/screen-server-data-model";

@Component({
  selector: "app-cinema-tech-survey-read-only-dialog",
  templateUrl: "./cinema-tech-survey-read-only-dialog.component.html",
  styleUrls: ["./cinema-tech-survey-read-only-dialog.component.scss"]
})
export class CinemaTechSurveyReadOnlyDialogComponent
  implements OnInit, AfterViewInit {
  @ViewChild(DxFormComponent) form: DxFormComponent;

  colCountByScreen: Object;
  public surveyModel: CinemaSurveyModel = new CinemaSurveyModel();

  // Vorwahlen
  phoneCodes: PhoneCodeModel[] = [
    { techPhoneCodeId: 1, phoneCode: "+49", countryCode: "DE" }
  ];
  techPhoneCodeIdMobile: number;
  techPhoneCodeIdLandline: number;
  phoneNumberMobile: string;
  phoneNumberLandline: string;

  // Screen Server

  dataSourceScreenSystem: ScreenServerModel[] = [];
  columnsScreenSystem: DxGridHeading[] = [];

  // Audio system

  dataSourceAudioSystem: AudioSystemModel[] = [];
  columnsAudioSystem: DxGridHeading[] = [];

  // Projector
  dataSource: ProjectorModel[] = [];
  columns: DxGridHeading[] = [];
  states: any[];

  // Screen Ips
  screenServerIPs: ScreenServerDataModel[] = [];
  columnsServerIPs: DxGridHeading[] = [];

  genders: string[];
  gender: string;

  frameRates = [
    { runType: "24-30 fps", label: "24-30 fps" },
    { runType: "48 fps", label: "48 fps" },
    { runType: ">= 60 fps", label: ">= 60 fps" }
  ];

  private techCinemaRegistrationsId;

  isDifferingShippingAddressMBOX: number;

  public isVpnUsage: number;
  public isVlanUsage: number;
  public isApiAvailable: number;

  // M-Box
  checkBoxOptions: any;
  deliveryMBoxOptions: any;
  public showMBOX: boolean;
  public vpnUsageOptions: any;
  public vlanUsageOptions: any;
  public internalNetworkStructureOptions: any;
  public existingApiOptions: any;

  constructor(
    public urlApiService: ApiUrlService,
    public httpClient: HttpClient,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<CinemaTechSurveyReadOnlyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.techCinemaRegistrationsId = data.techCinemaRegistrationsId;
    this.checkBoxOptions = {
      caption: this.translateService.instant(
        "Formular für weischerPLAY M Installation anzeigen"
      ),
      onValueChanged: e => {
        this.showMBOX = e.value;
      }
    };

    this.deliveryMBoxOptions = {
      displayExpr: "name",
      valueExpr: "id",
      dataSource: [
        { id: 1, name: this.translateService.instant("Wie Kinoadresse") },
        { id: 0, name: this.translateService.instant("Sonstige Adresse") }
      ],
      onValueChanged: e => {
        this.isDifferingShippingAddressMBOX = e.value;
      }
    };

    this.vpnUsageOptions = {
      displayExpr: "name",
      valueExpr: "id",
      dataSource: [
        { id: 1, name: this.translateService.instant("Ja") },
        { id: 0, name: this.translateService.instant("Nein") }
      ],
      onValueChanged: e => {
        this.isVpnUsage = e.value;
      }
    };

    this.vlanUsageOptions = {
      displayExpr: "name",
      valueExpr: "id",
      dataSource: [
        { id: 1, name: this.translateService.instant("Ja") },
        { id: 0, name: this.translateService.instant("Nein") }
      ],
      onValueChanged: e => {
        this.isVlanUsage = e.value;
      }
    };

    this.internalNetworkStructureOptions = {
      displayExpr: "name",
      valueExpr: "id",
      dataSource: [
        {
          id: 1,
          name: this.translateService.instant("Gemeinsames Netzwerk")
        },
        {
          id: 0,
          name: this.translateService.instant("Getrenntes Netzwerk")
        }
      ]
    };

    this.existingApiOptions = {
      displayExpr: "name",
      valueExpr: "id",
      dataSource: [
        { id: 1, name: this.translateService.instant("Ja") },
        { id: 0, name: this.translateService.instant("Nein") }
      ],
      onValueChanged: e => {
        this.isApiAvailable = e.value;
      }
    };
  }

  ngOnInit() {
    this.initPhoneCodes();
    this.updateGrid();
  }

  // initialisiert die Vorwahl Dropdowns
  initPhoneCodes() {
    // Daten vom Endpunkt holen
    this.httpClient
      .get(this.urlApiService.getPhoneCodesUrl())
      .subscribe(result => {
        this.phoneCodes = result as PhoneCodeModel[];
      });

    // Dropdowns auf den ersten Wert setzen, bzw. den Eintrag mit der ID 1
    if (!this.techPhoneCodeIdLandline) {
      this.techPhoneCodeIdMobile = 1;
    }

    if (!this.techPhoneCodeIdLandline) {
      this.techPhoneCodeIdLandline = 1;
    }
  }

  requestData() {
    this.httpClient
      .post(
        this.urlApiService.getCinemaRegistrationById(),
        this.techCinemaRegistrationsId
      )
      .subscribe(
        data => {
          const cinemaRegistrationModel = data[
            "cinemaRegistrationData"
          ] as CinemaRegistrationModel;

          // Show MBOX
          this.surveyModel.showWeischerPlayM =
            cinemaRegistrationModel.showWeischerPlayM;

          // Misc Info
          this.surveyModel.cinemaName = cinemaRegistrationModel.name;
          this.surveyModel.theaterNr = parseInt(
            cinemaRegistrationModel.theaterNr,
            10
          );
          this.surveyModel.companyOperator =
            cinemaRegistrationModel.companyOperatorName;
          this.surveyModel.cinemaGroup =
            cinemaRegistrationModel.cinemaGroupName;
          this.surveyModel.location = cinemaRegistrationModel.location;
          this.surveyModel.screenCount = cinemaRegistrationModel.screenCount;
          this.surveyModel.serviceProvider =
            cinemaRegistrationModel.technicalServiceProviderName;
          this.surveyModel.contactServiceProvider =
            cinemaRegistrationModel.technicalServiceProviderContact;
          this.surveyModel.contactWeischerMedia =
            cinemaRegistrationModel.weischerContact;

          // contact person
          this.surveyModel.gender =
            cinemaRegistrationModel.cinemaTechContactSex === "Herr" ? 0 : 1;
          this.surveyModel.firstName =
            cinemaRegistrationModel.cinematechContactFirstName;
          this.surveyModel.lastName =
            cinemaRegistrationModel.cinematechContactLastName;
          this.surveyModel.mail =
            cinemaRegistrationModel.cinematechContactEmail;

          this.surveyModel.phone =
            cinemaRegistrationModel.cinemaTechContactTelephone;
          this.surveyModel.techPhoneCodeIdLandline =
            cinemaRegistrationModel.techPhoneCodeIdLandline;
          this.surveyModel.techPhoneCodeIdMobile =
            cinemaRegistrationModel.techPhoneCodeIdMobile;
          this.surveyModel.mobilePhone =
            cinemaRegistrationModel.cinemaTechContactMobile;

          this.techPhoneCodeIdLandline = this.surveyModel.techPhoneCodeIdLandline;
          this.techPhoneCodeIdMobile = this.surveyModel.techPhoneCodeIdMobile;
          this.phoneNumberLandline = this.surveyModel.phone;
          this.phoneNumberMobile = this.surveyModel.mobilePhone;

          // internet
          switch (cinemaRegistrationModel.downstream) {
            case "<16 Mbit/s":
              this.surveyModel.bitrate = 0;
              break;
            case "16-50 Mbit/s":
              this.surveyModel.bitrate = 1;
              break;
            case ">50 Mbit/s":
              this.surveyModel.bitrate = 2;
              break;
            default:
              this.surveyModel.bitrate = 0;
              break;
          }

          // network
          switch (cinemaRegistrationModel.networkSpeed) {
            case "100 Mbit/s":
              this.surveyModel.networkSpeed = 0;
              break;
            case "1 Gbit/s":
              this.surveyModel.networkSpeed = 1;
              break;
            case ">1 Gbit/s":
              this.surveyModel.networkSpeed = 2;
              break;
            case "Die Komponenten sind nicht miteinander vernetzt":
              this.surveyModel.networkSpeed = 3;
              break;
            default:
              this.surveyModel.networkSpeed = 3;
              break;
          }

          // Mitbenutzung
          if (cinemaRegistrationModel.sharedUseDownstream) {
            this.surveyModel.jointUsage =
              cinemaRegistrationModel.sharedUseDownstream;
          }

          // Internetnutzung
          this.surveyModel.internetUsage = cinemaRegistrationModel.sharedDownStream
            ? 0
            : 1;

          // Cinema System TMS
          this.surveyModel.tms = TmsCinemaSystem[cinemaRegistrationModel.tms];
          this.surveyModel.softwareVersion =
            cinemaRegistrationModel.tmsSoftwareVersion;
          this.surveyModel.otherTms = cinemaRegistrationModel.tmsOther;

          // Cinema System Kassensystem
          this.surveyModel.accounting =
            CashSystems[cinemaRegistrationModel.posSystem];
          this.surveyModel.accountingOther =
            cinemaRegistrationModel.posSystemOther;
          // Screen Servers
          this.dataSourceScreenSystem =
            cinemaRegistrationModel.screenEquipment.screenServers;

          // Screen IPs
          this.screenServerIPs =
            cinemaRegistrationModel.screenEquipment.screenIps;

          this.dataSourceAudioSystem =
            cinemaRegistrationModel.screenEquipment.audioSystems;

          this.dataSource = cinemaRegistrationModel.screenEquipment.projectors;

          this.surveyModel.notes =
            cinemaRegistrationModel.additionalInformation;

          // MBOX

          this.surveyModel.deliverySelectionMBOX =
            cinemaRegistrationModel.deliverySelectionMBOX;
          this.isDifferingShippingAddressMBOX =
            cinemaRegistrationModel.deliverySelectionMBOX;
          this.surveyModel.companyNameMBOX =
            cinemaRegistrationModel.companyNameMBOX;
          this.surveyModel.contactPartnerMBOX =
            cinemaRegistrationModel.contactPartnerMBOX;
          this.surveyModel.deliveryAddressStreetMBOX =
            cinemaRegistrationModel.deliveryAddressStreetMBOX;
          this.surveyModel.deliveryAddressStreetCodeMBOX =
            cinemaRegistrationModel.deliveryAddressStreetCodeMBOX;
          this.surveyModel.deliveryAddressCountryMBOX =
            cinemaRegistrationModel.deliveryAddressCountryMBOX;
          this.surveyModel.existingAPI = cinemaRegistrationModel.existingAPI;
          this.surveyModel.cashSystemUrl =
            cinemaRegistrationModel.cashSystemUrl;
          this.surveyModel.cashSystemPassword =
            cinemaRegistrationModel.cashSystemPassword;
          this.surveyModel.downloadSpeedResult =
            cinemaRegistrationModel.downloadSpeedResult;
          this.surveyModel.uploadSpeedResult =
            cinemaRegistrationModel.uploadSpeedResult;
          this.surveyModel.pingSpeedResult =
            cinemaRegistrationModel.pingSpeedResult;
          this.surveyModel.internalNetworkStructure =
            cinemaRegistrationModel.internalNetworkStructure;
          this.surveyModel.ipMBoxServer = cinemaRegistrationModel.ipMBoxServer;
          this.surveyModel.subnetMask = cinemaRegistrationModel.subnetMask;
          this.surveyModel.defaultGateway =
            cinemaRegistrationModel.defaultGateway;
          this.surveyModel.dnsServer = cinemaRegistrationModel.dnsServer;
          this.surveyModel.vlanUsage = cinemaRegistrationModel.vlanUsage;
          this.surveyModel.vlanTopo = cinemaRegistrationModel.vlanTopo;
          this.surveyModel.vpnUsage = cinemaRegistrationModel.vpnUsage;
          this.surveyModel.vpnDescription =
            cinemaRegistrationModel.vpnDescription;
        },
        err => {
          console.log(err.error.messageFromSpotlog);
        }
      );
  }

  updateGrid() {
    this.columns.length = 0;
    this.columns.push(
      { caption: this.translateService.instant("Saal"), dataField: "screen" },
      { caption: this.translateService.instant("Name"), dataField: "name" },
      {
        caption: this.translateService.instant("2k"),
        dataField: "resolution2k",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("4k"),
        dataField: "resolution4k",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("3D-Fähigkeit"),
        dataField: "ready3D",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("IMAX"),
        dataField: "readyImax",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("Bildwechselfrequenz"),
        dataField: "fps",
        lookup: {
          valueExpr: "runType",
          displayExpr: "label",
          dataSource: this.frameRates
        }
      },
      {
        caption: this.translateService.instant(
          "Sonstige saalspezifische Installationen"
        ),
        dataField: "additionalInformation"
      }
    );

    this.columnsScreenSystem.length = 0;
    this.columnsScreenSystem.push(
      { caption: this.translateService.instant("Saal"), dataField: "screen" },
      { caption: this.translateService.instant("Name"), dataField: "name" },
      {
        caption: this.translateService.instant("DOLBY"),
        dataField: "dolby",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("DOLBY IMS 2000"),
        dataField: "dolbyIMS2000",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("SONY R320"),
        dataField: "sonyR320",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("SONY R515"),
        dataField: "sonyR515",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("doremi"),
        dataField: "doremi",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("NEC IMS 2000"),
        dataField: "necIMS2000",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("Barco Alchemy"),
        dataField: "barcoAlchemy",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("GDC"),
        dataField: "gdc",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("Sonstige"),
        dataField: "others"
      }
    );

    this.columnsAudioSystem.length = 0;
    this.columnsAudioSystem.push(
      { caption: this.translateService.instant("Saal"), dataField: "screen" },
      { caption: this.translateService.instant("Name"), dataField: "name" },
      {
        caption: this.translateService.instant("5.1 Surround"),
        dataField: "surround_5_1",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("7.1 Surround"),
        dataField: "surround_7_1",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("Dolby-Atmos"),
        dataField: "dolbyAtmos",
        dataType: "boolean"
      }
    );

    this.columnsServerIPs.length = 0;
    this.columnsServerIPs.push(
      { caption: this.translateService.instant("Saal"), dataField: "screen" },
      {
        caption: this.translateService.instant(
          "IP Adresse/Hostname Kinoserver"
        ),
        dataField: "ip"
      }
    );
  }

  ngAfterViewInit(): void {
    this.requestData();
  }
}
