import { Aggregators } from './../../layouts/administration/tiles/aggregator-start/aggregators.enum';
import { Injectable } from '@angular/core';
import { HttpResponse, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { ApiUrlService } from '../../shared/services/api-url.service';
import { LoadingSpinnerService } from '../../shared/services/loading-spinner.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {

    public pendingRequests = 0;

    constructor(private spinnerService: LoadingSpinnerService, private urlService: ApiUrlService) { }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url === this.urlService.startAggregator(Aggregators.BookingData) || req.url === this.urlService.startAggregator(Aggregators.ContentCatalog)
            || req.url === this.urlService.startAggregator(Aggregators.CinemaMasterData) || req.url === this.urlService.startAggregator(Aggregators.User)
            || req.url === this.urlService.getVersionCheck() || req.url === this.urlService.getCreateMultipleReport()) {
            return next.handle(req);
        }
        this.spinnerService.show();
        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                this.spinnerService.hide();
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                this.spinnerService.hide();
            }
        }));
    }
}


