export enum TranslationBlock {
    'Einlass' = 20,
    'Werbung Kinobetreiber  / WW' = 30,
    'Event stumm' = 31,
    'Werbung Kinobetreiber' = 40,
    'Werbung I' = 50,
    'Werbung I / DE' = 53,
    'Werbung II' = 60,
    'Event tönend' = 61,
    'Eis 1' = 62,
    'Filmtrailer II' = 64,
    'Eis 2' = 68,
    'Brille auf Spot' = 70,
    'Werbung III' = 80,
    'Eis 3 (3D) Flat/Scope' = 82,
    'Filmtrailer III 3D' = 84,
    'Eis 4 (3D) Flat/Scope' = 88,
    'PrePresenter / Presenter' = 90,
    'PrePresenter /Presenter' = 100,
    'SaalPresenter 2D' = 101,
    'SaalPresenter 3D' = 102,
    'KinobetreiberLogo 2D' = 110,
    'KinobetreiberLogo 3D' = 120,
    'Pause01_Intro' = 130,
    'Pause02_Presenter_Intro' = 140,
    'Pause03_Werbeblock_1' = 150,
    'Pause04_Unterhaltung_mit_Ton' = 160,
    'Pause05_Unterhaltung_ohne_Ton' = 165,
    'Pause06_kitag_ohne_Ton' = 170,
    'Pause07_Countdown_ohne_Ton' = 175,
    'Pause08_Countdown_mit_Ton' = 180,
    'Pause09_Presenter_Outro' = 185,
    'Pause10_Outro' = 190
}
