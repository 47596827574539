import { ScreenServerDataModel } from "./../../../../models/screen-server-data-model";
import { CashSystems } from "./../../../../models/enums/cash-systems.enum";
import { ProjectorModel } from "./../../../../models/projector-model";
import { AudioSystemModel } from "./../../../../models/audio-system-model";
import { ScreenServerModel } from "./../../../../models/screen-server-model";
import { ApiUrlService } from "./../../../../shared/services/api-url.service";
import { CinemaSurveyModel } from "./../../../../models/cinema-survey-model";
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  AfterViewInit
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import notify from "devextreme/ui/notify";
import { DxFormComponent } from "devextreme-angular";
import { HttpClient } from "@angular/common/http";
import { CinemaRegistrationModel } from "../../../../models/cinema-registration-model";
import { ScreenEquipmentModel } from "../../../../models/screen-equipment-model";
import { TmsCinemaSystem } from "../../../../models/enums/tms-cinema-system.enum";
import { DxGridHeading } from "../../../../helpers/dx-grid-heading";
import { PhoneCodeModel } from "../../../../models/phone-code-model";

@Component({
  selector: "app-cinema-survey-dialog",
  templateUrl: "./cinema-survey-dialog.component.html",
  styleUrls: ["./cinema-survey-dialog.component.scss"]
})
export class CinemaSurveyDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(DxFormComponent) form: DxFormComponent;

  colCountByScreen: Object;
  public surveyModel: CinemaSurveyModel = {
    screenCount: 1,
    techPhoneCodeIdLandline: 1,
    techPhoneCodeIdMobile: 1
  };

  // Vorwahlen
  phoneCodes: PhoneCodeModel[] = [
    { techPhoneCodeId: 1, phoneCode: "+49", countryCode: "DE" }
  ];
  techPhoneCodeIdMobile: number;
  techPhoneCodeIdLandline: number;
  phoneNumberMobile: string;
  phoneNumberLandline: string;

  // Screen Server

  dataSourceScreenSystem: ScreenServerModel[] = [];
  columnsScreenSystem: DxGridHeading[] = [];

  // Audio system

  dataSourceAudioSystem: AudioSystemModel[] = [];
  columnsAudioSystem: DxGridHeading[] = [];

  // Projector
  public dataSource: ProjectorModel[] = [];
  public columns: DxGridHeading[] = [];
  public states: any[];

  genders: string[];
  gender: string;

  // Screen Ips
  public screenServerIPs: ScreenServerDataModel[] = [];
  public columnsServerIPs: DxGridHeading[] = [];

  //Kinosysteme TMS
  public showSoftwareVersionText: boolean;
  public showOtherTMSText: boolean;

  public showMBOX;

  public buttonOptions: any = {
    text: this.translateService.instant("Senden"),
    type: "success",
    useSubmitBehavior: true,
    width: 150
  };

  public frameRates = [
    { runType: "24-30 fps", label: "24-30 fps" },
    { runType: "48 fps", label: "48 fps" },
    { runType: ">= 60 fps", label: ">= 60 fps" }
  ];

  public projectorManufacteurs = [
    { runType: "Barco", label: "Barco" },
    { runType: "Kinoton", label: "Kinoton" },
    { runType: "Christie", label: "Christie" },
    { runType: "NEC", label: "NEC" },
    { runType: "Sony", label: "Sony" }
  ];

  public onlyDigitsPattern: any = "^[0-9]*$";
  public theaterNrPattern: any = "^[0-9]{6}$";

  public isDifferingShippingAddressMBOX: number;
  public isVpnUsage: number;
  public isVlanUsage: number;
  public isApiAvailable: number;

  // M-Box Select Options
  public deliveryMBoxOptions: any;
  public vpnUsageOptions: any;
  public vlanUsageOptions: any;
  public internalNetworkStructureOptions: any;
  public existingApiOptions: any;

  constructor(
    public urlApiService: ApiUrlService,
    public httpClient: HttpClient,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<CinemaSurveyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.showMBOX = data;

    this.dialogRef.disableClose = true;

    this.colCountByScreen = {
      md: 4,
      sm: 2
    };

    this.genders = ["female", "male"];

    this.deliveryMBoxOptions = {
      displayExpr: "name",
      valueExpr: "id",
      dataSource: [
        { id: 1, name: this.translateService.instant("Wie Kinoadresse") },
        { id: 0, name: this.translateService.instant("Sonstige Adresse") }
      ],
      onValueChanged: e => {
        this.isDifferingShippingAddressMBOX = e.value;
      }
    };

    this.vpnUsageOptions = {
      displayExpr: "name",
      valueExpr: "id",
      dataSource: [
        { id: 1, name: this.translateService.instant("Ja") },
        { id: 0, name: this.translateService.instant("Nein") }
      ],
      onValueChanged: e => {
        this.isVpnUsage = e.value;
      }
    };

    this.vlanUsageOptions = {
      displayExpr: "name",
      valueExpr: "id",
      dataSource: [
        { id: 1, name: this.translateService.instant("Ja") },
        { id: 0, name: this.translateService.instant("Nein") }
      ],
      onValueChanged: e => {
        this.isVlanUsage = e.value;
      }
    };

    this.internalNetworkStructureOptions = {
      displayExpr: "name",
      valueExpr: "id",
      dataSource: [
        {
          id: 1,
          name: this.translateService.instant("Gemeinsames Netzwerk")
        },
        {
          id: 0,
          name: this.translateService.instant("Getrenntes Netzwerk")
        }
      ]
    };

    this.existingApiOptions = {
      displayExpr: "name",
      valueExpr: "id",
      dataSource: [
        { id: 1, name: this.translateService.instant("Ja") },
        { id: 0, name: this.translateService.instant("Nein") }
      ],
      onValueChanged: e => {
        this.isApiAvailable = e.value;
      }
    };
  }

  ngOnInit() {
    this.initPhoneCodes();
    this.updateGrid();
    this.updateGridMatrix(1);
  }

  ngAfterViewInit(): void {
    /*setTimeout(() => {
      this.form.instance.getEditor("deliverySelectionMBOX").option("value", 1);
    });*/
  }

  // initialisiert die Vorwahl Dropdowns
  initPhoneCodes() {
    // Daten vom Endpunkt holen
    this.httpClient
      .get(this.urlApiService.getPhoneCodesUrl())
      .subscribe(result => {
        this.phoneCodes = result as PhoneCodeModel[];
      });

    // Dropdowns auf den ersten Wert setzen, bzw. den Eintrag mit der ID 1
    this.techPhoneCodeIdMobile = 1;
    this.techPhoneCodeIdLandline = 1;
  }

  updateGrid() {
    this.columns.length = 0;
    this.columns.push(
      { caption: this.translateService.instant("Saal"), dataField: "screen" },
      { caption: this.translateService.instant("Name"), dataField: "name" },
      {
        caption: this.translateService.instant("2k"),
        dataField: "resolution2k",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("4k"),
        dataField: "resolution4k",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("3D-Fähigkeit"),
        dataField: "ready3D",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("IMAX"),
        dataField: "readyImax",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("Bildwechselfrequenz"),
        dataField: "fps",
        lookup: {
          valueExpr: "runType",
          displayExpr: "label",
          dataSource: this.frameRates,
          value: { runType: "24-30 fps", label: "24-30 fps" }
        }
      },
      {
        caption: this.translateService.instant("Hersteller Projektor"),
        dataField: "projectorManufacteur",
        lookup: {
          valueExpr: "runType",
          displayExpr: "label",
          dataSource: this.projectorManufacteurs,
          value: { runType: "Barco", label: "Barco" }
        }
      },
      {
        caption: this.translateService.instant(
          "Sonstige saalspezifische Installationen"
        ),
        dataField: "additionalInformation"
      }
    );

    this.columnsScreenSystem.length = 0;
    this.columnsScreenSystem.push(
      { caption: this.translateService.instant("Saal"), dataField: "screen" },
      { caption: this.translateService.instant("Name"), dataField: "name" },
      {
        caption: this.translateService.instant("DOLBY"),
        dataField: "dolby",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("DOLBY IMS 2000"),
        dataField: "dolbyIMS2000",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("SONY R320"),
        dataField: "sonyR320",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("SONY R515"),
        dataField: "sonyR515",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("doremi"),
        dataField: "doremi",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("NEC IMS 2000"),
        dataField: "necIMS2000",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("Barco Alchemy"),
        dataField: "barcoAlchemy",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("GDC"),
        dataField: "gdc",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("Sonstige"),
        dataField: "others"
      }
    );

    this.columnsAudioSystem.length = 0;
    this.columnsAudioSystem.push(
      { caption: this.translateService.instant("Saal"), dataField: "screen" },
      { caption: this.translateService.instant("Name"), dataField: "name" },
      {
        caption: this.translateService.instant("5.1 Surround"),
        dataField: "surround_5_1",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("7.1 Surround"),
        dataField: "surround_7_1",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("Dolby-Atmos"),
        dataField: "dolbyAtmos",
        dataType: "boolean"
      }
    );

    this.columnsServerIPs.length = 0;
    this.columnsServerIPs.push(
      { caption: this.translateService.instant("Saal"), dataField: "screen" },
      {
        caption: this.translateService.instant(
          "IP Adresse/Hostname Kinoserver"
        ),
        dataField: "ip"
      }
    );
  }

  screen(width) {
    return width < 720 ? "sm" : "md";
  }
  valueChanged(e) {
    if (e.value) {
      this.colCountByScreen = {
        md: 4,
        sm: 2
      };
    } else {
      this.colCountByScreen = null;
    }
    //this.form.instance.repaint();
  }

  onTMSChanged(e) {
    if (e.value === 7) {
      this.showOtherTMSText = true;
      this.showSoftwareVersionText = false;
    } else if (e.value === 8) {
      this.showOtherTMSText = false;
      this.showSoftwareVersionText = false;
    } else {
      this.showSoftwareVersionText = true;
      this.showOtherTMSText = false;
    }
    //this.form.instance.repaint();
  }

  onFormSubmit(e) {
    let formData = this.form.formData as CinemaSurveyModel;
    let request: CinemaRegistrationModel = new CinemaRegistrationModel();

    // Misc Info
    request.name = formData.cinemaName;
    if (formData.theaterNr) {
      request.theaterNr = formData.theaterNr.toString();
    }
    request.companyOperatorName = formData.companyOperator;
    request.cinemaGroupName = formData.cinemaGroup;
    request.location = formData.location;
    request.screenCount = formData.screenCount;
    request.technicalServiceProviderName = formData.serviceProvider;
    request.technicalServiceProviderContact = formData.contactServiceProvider;
    request.weischerContact = formData.contactWeischerMedia;

    // contact person
    request.cinemaTechContactSex = formData.gender === 0 ? "Herr" : "Frau";
    request.cinematechContactFirstName = formData.firstName;
    request.cinematechContactLastName = formData.lastName;

    request.cinemaTechContactTelephone = this.phoneNumberLandline;
    request.cinemaTechContactMobile = this.phoneNumberMobile;
    request.techPhoneCodeIdLandline = this.techPhoneCodeIdLandline;
    request.techPhoneCodeIdMobile = this.techPhoneCodeIdMobile;

    request.cinematechContactEmail = formData.mail;

    // internet
    switch (formData.bitrate) {
      case 0:
        request.downstream = "<16 Mbit/s";
        break;
      case 1:
        request.downstream = "16-50 Mbit/s";
        break;
      case 2:
        request.downstream = ">50 Mbit/s";
        break;
      default:
        break;
    }

    // network
    switch (formData.networkSpeed) {
      case 0:
        request.networkSpeed = "100 Mbit/s";
        break;
      case 1:
        request.networkSpeed = "1 Gbit/s";
        break;
      case 2:
        request.networkSpeed = ">1 Gbit/s";
        break;
      case 3:
        request.networkSpeed =
          "Die Komponenten sind nicht miteinander vernetzt";
        break;
      default:
        request.networkSpeed = "Keine Angabe";
        break;
    }

    // Internetnutzung
    if (formData.internetUsage === 0 || formData.internetUsage === 1) {
      request.sharedDownStream = formData.internetUsage === 0 ? true : false;
    }

    // Mitbenutzung
    if (formData.jointUsage) {
      request.sharedUseDownstream = formData.jointUsage;
    }

    // Cinema System TMS

    request.tms = TmsCinemaSystem[formData.tms];
    request.tmsSoftwareVersion = formData.softwareVersion;
    request.tmsOther = formData.otherTms;

    // Cinema System Kassensystem

    request.posSystem = CashSystems[formData.accounting];
    request.posSystemOther = formData.accountingOther;

    request.screenEquipment = new ScreenEquipmentModel();

    //Screen Server

    request.screenEquipment.screenServers = this.dataSourceScreenSystem;

    // Audio System

    request.screenEquipment.audioSystems = this.dataSourceAudioSystem;

    // Projectors
    request.screenEquipment.projectors = this.dataSource;

    // Screen Server Data
    request.screenEquipment.screenIps = this.screenServerIPs;

    request.additionalInformation = formData.notes;

    // MBOX
    request.showWeischerPlayM = this.showMBOX;
    request.deliverySelectionMBOX = formData.deliverySelectionMBOX;
    request.companyNameMBOX = formData.companyNameMBOX;
    request.contactPartnerMBOX = formData.contactPartnerMBOX;
    request.deliveryAddressStreetMBOX = formData.deliveryAddressStreetMBOX;
    request.deliveryAddressStreetCodeMBOX =
      formData.deliveryAddressStreetCodeMBOX;
    request.deliveryAddressCountryMBOX = formData.deliveryAddressCountryMBOX;
    request.existingAPI = formData.existingAPI;
    request.cashSystemUrl = formData.cashSystemUrl;
    request.cashSystemPassword = formData.cashSystemPassword;
    request.downloadSpeedResult = formData.downloadSpeedResult;
    request.uploadSpeedResult = formData.uploadSpeedResult;
    request.pingSpeedResult = formData.pingSpeedResult;
    request.internalNetworkStructure = formData.internalNetworkStructure;
    request.ipMBoxServer = formData.ipMBoxServer;
    request.subnetMask = formData.subnetMask;
    request.defaultGateway = formData.defaultGateway;
    request.dnsServer = formData.dnsServer;
    request.vlanUsage = formData.vlanUsage;
    request.vlanTopo = formData.vlanTopo;
    request.vpnUsage = formData.vpnUsage;
    request.vpnDescription = formData.vpnDescription;

    this.httpClient
      .post(this.urlApiService.getCinemaRegistrationUrl(), request)
      .subscribe(
        response => {
          this.form.instance.resetValues();
          this.surveyModel.screenCount = 1;
          this.updateGridMatrix(1);
          notify(
            {
              message: this.translateService.instant(
                "Ihre Daten wurden erfolgreich gespeichert. Danke!"
              ),
              position: {
                my: "center top",
                at: "center top"
              }
            },
            "success",
            3000
          );
        },
        err => {
          notify(
            {
              message: this.translateService.instant(
                "Das Kino muss mindestens einen Saal haben."
              ),
              position: {
                my: "center top",
                at: "center top"
              }
            },
            "error",
            3000
          );
          console.log("Error occured." + err);
        }
      );
    e.preventDefault();
  }

  form_fieldDataChanged(e) {
    if (e.dataField == "screenCount") {
      if (e.value > 0) {
        this.updateGridMatrix(e.value);
      }
    }
  }

  updateGridMatrix(screenCount: number) {
    this.dataSource = [];
    this.dataSourceScreenSystem = [];
    this.dataSourceAudioSystem = [];
    this.screenServerIPs = [];

    for (let index = 1; index <= screenCount; index++) {
      const element: ProjectorModel = new ProjectorModel();
      element.screen = index;
      element.ready3D = false;
      element.readyImax = false;
      element.resolution2k = true;
      element.resolution4k = false;
      element.fps = "24-30 fps";
      element.projectorManufacteur = "Barco";
      this.dataSource.push(element);

      const screenServerModel: ScreenServerModel = new ScreenServerModel();
      screenServerModel.barcoAlchemy = false;
      screenServerModel.dolby = false;
      screenServerModel.dolbyIMS2000 = false;
      screenServerModel.doremi = false;
      screenServerModel.gdc = false;
      screenServerModel.necIMS2000 = false;
      screenServerModel.sonyR320 = false;
      screenServerModel.sonyR515 = false;
      screenServerModel.screen = index;
      this.dataSourceScreenSystem.push(screenServerModel);

      const audioSystemModel: AudioSystemModel = new AudioSystemModel();
      audioSystemModel.screen = index;
      audioSystemModel.dolbyAtmos = false;
      audioSystemModel.surround_5_1 = true;
      audioSystemModel.surround_7_1 = false;
      this.dataSourceAudioSystem.push(audioSystemModel);

      const screenIPs: ScreenServerDataModel = new ScreenServerDataModel();
      screenIPs.screen = index;
      this.screenServerIPs.push(screenIPs);
    }
  }

  close() {
    this.dialogRef.close();
  }

  public onInputValueChanged(event: any, targetVar: string) {
    if (event.value) {
      this[targetVar] = event.value;
    }
  }
}
