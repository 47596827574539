export enum TranslationStarttimePlayout {
  'wholeDay' = 1,
  'Ab 18 Uhr' = 2,
  'Vor 18:00 Uhr' = 3,
  'Ab 20:00 Uhr' = 4,
  'Vor 20:00 Uhr' = 5,
  'Fulltime' = 6,
  'Daytime' = 7,
  'Pre-Primetime' = 8,
  'Primetime' = 9
}
