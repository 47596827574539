import { PortalRunResultBookingDataModel } from './../../../../models/portal-run-result-booking-data-model';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ApiUrlService } from '../../../../shared/services/api-url.service';
import { HttpClient } from '@angular/common/http';
import { DxGridHeading } from '../../../../helpers/dx-grid-heading';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'app-aggregator-run-results-details-dialog',
  templateUrl: './aggregator-run-results-details-dialog.component.html',
  styleUrls: ['./aggregator-run-results-details-dialog.component.scss']
})
export class AggregatorRunResultsDetailsDialogComponent implements OnInit {

  columns: DxGridHeading[] = [];
  data: PortalRunResultBookingDataModel[] = [];
  techLastRunsId: any;
  excelExportFileName = moment().format('YYYY-MM-DD HH:mm') + '_' + 'AggregatorRunResultsDetails' + '_' + this.techLastRunsId;
  annotationText: string;


  constructor(public urlApiService: ApiUrlService, public httpClient: HttpClient, private translateService: TranslateService, public dialogRef: MatDialogRef<AggregatorRunResultsDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) public result: any) {
    this.techLastRunsId = result.techLastRunsId;
    this.annotationText = result.techLastRunsId;
  }

  ngOnInit() {
    this.updateGrid();
    this.refreshView();
  }

  refreshView() {
    this.httpClient.post(this.urlApiService.getRunResults(), this.techLastRunsId).subscribe(
      data => {
        const result = data as PortalRunResultBookingDataModel[];
        this.data = result;
      }
    );
  }

  updateGrid() {
    this.columns.length = 0;
    this.columns.push(
      { caption: this.translateService.instant('TheaterNr'), dataField: 'theaterNr' },
      { caption: this.translateService.instant('Name'), dataField: 'name1' },
      { caption: this.translateService.instant('Postleitzahl'), dataField: 'zipCode' },
      { caption: this.translateService.instant('Ort'), dataField: 'city' },
      { caption: this.translateService.instant('Straße'), dataField: 'street' },
      { caption: this.translateService.instant('Endmarkt'), dataField: 'country' },
      { caption: this.translateService.instant('BL Erfolg'), dataField: 'bookingsSuccessful' },
      { caption: this.translateService.instant('EA Erfolg'), dataField: 'firstPlayoutsSuccessful' },
      { caption: this.translateService.instant('Anz. Filmb.'), dataField: 'featureExclusiveBookingCount' },
      { caption: this.translateService.instant('Anz. Saalb.'), dataField: 'screenBookingCount' },
      { caption: this.translateService.instant('Anz. gel. EA'), dataField: 'firstPlayoutDeletedCount' },
      { caption: this.translateService.instant('Anz. EA'), dataField: 'firstPlayoutInsertedCount' },
      { caption: this.translateService.instant('Zus. Info'), dataField: 'additionalInfo' },
    );

  }
}


