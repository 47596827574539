import { PermissionsService } from './../shared/services/permissions.service';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class RequestErrorHandler {

  constructor(public snackbar: MatSnackBar, public permissionsService: PermissionsService) { }

  public handleError(err: any) {
    if (this.permissionsService.isPermittedToAccessActions) {
      if (err.error && err.error.messageFromSpotlog) {
        this.snackbar.open(err.error.messageFromSpotlog, 'close', {
          duration: 4000,
        });
      } else if (err.status === 404) {
        this.snackbar.open('Not found', 'close', {
          duration: 2000,
        });
      }
      else {
        this.snackbar.open(err.message, 'close', {
          duration: 4000,
        });
      }
    }
  }
}
