import { Component, OnInit, Input } from '@angular/core';
import { ScreenModel } from '../../../../../../models/screen-model';

@Component({
  selector: 'app-screen-playout-engineering-tab',
  templateUrl: './screen-playout-engineering-tab.component.html',
  styleUrls: ['./screen-playout-engineering-tab.component.scss']
})
export class ScreenPlayoutEngineeringTabComponent implements OnInit {
  
  _screenModel: ScreenModel;

  @Input()
  set screenModel(screenModel: ScreenModel) {
    this._screenModel = screenModel;
  }

  get screenModel() {
    return this._screenModel;
  }

  constructor() { }

  ngOnInit() {
  }

}
