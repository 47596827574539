export class ScreenServerModel {
    /// <summary>
    /// Name des Screenservers
    /// </summary>
    name: string;
    /// <summary>
    /// In den Sälen ...
    /// </summary>
    screen: number;

    dolby: boolean;
    dolbyIMS2000: boolean;
    sonyR320: boolean;
    sonyR515: boolean;
    doremi: boolean;
    necIMS2000: boolean;
    barcoAlchemy: boolean;
    gdc: boolean;
    others: string;
}
