import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-nav-card',
  templateUrl: './nav-card.component.html',
  styleUrls: ['./nav-card.component.scss']
})
export class NavCardComponent implements OnInit {

  @Input() icon: string;
  @Input() label: string;
  @Input() logo: string;
  @Input() backgroundUrl: string;
  @Input() contentText: string;

  constructor() { }

  ngOnInit() {
  }
}
