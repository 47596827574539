import { NgModule } from '@angular/core';
import { DevExtremeModule, DxActionSheetModule, DxButtonModule } from 'devextreme-angular';
import { SharedModule } from '../shared/shared.module';
import { DatagridComponent } from './datagrid/datagrid.component';
import { MatIconModule, MatButtonModule } from '@angular/material';

@NgModule({
  imports: [
    DevExtremeModule,
    SharedModule,
    MatIconModule,
    MatButtonModule,
    DxButtonModule
  ],
  declarations: [
    DatagridComponent
  ],
  exports: [
    DatagridComponent
  ]
})
export class WidgetsModule { }
