import { CinemaHierarchyModel } from './../../../../../../models/cinema-hierarchy-model';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ApiUrlService } from '../../../../../../shared/services/api-url.service';
import { HttpClient } from '@angular/common/http';
import { CinemaModel } from '../../../../../../models/cinema-model';
import { DxGridHeading } from '../../../../../../helpers/dx-grid-heading';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-extended-information-tab',
  templateUrl: './extended-information-tab.component.html',
  styleUrls: ['./extended-information-tab.component.scss']
})
export class ExtendedInformationTabComponent implements OnInit, OnDestroy {

  @Input()
  set techCinemaId(techCinemaId: number) {
    this._techCinemaId = techCinemaId;
  }

  get techCinemaId() {
    return this._techCinemaId;
  }

  private _techCinemaId: number;
  cinemaModel: CinemaModel;
  public cinemaHierarchyModel: CinemaHierarchyModel[] = [];
  public columnsCinemaHierarchyModel: DxGridHeading[] = [];
  private subscription: Subscription;


  constructor(public urlApiService: ApiUrlService, public httpClient: HttpClient, private translateService: TranslateService) { }

  ngOnInit() {
    this.requestData();
    this.subscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateCinemaHierarchyGrid();
    });
  }

  requestData() {
    this.httpClient.post(this.urlApiService.getCinemaById(), this.techCinemaId).subscribe(
      response => {
        this.cinemaModel = response as CinemaModel;
        if (this.cinemaModel.createDate) {
          this.cinemaModel.createDate = moment(this.cinemaModel.createDate).format('DD.MM.YYYY HH:mm:ss');
        }
        //this.data = this.cinemaModel.screens;
      },
      err => {
        console.log('Error occured.');
      });

    this.httpClient.post(this.urlApiService.getScreenHierarchyForCinema(), this.techCinemaId).subscribe(
      res => {
        this.updateCinemaHierarchyGrid();
        this.cinemaHierarchyModel = res as CinemaHierarchyModel[];
      },
      err => {
        console.log('Error occured.');
      });
  }

  updateCinemaHierarchyGrid() {
    this.columnsCinemaHierarchyModel.length = 0;
    this.columnsCinemaHierarchyModel.push(
      { caption: 'groupName1', dataField: 'groupName1' , groupIndex: '0'},
      { caption: 'crmCompanyOperatorId', dataField: 'crmCompanyOperatorId' },
      { caption: 'crmGroupId', dataField: 'crmGroupId', groupIndex: '0'},
      { caption: this.translateService.instant('screenName'), dataField: 'screenName' },
      { caption: this.translateService.instant('screenTheaterNr'), dataField: 'screenTheaeterNr' },
      { caption: this.translateService.instant('cinemaName'), dataField: 'cinemaName' },
      { caption: this.translateService.instant('cinemaCountry'), dataField: 'cinemaCountry' },
      { caption: this.translateService.instant('cinemaThearterNr'), dataField: 'cinemaThearterNr' },
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

/*techScreenId: number;
screenName: string;
screenTheaeterNr: string;
allowBookinglist: boolean;
allowPlayout: boolean;
techCinemaId: number;
cinemaName: string;
cinemaCountry: string;
cinemaThearterNr: string;
cineWeekStartingDay: number;
techCompanyOperatorId?: number;
companyName1: string;
companyName2: string;
companyZipCode: string;
companyCity: string;
companyStreet: string;
companyCountry: string;
crmCompanyOperatorId: string;
techCinemaGroupId?: number;
groupName1: string;
crmGroupId: string;*/
