export class LocalStorageKeyHelper {
    public static accessToken = 'access-token';
    public static expiresAt = 'expiresAt';
    public static permissionToken = 'permission-token';
    public static password = 'spotlog-password';
    public static loginName = 'spotlog-loginName';
    public static signedIn = 'spotlog-signedIn';
    public static allowPlayout = 'allowPlayout';
    public static allowBookingList = 'allowBookingList';
}

