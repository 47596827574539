import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ApiUrlService } from '../../../../shared/services/api-url.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-post-response-details-dialog',
  templateUrl: './post-response-details-dialog.component.html',
  styleUrls: ['./post-response-details-dialog.component.scss']
})
export class PostResponseDetailsDialogComponent implements OnInit {

  data: any;

  constructor(public dialogRef: MatDialogRef<PostResponseDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) public tmsId: any,
    private httpClient: HttpClient, private apiUrlService: ApiUrlService) { }

  ngOnInit() {
    this.httpClient.post(this.apiUrlService.getPostResponseDetails(), this.tmsId).subscribe(
      response => {
        const res = response as any;
      },
      err => {
        console.log('Error occured.');
      });
  }

}
