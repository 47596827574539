import { ScreenEquipmentModel } from "./screen-equipment-model";
export class CinemaRegistrationModel {
  /// <summary>
  /// Kino-Name Min = 3, Max = 30)
  /// </summary>
  name: string;
  /// <summary>
  /// Theater Nummer
  /// </summary>
  theaterNr: string;
  /// <summary>
  /// Inhaber Min = 3, Max = 30
  /// </summary>
  companyOperatorName: string;
  /// <summary>
  /// Ketten / Gruppe
  /// </summary>
  cinemaGroupName: string;
  /// <summary>
  /// Standort Min = 3
  /// </summary>
  location: string;
  /// <summary>
  /// Anzahl Säle Min = 3, Max = 25
  /// </summary>
  screenCount: number;
  /// <summary>
  /// Technischer Ausstatter
  /// </summary>
  technicalServiceProviderName: string;
  /// <summary>
  /// Kontakt Techn. Ausstatter
  /// </summary>
  technicalServiceProviderContact: string;
  /// <summary>
  /// Kontakt Weischer.Media
  /// </summary>
  weischerContact: string;
  /// <summary>
  /// Ansprechpartner Kinotechnik - Anrede
  /// </summary>
  cinemaTechContactSex: string;
  /// <summary>
  /// Ansprechpartner Kinotechnik - Name, Min = 2, Max = 30
  /// </summary>
  cinematechContactLastName: string;
  /// <summary>
  /// Ansprechpartner Kinotechnik - Name Min = 2, Max = 30
  /// </summary>
  cinematechContactFirstName: string;
  /// <summary>
  /// Ansprechpartner Kinotechnik - Telefonnummer
  /// </summary>
  cinemaTechContactTelephone: string;
  /// <summary>
  /// Technischer Schlüssel der Festnetzvorwahl
  /// </summary
  techPhoneCodeIdLandline: number;
  /// <summary>
  /// Ansprechpartner Kinotechnik - Handy
  /// </summary>
  cinemaTechContactMobile: string;
  /// <summary>
  /// Technischer Schlüssel der Mobilvorwahl
  /// </summary
  techPhoneCodeIdMobile: number;
  /// <summary>
  /// Ansprechpartner Kinotechnik - Email
  /// </summary>
  cinematechContactEmail: string;
  /// <summary>
  /// Internetzugang (Downstream)
  /// </summary>
  downstream: string;
  /// <summary>
  /// Internetzugang (Exklusiv, nicht exkl)
  /// </summary>
  sharedDownStream: boolean;

  /// <summary>
  /// Mitbenutzung
  /// </summary>
  sharedUseDownstream: string;

  /// <summary>
  /// Geschiwndigkeit/Kapazität internes Netzwerk
  /// </summary>
  networkSpeed: string;
  /// <summary>
  /// Benutztes Theater Management System
  /// </summary>
  tms: string;

  /// <summary>
  /// Software Version Tms
  /// </summary>
  tmsSoftwareVersion: string;
  /// <summary>
  /// Tms Sonstige
  /// </summary>
  tmsOther: string;

  /// <summary>
  /// Kassensystem
  /// </summary>
  posSystem: string;

  /// <summary>
  /// Kassensystem Sonstiges
  /// </summary>

  posSystemOther: string;
  /// <summary>
  /// Besonderheiten
  /// </summary>

  additionalInformation: string;
  /// <summary>
  /// Saalbezogene Technik
  /// </summary>
  screenEquipment: ScreenEquipmentModel;

  // M-BOX

  /// <summary>
  /// Lieferadresse für Hardware 1 = Wie Kinoadresse, 0  = "Sonstige Adresse"
  /// </summary>

  public deliverySelectionMBOX?: number;

  /// <summary>
  /// Firmenname MBOX
  /// </summary>

  public companyNameMBOX?: string;

  /// <summary>
  /// AnsprechPartner MBOX
  /// </summary>

  public contactPartnerMBOX?: string;

  /// <summary>
  /// Lieferadresse Straße und Nr MBOX
  /// </summary>

  public deliveryAddressStreetMBOX?: string;

  /// <summary>
  /// Lieferadresse PLZ, Ort MBOX
  /// </summary>

  public deliveryAddressStreetCodeMBOX?: string;

  /// <summary>
  /// Lieferadresse Land MBOX
  /// </summary>

  public deliveryAddressCountryMBOX?: string;

  // Kassensystem M-Box

  /// <summary>
  /// Existiert eine API zu Ihrem Kassensystem?
  /// </summary>

  public existingAPI?: number;

  /// <summary>
  /// Kassen-URL / Username
  /// </summary>

  public cashSystemUrl?: string;

  /// <summary>
  /// Passwort API
  /// </summary>

  public cashSystemPassword?: string;

  // Angaben zur Netzwerkgeschwindigkeit

  /// <summary>
  /// Download Geschwindigkeit (gemessen - MBit/s)
  /// </summary>

  public downloadSpeedResult?: number;

  /// <summary>
  /// Upload Geschwindigkeit(gemessen - MBit/s)
  /// </summary>

  public uploadSpeedResult?: number;

  /// <summary>
  /// Ping/Latenz (gemessen in ms (Millisekunde))
  /// </summary>

  public pingSpeedResult?: number;

  //Angaben zur internen Netzwerkstruktur

  /// <summary>
  /// Grundstruktur Netzwerk von Kino und Projektion (Wie Gemeinsames Netzwerk = false, Getrenntes Netzwerk = true
  /// </summary>

  public internalNetworkStructure?: number;

  /// <summary>
  /// Freie IP für M Server
  /// </summary>

  public ipMBoxServer?: string;

  /// <summary>
  /// Subnetmaske
  /// </summary>

  public subnetMask?: string;

  /// <summary>
  /// Default Gateway
  /// </summary>

  public defaultGateway?: string;

  /// <summary>
  /// DNS Server
  /// </summary>

  public dnsServer?: string;

  /// <summary>
  /// Verwenden Sie VLANs
  /// </summary>

  public vlanUsage?: number;

  /// <summary>
  /// Bitte beschreiben Sie ausführlich Ihre Netzwerk / VLAN-Topologie.
  /// </summary>

  public vlanTopo?: string;

  /// <summary>
  /// Verwenden Sie bereits eine VPN-Verbindung in Ihrem Kino?
  /// </summary>

  public vpnUsage?: number;

  /// <summary>
  /// Bitte beschreiben Sie Ihre VPN-Verbindung.
  /// </summary>

  public vpnDescription?: string;

  public showWeischerPlayM?: boolean;
}
