import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-changelog-info',
  templateUrl: './changelog-info.component.html',
  styleUrls: ['./changelog-info.component.scss']
})
export class ChangelogInfoComponent implements OnInit {

  constructor(private httpClient: HttpClient) { }

  public changelogData;
  public newestChange;

  ngOnInit() {
    this.httpClient.get('./assets/changelog.json').subscribe(
      res => {
        this.changelogData = res;
        this.newestChange = this.changelogData[0];
      }
    );
  }

  public showNotes(notes: string): boolean {
    return notes !== '';
  }

  public showHighlights(highlights: Array<any>): boolean {
    return highlights.length > 0;
  }

  public showModifications(modifications: Array<any>): boolean {
    return modifications.length > 0;
  }

}
