import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-site-policy-dialog',
  templateUrl: './site-policy-dialog.component.html',
  styleUrls: ['./site-policy-dialog.component.scss']
})
export class SitePolicyDialogComponent implements OnInit {

  isDownloadDialog: boolean;
  currentLanguage = 'de';

  constructor(public dialogRef: MatDialogRef<SitePolicyDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private translateService: TranslateService) {

    if (data) {
      this.dialogRef.disableClose = true;
      this.isDownloadDialog = true;
    } else {
      this.dialogRef.disableClose = false;
      this.isDownloadDialog = false;
    }
  }

  ngOnInit() {
    if (_.isUndefined(this.translateService.currentLang)) {
      const defaultLanguage = this.translateService.getDefaultLang();
      if (defaultLanguage) {
        this.currentLanguage = this.translateService.getDefaultLang();
      } else {
        this.currentLanguage = 'de';
      }
    } else {
      this.currentLanguage = this.translateService.currentLang;
    }
  }

  onOkClick() {
    this.dialogRef.close(`${true}`);
  }

  onNoClick(): void {
    this.dialogRef.close(`${false}`);
  }

}
