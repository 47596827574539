import { SpotlogUserModel } from './../../models/spotlog-user-model';
import { Directive, ElementRef, ViewContainerRef, TemplateRef, Input } from '@angular/core';
import { PermissionsService } from '../../shared/services/permissions.service';

@Directive({
  selector: '[appPermissions]'
})
export class PermissionsDirective {

  private currentUser: SpotlogUserModel;
  private permission: string[];

  @Input()
  set appPermissions(val) {
    this.permission = val;
    this.updateView();
  }

  constructor(private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionsService: PermissionsService) {

  }

  ngOnInit() {
    this.permissionsService.currentUser.subscribe(user => {
      this.currentUser = user;
      this.updateView();
    });
  }

  private updateView() {
    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    let hasPermission = false;

    if (this.currentUser && this.currentUser.portalPermissions && this.currentUser.portalPermissions.length > 0) {
      for (const checkPermission of this.permission) {
        const permissionFound = this.currentUser.portalPermissions.find(x => x.toUpperCase() === checkPermission.toUpperCase());
        if(permissionFound){
          hasPermission = true;
        }
      }
    }

    return hasPermission;
  }

}
