import { CinemaRegistrations } from "./../../../../models/cinema-registrations";
import { ScreenEquipmentModel } from "./../../../../models/screen-equipment-model";
import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { DxFormComponent } from "devextreme-angular";
import { CinemaSurveyModel } from "../../../../models/cinema-survey-model";
import { ScreenServerModel } from "../../../../models/screen-server-model";
import { DxGridHeading } from "../../../../helpers/dx-grid-heading";
import { AudioSystemModel } from "../../../../models/audio-system-model";
import { ProjectorModel } from "../../../../models/projector-model";
import { ApiUrlService } from "../../../../shared/services/api-url.service";
import { TranslateService } from "@ngx-translate/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { CinemaSurveyDialogComponent } from "../cinema-survey-dialog/cinema-survey-dialog.component";
import { CinemaRegistrationModel } from "../../../../models/cinema-registration-model";
import { TmsCinemaSystem } from "../../../../models/enums/tms-cinema-system.enum";
import { CashSystems } from "../../../../models/enums/cash-systems.enum";
import { HttpClient } from "@angular/common/http";
import notify from "devextreme/ui/notify";
import { CinemaRegistrationStatus } from "../../../../models/enums/cinema-registration-status.enum";
import { ChangeCinemaRegistrationStatusRequest } from "../../../../models/change-cinema-registration-status-request";
import { PortalApiDataService } from "../../../../shared/services/portal-api-data.service";
import { CinemaModel } from "../../../../models/cinema-model";
import { ChangeCinemaRegistrationAssignCinemaRequest } from "../../../../models/change-cinema-registration-assign-cinema-request";
import { StandardYesNoDialogComponent } from "../standard-yes-no-dialog/standard-yes-no-dialog.component";
import { PhoneCodeModel } from "../../../../models/phone-code-model";
import { ScreenServerDataModel } from "../../../../models/screen-server-data-model";

@Component({
  selector: "app-admin-cinema-survey-dialog",
  templateUrl: "./admin-cinema-survey-dialog.component.html",
  styleUrls: ["./admin-cinema-survey-dialog.component.scss"]
})
export class AdminCinemaSurveyDialogComponent implements OnInit {
  @ViewChild(DxFormComponent) form: DxFormComponent;

  colCountByScreen: Object;
  public surveyModel: CinemaSurveyModel = {
    screenCount: 1,
    techPhoneCodeIdLandline: 1,
    techPhoneCodeIdMobile: 1
  };

  // Vorwahlen
  phoneCodes: PhoneCodeModel[] = [
    { techPhoneCodeId: 1, phoneCode: "+49", countryCode: "DE" }
  ];
  techPhoneCodeIdMobile: number;
  techPhoneCodeIdLandline: number;
  phoneNumberMobile: string;
  phoneNumberLandline: string;

  // Screen Server
  dataSourceScreenSystem: ScreenServerModel[] = [];
  columnsScreenSystem: DxGridHeading[] = [];

  // Audio system
  dataSourceAudioSystem: AudioSystemModel[] = [];
  columnsAudioSystem: DxGridHeading[] = [];

  // Projector
  dataSource: ProjectorModel[] = [];
  columns: DxGridHeading[] = [];
  states: any[];
  genders: string[];
  gender: string;

  // Screen Ips
  screenServerIPs: ScreenServerDataModel[] = [];
  columnsServerIPs: DxGridHeading[] = [];

  //Kinosysteme TMS
  public showSoftwareVersionText: boolean;
  public showOtherTMSText: boolean;

  public showMBOX: boolean;

  buttonOptions: any = {
    text: this.translateService.instant("Ändern"),
    type: "success",
    useSubmitBehavior: true
  };

  frameRates = [
    { runType: "24-30 fps", label: "24-30 fps" },
    { runType: "48 fps", label: "48 fps" },
    { runType: ">= 60 fps", label: ">= 60 fps" }
  ];

  projectorManufacteurs = [
    { runType: "Barco", label: "Barco" },
    { runType: "Kinoton", label: "Kinoton" },
    { runType: "Christie", label: "Christie" },
    { runType: "NEC", label: "NEC" },
    { runType: "Sony", label: "Sony" }
  ];

  onlyDigitsPattern: any = "^[0-9]*$";
  theaterNrPattern: any = "^[0-9]{6}$";

  isDifferingShippingAddressMBOX: number;

  public isVpnUsage: number;
  public isVlanUsage: number;
  public isApiAvailable: number;

  // M-Box
  checkBoxOptions: any;
  deliveryMBoxOptions: any;
  public vpnUsageOptions: any;
  public vlanUsageOptions: any;
  public existingApiOptions: any;
  public internalNetworkStructureOptions: any;

  public cinemaRegistrations: CinemaRegistrations;

  status = [
    {
      status: CinemaRegistrationStatus.Pending,
      label: this.translateService.instant("Neu")
    },
    {
      status: CinemaRegistrationStatus.Approved,
      label: this.translateService.instant("Geprüft")
    },
    {
      status: CinemaRegistrationStatus.Acknowledged,
      label: this.translateService.instant("Ungeprüft")
    },
    {
      status: CinemaRegistrationStatus.Rejected,
      label: this.translateService.instant("Abgelehnt")
    },
    {
      status: CinemaRegistrationStatus.Deleted,
      label: this.translateService.instant("Gelöscht")
    },
    {
      status: CinemaRegistrationStatus.Incomplete,
      label: this.translateService.instant("Unvollständig")
    }
  ];

  public currentStatus;

  public selectedCinema: CinemaModel;
  public cinemas: CinemaModel[];

  constructor(
    private portalApiDataService: PortalApiDataService,
    public urlApiService: ApiUrlService,
    public httpClient: HttpClient,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<CinemaSurveyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog
  ) {
    this.colCountByScreen = {
      md: 4,
      sm: 2
    };

    this.genders = ["female", "male"];

    this.checkBoxOptions = {
      caption: this.translateService.instant(
        "Formular für weischerPLAY M Installation anzeigen"
      ),
      onValueChanged: e => {
        this.showMBOX = e.value;
      }
    };

    this.deliveryMBoxOptions = {
      displayExpr: "name",
      valueExpr: "id",
      dataSource: [
        { id: 1, name: this.translateService.instant("Wie Kinoadresse") },
        { id: 0, name: this.translateService.instant("Sonstige Adresse") }
      ],
      onValueChanged: e => {
        this.isDifferingShippingAddressMBOX = e.value;
      }
    };

    this.vpnUsageOptions = {
      displayExpr: "name",
      valueExpr: "id",
      dataSource: [
        { id: 1, name: this.translateService.instant("Ja") },
        { id: 0, name: this.translateService.instant("Nein") }
      ],
      onValueChanged: e => {
        this.isVpnUsage = e.value;
      }
    };

    this.vlanUsageOptions = {
      displayExpr: "name",
      valueExpr: "id",
      dataSource: [
        { id: 1, name: this.translateService.instant("Ja") },
        { id: 0, name: this.translateService.instant("Nein") }
      ],
      onValueChanged: e => {
        this.isVlanUsage = e.value;
      }
    };

    this.internalNetworkStructureOptions = {
      displayExpr: "name",
      valueExpr: "id",
      dataSource: [
        {
          id: 1,
          name: this.translateService.instant("Gemeinsames Netzwerk")
        },
        {
          id: 0,
          name: this.translateService.instant("Getrenntes Netzwerk")
        }
      ]
    };

    this.existingApiOptions = {
      displayExpr: "name",
      valueExpr: "id",
      dataSource: [
        { id: 1, name: this.translateService.instant("Ja") },
        { id: 0, name: this.translateService.instant("Nein") }
      ],
      onValueChanged: e => {
        this.isApiAvailable = e.value;
      }
    };

    this.cinemaRegistrations = this.data[
      "CinemaRegistrationData"
    ] as CinemaRegistrations;

    this.portalApiDataService.getCinemas().subscribe(res => {
      this.cinemas = res as CinemaModel[];
      if (this.cinemaRegistrations.techCinemaId) {
        this.selectedCinema = this.portalApiDataService.getCinemaById(
          this.cinemaRegistrations.techCinemaId
        );
      }
    });

    this.currentStatus = this.cinemaRegistrations.status;

    const cinemaRegistrationModel = this.cinemaRegistrations
      .cinemaRegistrationData;

    if (cinemaRegistrationModel) {
      // Show MBOX
      this.surveyModel.showWeischerPlayM =
        cinemaRegistrationModel.showWeischerPlayM;

      // Misc Info
      this.surveyModel.cinemaName = cinemaRegistrationModel.name;
      this.surveyModel.theaterNr = parseInt(
        cinemaRegistrationModel.theaterNr,
        10
      );
      this.surveyModel.companyOperator =
        cinemaRegistrationModel.companyOperatorName;
      this.surveyModel.cinemaGroup = cinemaRegistrationModel.cinemaGroupName;
      this.surveyModel.location = cinemaRegistrationModel.location;
      this.surveyModel.screenCount = cinemaRegistrationModel.screenCount;
      this.surveyModel.serviceProvider =
        cinemaRegistrationModel.technicalServiceProviderName;
      this.surveyModel.contactServiceProvider =
        cinemaRegistrationModel.technicalServiceProviderContact;
      this.surveyModel.contactWeischerMedia =
        cinemaRegistrationModel.weischerContact;

      // contact person
      this.surveyModel.gender =
        cinemaRegistrationModel.cinemaTechContactSex === "Herr" ? 0 : 1;
      this.surveyModel.firstName =
        cinemaRegistrationModel.cinematechContactFirstName;
      this.surveyModel.lastName =
        cinemaRegistrationModel.cinematechContactLastName;
      this.surveyModel.phone =
        cinemaRegistrationModel.cinemaTechContactTelephone;
      this.surveyModel.mobilePhone =
        cinemaRegistrationModel.cinemaTechContactMobile;
      this.surveyModel.techPhoneCodeIdMobile =
        cinemaRegistrationModel.techPhoneCodeIdMobile;
      this.surveyModel.techPhoneCodeIdLandline =
        cinemaRegistrationModel.techPhoneCodeIdLandline;
      this.surveyModel.mail = cinemaRegistrationModel.cinematechContactEmail;

      this.techPhoneCodeIdLandline = this.surveyModel.techPhoneCodeIdLandline;
      this.techPhoneCodeIdMobile = this.surveyModel.techPhoneCodeIdMobile;
      this.phoneNumberLandline = this.surveyModel.phone;
      this.phoneNumberMobile = this.surveyModel.mobilePhone;

      // internet
      switch (cinemaRegistrationModel.downstream) {
        case "<16 Mbit/s":
          this.surveyModel.bitrate = 0;
          break;
        case "16-50 Mbit/s":
          this.surveyModel.bitrate = 1;
          break;
        case ">50 Mbit/s":
          this.surveyModel.bitrate = 2;
          break;
        default:
          this.surveyModel.bitrate = 0;
          break;
      }

      // Mitbenutzung
      if (cinemaRegistrationModel.sharedUseDownstream) {
        this.surveyModel.jointUsage =
          cinemaRegistrationModel.sharedUseDownstream;
      }

      // Internetnutzung
      this.surveyModel.internetUsage = cinemaRegistrationModel.sharedDownStream
        ? 0
        : 1;

      // network
      switch (cinemaRegistrationModel.networkSpeed) {
        case "100 Mbit/s":
          this.surveyModel.networkSpeed = 0;
          break;
        case "1 Gbit/s":
          this.surveyModel.networkSpeed = 1;
          break;
        case ">1 Gbit/s":
          this.surveyModel.networkSpeed = 2;
          break;
        case "Die Komponenten sind nicht miteinander vernetzt":
          this.surveyModel.networkSpeed = 3;
          break;
        default:
          this.surveyModel.networkSpeed = 3;
          break;
      }

      // Cinema System TMS
      this.surveyModel.tms = TmsCinemaSystem[cinemaRegistrationModel.tms];

      this.surveyModel.softwareVersion =
        cinemaRegistrationModel.tmsSoftwareVersion;
      this.surveyModel.otherTms = cinemaRegistrationModel.tmsOther;

      // Cinema System Kassensystem
      this.surveyModel.accounting =
        CashSystems[cinemaRegistrationModel.posSystem];
      this.surveyModel.accountingOther = cinemaRegistrationModel.posSystemOther;
      // Screen Servers
      this.dataSourceScreenSystem =
        cinemaRegistrationModel.screenEquipment.screenServers;

      // Screen IPs
      this.screenServerIPs = cinemaRegistrationModel.screenEquipment.screenIps;

      this.dataSourceAudioSystem =
        cinemaRegistrationModel.screenEquipment.audioSystems;
      this.dataSource = cinemaRegistrationModel.screenEquipment.projectors;
      this.surveyModel.notes = cinemaRegistrationModel.additionalInformation;

      // MBOX

      this.surveyModel.deliverySelectionMBOX =
        cinemaRegistrationModel.deliverySelectionMBOX;
      this.isDifferingShippingAddressMBOX =
        cinemaRegistrationModel.deliverySelectionMBOX;
      this.surveyModel.companyNameMBOX =
        cinemaRegistrationModel.companyNameMBOX;
      this.surveyModel.contactPartnerMBOX =
        cinemaRegistrationModel.contactPartnerMBOX;
      this.surveyModel.deliveryAddressStreetMBOX =
        cinemaRegistrationModel.deliveryAddressStreetMBOX;
      this.surveyModel.deliveryAddressStreetCodeMBOX =
        cinemaRegistrationModel.deliveryAddressStreetCodeMBOX;
      this.surveyModel.deliveryAddressCountryMBOX =
        cinemaRegistrationModel.deliveryAddressCountryMBOX;
      this.surveyModel.existingAPI = cinemaRegistrationModel.existingAPI;
      this.surveyModel.cashSystemUrl = cinemaRegistrationModel.cashSystemUrl;
      this.surveyModel.cashSystemPassword =
        cinemaRegistrationModel.cashSystemPassword;
      this.surveyModel.downloadSpeedResult =
        cinemaRegistrationModel.downloadSpeedResult;
      this.surveyModel.uploadSpeedResult =
        cinemaRegistrationModel.uploadSpeedResult;
      this.surveyModel.pingSpeedResult =
        cinemaRegistrationModel.pingSpeedResult;
      this.surveyModel.internalNetworkStructure =
        cinemaRegistrationModel.internalNetworkStructure;
      this.surveyModel.ipMBoxServer = cinemaRegistrationModel.ipMBoxServer;
      this.surveyModel.subnetMask = cinemaRegistrationModel.subnetMask;
      this.surveyModel.defaultGateway = cinemaRegistrationModel.defaultGateway;
      this.surveyModel.dnsServer = cinemaRegistrationModel.dnsServer;
      this.surveyModel.vlanUsage = cinemaRegistrationModel.vlanUsage;
      this.surveyModel.vlanTopo = cinemaRegistrationModel.vlanTopo;
      this.surveyModel.vpnUsage = cinemaRegistrationModel.vpnUsage;
      this.surveyModel.vpnDescription = cinemaRegistrationModel.vpnDescription;
    }
  }

  ngOnInit() {
    this.initPhoneCodes();
    this.updateGrid();
    this.updateGridMatrix(1);
  }

  // initialisiert die Vorwahl Dropdowns
  initPhoneCodes() {
    // Daten vom Endpunkt holen
    this.httpClient
      .get(this.urlApiService.getPhoneCodesUrl())
      .subscribe(result => {
        this.phoneCodes = result as PhoneCodeModel[];
      });

    // Dropdowns auf den ersten Wert setzen, bzw. den Eintrag mit der ID 1
    if (!this.techPhoneCodeIdLandline) {
      this.techPhoneCodeIdMobile = 1;
    }

    if (!this.techPhoneCodeIdLandline) {
      this.techPhoneCodeIdLandline = 1;
    }
  }

  updateGrid() {
    this.columns.length = 0;
    this.columns.push(
      { caption: this.translateService.instant("Saal"), dataField: "screen" },
      { caption: this.translateService.instant("Name"), dataField: "name" },
      {
        caption: this.translateService.instant("2k"),
        dataField: "resolution2k",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("4k"),
        dataField: "resolution4k",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("3D-Fähigkeit"),
        dataField: "ready3D",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("IMAX"),
        dataField: "readyImax",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("Bildwechselfrequenz"),
        dataField: "fps",
        lookup: {
          valueExpr: "runType",
          displayExpr: "label",
          dataSource: this.frameRates
        }
      },
      {
        caption: this.translateService.instant("Hersteller Projektor"),
        dataField: "projectorManufacteur",
        lookup: {
          valueExpr: "runType",
          displayExpr: "label",
          dataSource: this.projectorManufacteurs,
          value: { runType: "Barco", label: "Barco" }
        }
      },
      {
        caption: this.translateService.instant(
          "Sonstige saalspezifische Installationen"
        ),
        dataField: "additionalInformation"
      }
    );

    this.columnsScreenSystem.length = 0;
    this.columnsScreenSystem.push(
      { caption: this.translateService.instant("Saal"), dataField: "screen" },
      { caption: this.translateService.instant("Name"), dataField: "name" },
      {
        caption: this.translateService.instant("DOLBY"),
        dataField: "dolby",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("DOLBY IMS 2000"),
        dataField: "dolbyIMS2000",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("SONY R320"),
        dataField: "sonyR320",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("SONY R515"),
        dataField: "sonyR515",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("doremi"),
        dataField: "doremi",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("NEC IMS 2000"),
        dataField: "necIMS2000",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("Barco Alchemy"),
        dataField: "barcoAlchemy",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("GDC"),
        dataField: "gdc",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("Sonstige"),
        dataField: "others"
      }
    );

    this.columnsAudioSystem.length = 0;
    this.columnsAudioSystem.push(
      { caption: this.translateService.instant("Saal"), dataField: "screen" },
      { caption: this.translateService.instant("Name"), dataField: "name" },
      {
        caption: this.translateService.instant("5.1 Surround"),
        dataField: "surround_5_1",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("7.1 Surround"),
        dataField: "surround_7_1",
        dataType: "boolean"
      },
      {
        caption: this.translateService.instant("Dolby-Atmos"),
        dataField: "dolbyAtmos",
        dataType: "boolean"
      }
    );

    this.columnsServerIPs.length = 0;
    this.columnsServerIPs.push(
      { caption: this.translateService.instant("Saal"), dataField: "screen" },
      {
        caption: this.translateService.instant(
          "IP Adresse/Hostname Kinoserver"
        ),
        dataField: "ip"
      }
    );
  }

  screen(width) {
    return width < 720 ? "sm" : "md";
  }
  valueChanged(e) {
    if (e.value) {
      this.colCountByScreen = {
        md: 4,
        sm: 2
      };
    } else {
      this.colCountByScreen = null;
    }
  }

  onTMSChanged(e) {
    if (e.value === 7) {
      this.showOtherTMSText = true;
      this.showSoftwareVersionText = false;
    } else if (e.value === 8) {
      this.showOtherTMSText = false;
      this.showSoftwareVersionText = false;
    } else {
      this.showSoftwareVersionText = true;
      this.showOtherTMSText = false;
    }
  }

  onFormSubmit(e) {
    let formData = this.form.formData as CinemaSurveyModel;
    let request: CinemaRegistrationModel = new CinemaRegistrationModel();

    // Misc Info
    request.name = formData.cinemaName;
    if (formData.theaterNr) {
      request.theaterNr = formData.theaterNr.toString();
    }
    request.companyOperatorName = formData.companyOperator;
    request.cinemaGroupName = formData.cinemaGroup;
    request.location = formData.location;
    request.screenCount = formData.screenCount;
    request.technicalServiceProviderName = formData.serviceProvider;
    request.technicalServiceProviderContact = formData.contactServiceProvider;
    request.weischerContact = formData.contactWeischerMedia;

    // contact person
    request.cinemaTechContactSex = formData.gender === 0 ? "Herr" : "Frau";
    request.cinematechContactFirstName = formData.firstName;
    request.cinematechContactLastName = formData.lastName;
    request.cinemaTechContactTelephone = formData.phone;
    request.cinemaTechContactMobile = formData.mobilePhone;

    request.cinemaTechContactTelephone = this.phoneNumberLandline;
    request.cinemaTechContactMobile = this.phoneNumberMobile;
    request.techPhoneCodeIdMobile = this.techPhoneCodeIdMobile;
    request.techPhoneCodeIdLandline = this.techPhoneCodeIdLandline;

    request.cinematechContactEmail = formData.mail;

    // internet
    switch (formData.bitrate) {
      case 0:
        request.downstream = "<16 Mbit/s";
        break;
      case 1:
        request.downstream = "16-50 Mbit/s";
        break;
      case 2:
        request.downstream = ">50 Mbit/s";
        break;
      default:
        break;
    }

    // network
    switch (formData.networkSpeed) {
      case 0:
        request.networkSpeed = "100 Mbit/s";
        break;
      case 1:
        request.networkSpeed = "1 Gbit/s";
        break;
      case 2:
        request.networkSpeed = ">1 Gbit/s";
        break;
      case 3:
        request.networkSpeed =
          "Die Komponenten sind nicht miteinander vernetzt";
        break;
      default:
        request.networkSpeed = "Keine Angabe";
        break;
    }

    // Internetnutzung
    if (formData.internetUsage === 0 || formData.internetUsage === 1) {
      request.sharedDownStream = formData.internetUsage === 0 ? true : false;
    }

    // Mitbenutzung
    if (formData.jointUsage) {
      request.sharedUseDownstream = formData.jointUsage;
    }

    // Cinema System TMS

    request.tms = TmsCinemaSystem[formData.tms];
    request.tmsSoftwareVersion = formData.softwareVersion;
    request.tmsOther = formData.otherTms;

    // Cinema System Kassensystem

    request.posSystem = CashSystems[formData.accounting];
    request.posSystemOther = formData.accountingOther;

    request.screenEquipment = new ScreenEquipmentModel();

    //Screen Server

    request.screenEquipment.screenServers = this.dataSourceScreenSystem;

    // Audio System

    request.screenEquipment.audioSystems = this.dataSourceAudioSystem;

    // Projectors
    request.screenEquipment.projectors = this.dataSource;

    // Screen Ips
    request.screenEquipment.screenIps = this.screenServerIPs;

    request.additionalInformation = formData.notes;

    // MBOX

    request.deliverySelectionMBOX = formData.deliverySelectionMBOX;
    request.companyNameMBOX = formData.companyNameMBOX;
    request.contactPartnerMBOX = formData.contactPartnerMBOX;
    request.deliveryAddressStreetMBOX = formData.deliveryAddressStreetMBOX;
    request.deliveryAddressStreetCodeMBOX =
      formData.deliveryAddressStreetCodeMBOX;
    request.deliveryAddressCountryMBOX = formData.deliveryAddressCountryMBOX;
    request.existingAPI = formData.existingAPI;
    request.cashSystemUrl = formData.cashSystemUrl;
    request.cashSystemPassword = formData.cashSystemPassword;
    request.downloadSpeedResult = formData.downloadSpeedResult;
    request.uploadSpeedResult = formData.uploadSpeedResult;
    request.pingSpeedResult = formData.pingSpeedResult;
    request.internalNetworkStructure = formData.internalNetworkStructure;
    request.ipMBoxServer = formData.ipMBoxServer;
    request.subnetMask = formData.subnetMask;
    request.defaultGateway = formData.defaultGateway;
    request.dnsServer = formData.dnsServer;
    request.vlanUsage = formData.vlanUsage;
    request.vlanTopo = formData.vlanTopo;
    request.vpnUsage = formData.vpnUsage;
    request.vpnDescription = formData.vpnDescription;

    this.cinemaRegistrations.cinemaRegistrationData = request;
    this.cinemaRegistrations.registrationData = JSON.stringify(request);

    this.httpClient
      .post(this.urlApiService.getUpdateCinemaUrl(), this.cinemaRegistrations)
      .subscribe(
        response => {
          notify(
            {
              message: this.translateService.instant(
                "Ihre Daten wurden erfolgreich gespeichert. Danke!"
              ),
              position: {
                my: "center top",
                at: "center top"
              }
            },
            "success",
            3000
          );
          this.dialogRef.close();
        },
        err => {
          notify(
            {
              message: this.translateService.instant(
                "Das Kino muss mindestens einen Saal haben."
              ),
              position: {
                my: "center top",
                at: "center top"
              }
            },
            "error",
            3000
          );
          console.log("Error occured." + err);
        }
      );
    e.preventDefault();
  }

  form_fieldDataChanged(e) {
    if (e.dataField == "screenCount") {
      if (e.value > 0) {
        this.updateGridMatrix(e.value);
      }
    }
  }

  onStatusChange(e) {
    const request = new ChangeCinemaRegistrationStatusRequest();
    request.status = this.currentStatus;
    request.techCinemaRegistrationsId = this.cinemaRegistrations.techCinemaRegistrationsId;

    if (this.currentStatus == CinemaRegistrationStatus.Deleted) {
      console.log("Löschen");

      const dialogRef = this.dialog.open(StandardYesNoDialogComponent, {
        data: {
          title: "Hinweis",
          content:
            "Wollen Sie wirklich die Technischen und Persönlichen Daten löschen?"
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === "true") {
          this.cinemaRegistrations.cinemaRegistrationData = null;
          this.cinemaRegistrations.registrationData = null;

          this.httpClient
            .post(
              this.urlApiService.getUpdateCinemaUrl(),
              this.cinemaRegistrations
            )
            .subscribe(
              response => {
                notify(
                  {
                    message: this.translateService.instant(
                      "Ihre Daten wurden erfolgreich gespeichert. Danke!"
                    ),
                    position: {
                      my: "center top",
                      at: "center top"
                    }
                  },
                  "success",
                  3000
                );
                this.dialogRef.close();
              },
              err => {
                notify(
                  {
                    message: this.translateService.instant(
                      "Das Kino muss mindestens einen Saal haben."
                    ),
                    position: {
                      my: "center top",
                      at: "center top"
                    }
                  },
                  "error",
                  3000
                );
                console.log("Error occured." + err);
              }
            );
        } else {
          dialogRef.close();
        }
      });
    } else {
      this.httpClient
        .post(this.urlApiService.getChangeStatusUrl(), request)
        .subscribe(
          data => {
            notify(
              {
                message: this.translateService.instant(
                  "Status wurde erfolgreich gesetzt. Danke!"
                ),
                position: {
                  my: "center top",
                  at: "center top"
                }
              },
              "success",
              3000
            );
            this.dialogRef.close();
          },
          err => {
            notify(
              {
                message: this.translateService.instant(
                  "Es ist ein Fehler aufgetreten!"
                ),
                position: {
                  my: "center top",
                  at: "center top"
                }
              },
              "error",
              3000
            );
          }
        );
    }
  }

  onCinemaAssignment(e) {
    const request = new ChangeCinemaRegistrationAssignCinemaRequest();
    request.techCinemaId = this.selectedCinema.techCinemaId;
    request.techCinemaRegistrationsId = this.cinemaRegistrations.techCinemaRegistrationsId;
    this.httpClient
      .post(this.urlApiService.getAssignCinemaUrl(), request)
      .subscribe(
        data => {
          notify(
            {
              message: this.translateService.instant(
                "Kino wurde erfolgreich zugewiesen. Danke!"
              ),
              position: {
                my: "center top",
                at: "center top"
              }
            },
            "success",
            3000
          );
          this.dialogRef.close();
        },
        err => {
          notify(
            {
              message: this.translateService.instant(
                "Es ist ein Fehler aufgetreten!"
              ),
              position: {
                my: "center top",
                at: "center top"
              }
            },
            "error",
            3000
          );
        }
      );
  }

  getCenterDisplayExpr(item) {
    if (!item) {
      return "";
    }
    return item.name1 + ", " + item.city + " (" + item.theaterNr + ")";
  }

  public onInputValueChanged(event: any, targetVar: string) {
    if (event.value) {
      this[targetVar] = event.value;
    }
  }

  updateGridMatrix(screenCount: number) {
    /*this.dataSource = [];
    this.dataSourceScreenSystem = [];
    this.dataSourceAudioSystem = [];

    for (let index = 1; index <= screenCount; index++) {
      const element: ProjectorModel = new ProjectorModel();
      element.screen = index;
      element.ready3D = false;
      element.readyImax = false;
      element.resolution2k = false;
      element.resolution4k = false;
      this.dataSource.push(element);

      const screenServerModel: ScreenServerModel = new ScreenServerModel();
      screenServerModel.barcoAlchemy = false;
      screenServerModel.dolby = false;
      screenServerModel.dolbyIMS2000 = false;
      screenServerModel.doremi = false;
      screenServerModel.gdc = false;
      screenServerModel.necIMS2000 = false;
      screenServerModel.sonyR320 = false;
      screenServerModel.sonyR515 = false;
      screenServerModel.screen = index;
      this.dataSourceScreenSystem.push(screenServerModel);

      const audioSystemModel: AudioSystemModel = new AudioSystemModel();
      audioSystemModel.screen = index;
      audioSystemModel.dolbyAtmos = false;
      audioSystemModel.surround_5_1 = false;
      audioSystemModel.surround_7_1 = false;
      this.dataSourceAudioSystem.push(audioSystemModel);

    }*/
  }
}
