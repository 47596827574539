import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CinemaPlayoutDetailsTab } from '../cinema-playout-details-dialog/cinema-playout-details-dialog.component';
import { ScreenModel } from '../../../../models/screen-model';

@Component({
  selector: 'app-cinema-playout-screen-details-dialog',
  templateUrl: './cinema-playout-screen-details-dialog.component.html',
  styleUrls: ['./cinema-playout-screen-details-dialog.component.scss']
})
export class CinemaPlayoutScreenDetailsDialogComponent implements OnInit {

  tabs: CinemaPlayoutDetailsTab[] = [];
  public selectedTabId = 1;
  techCinemaId: number;
  screenModel: ScreenModel;

  constructor(public dialogRef: MatDialogRef<CinemaPlayoutScreenDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.techCinemaId = data.techCinemaId;
    this.screenModel = data.screenModel;
  }

  ngOnInit() {
    this.tabs.push({ id: 1, text: 'Playout + Technik' });
    this.tabs.push({ id: 2, text: 'Besucherzahlen' });
    this.tabs.push({ id: 3, text: 'Aktuelle Filme' });
  }

  selectTab(e) {
    this.selectedTabId = this.tabs[e.itemIndex].id;
  }

}
