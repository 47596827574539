
import {map} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CinemaModel } from '../../models/cinema-model';
import { CinemaRequestModel } from '../../models/cinema-request-model';
import { ApiUrlService } from './api-url.service';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class PortalApiDataService {



  private _cinemasModels = [];

  public set cinemaModels(value: CinemaModel[]) {
    this._cinemasModels = value;
  }

  public get cinemaModels() {
    return this._cinemasModels;
  }

  constructor(private httpClient: HttpClient, private apiUrlService: ApiUrlService) {

  }

  getCinemas(allowPlayout = true, allowBookingList = true, onlyActiveScreens = false) {

    const request: CinemaRequestModel = new CinemaRequestModel();
    request.allowPlayout = allowPlayout;
    request.allowBookingList = allowBookingList;
    if (onlyActiveScreens) {
      request.onlyActiveScreens = true;
    }

    return this.httpClient.post(this.apiUrlService.getCinemas(), request).pipe(map(
      res => {
        this.cinemaModels = res as CinemaModel[];
        return this.cinemaModels;
      }
    ));
  }

  getCinemaById(techCinemaId: number) {
    for (let index = 0; index < this.cinemaModels.length; index++) {
      const element = this.cinemaModels[index];
      if (element.techCinemaId === techCinemaId) {
        return element;
      }

    }
    return null;
  }

  getPostResponseDetails(techTMSRequestDataId: number): Observable<any> {

    return this.httpClient.post(this.apiUrlService.getPostResponseDetailsAsZip(), techTMSRequestDataId,
      {
        reportProgress: true,
        responseType: 'blob'
      }
    ).pipe(map((response: any) => {
      return <any[]>response;
    }
    ));
  }

  getRecievedResponseDetails(techTMSRequestDataId: number): Observable<any> {

    return this.httpClient.post(this.apiUrlService.getRecievedResponseDetailsAsZip(), techTMSRequestDataId,
      {
        reportProgress: true,
        responseType: 'blob'
      }
    ).pipe(map((response: any) => {
      return <any[]>response;
    }
    ));
  }
}

