import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss']
})
export class ContactDialogComponent implements OnInit {

  currentLanguage = 'de';

  constructor(private translateService: TranslateService) { }

  ngOnInit() {
    if (_.isUndefined(this.translateService.currentLang)) {
      const defaultLanguage = this.translateService.getDefaultLang();
      if (defaultLanguage) {
        this.currentLanguage = this.translateService.getDefaultLang();
      } else {
        this.currentLanguage = 'de';
      }
    } else {
      this.currentLanguage = this.translateService.currentLang;
    }
  }

}
