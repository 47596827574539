import { CineWeekModel } from './../../../../models/cine-week-model';
import { HistoricCineWeekRequestModel } from './../../../../models/historic-cine-week-request-model';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DxGridHeading } from '../../../../helpers/dx-grid-heading';
import { Subscription } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ApiUrlService } from '../../../../shared/services/api-url.service';
import * as moment from 'moment';

@Component({
  selector: 'app-playout-download-archive-dialog',
  templateUrl: './playout-download-archive-dialog.component.html',
  styleUrls: ['./playout-download-archive-dialog.component.scss']
})
export class PlayoutDownloadArchiveDialogComponent implements OnInit, OnDestroy {

  public columns: DxGridHeading[] = [];
  public content: CineWeekModel[] = [];

  cinemaName;
  techCinemaId;
  theaterNr;
  private subscription: Subscription;

  constructor(private translateService: TranslateService, public urlApiService: ApiUrlService, public httpClient: HttpClient, public dialogRef: MatDialogRef<PlayoutDownloadArchiveDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
   this.techCinemaId = data.techCinemaId, 
    this.cinemaName = data.cinemaName, 
    this.theaterNr = data.theaterNr
  }

  ngOnInit() {
    this.subscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateColumns();
    });
    this.updateColumns();
    this.requestContents();
  }

  updateColumns() {
    this.columns.length = 0;
    this.columns.push(
     { dataField: 'year', groupIndex: '0', groupCellTemplate: this.groupingFormat},
     { caption: this.translateService.instant('Kinowoche'),  dataField: 'week', groupIndex: '1'},
     { caption: this.translateService.instant('Kinowochen Beginn'), dataField: 'germanFormatStart' },
     { caption: this.translateService.instant('Kinowochen Ende'), dataField: 'germanFormatEnd' },
     { caption: 'PDF Download', dataField: 'downloadLink', allowSorting: false, allowFiltering: false, cellTemplate: 'cellTemplateHistoryDataPdfDownload' },
    );
  }

  groupingFormat(elem, data) {
    return elem.innerHTML = data.displayValue;
  }


  requestContents() {
    const request = new HistoricCineWeekRequestModel();
    request.techCinemaId = this.techCinemaId;
    this.httpClient.post(this.urlApiService.getHistoricCineWeeks(), request).subscribe(
      data => {
        const cineWeeks = data as CineWeekModel[];
        for (let index = 0; index < cineWeeks.length; index++) {
          const element = cineWeeks[index];

          cineWeeks[index].start = moment(element.start).local().format('YYYY-MM-DD HH:mm:ss.000');
          cineWeeks[index].germanFormatStart = moment(element.displayStart).local().format('DD.MM.YYYY');

          cineWeeks[index].end = moment(element.end).local().format('YYYY-MM-DD HH:mm:ss.000');
          cineWeeks[index].germanFormatEnd = moment(element.displayEnd).local().format('DD.MM.YYYY');
          cineWeeks[index].techCinemaId = this.techCinemaId;

          cineWeeks[index].translatedCineWeek = cineWeeks[index].week+': '+ moment(element.start).format('DD.MM.YYYY') + ' - ' + moment(element.end).format('DD.MM.YYYY');

        }
        this.content = cineWeeks;
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
