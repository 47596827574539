import { Component, OnInit, Input } from '@angular/core';
import { DxGridHeading } from '../../../../../../helpers/dx-grid-heading';
import { PortalShowInfoModel } from '../../../../../../models/portal-show-info-model';
import { Subscription } from 'rxjs';
import { ApiUrlService } from '../../../../../../shared/services/api-url.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ScreenShowsRequestModel } from '../../../../../../models/screen-shows-request-model';
import * as moment from 'moment';
import { ScreenPlayoutInformationsRequestModel } from '../../../../../../models/screen-playout-informations-request-model';
import { PortalPlayoutInformationModel } from '../../../../../../models/portal-playout-information-model';
import * as _ from 'lodash';


@Component({
  selector: 'app-screen-latest-movies-tab',
  templateUrl: './screen-latest-movies-tab.component.html',
  styleUrls: ['./screen-latest-movies-tab.component.scss']
})
export class ScreenLatestMoviesTabComponent implements OnInit {

  @Input()
  set techCinemaId(techCinemaId: number) {
    this._techCinemaId = techCinemaId;
  }

  get techCinemaId() {
    return this._techCinemaId;
  }

  @Input()
  set techScreenId(techScreenId: number) {
    this._techScreenId = techScreenId;
  }

  get techScreenId() {
    return this._techScreenId;
  }

  private _techCinemaId: number;
  private _techScreenId: number;

  columns: DxGridHeading[] = [];
  data: PortalShowInfoModel[] = [];
  private subscription: Subscription;
  movies = [];
  columnsMovie: DxGridHeading[] = [];


  constructor(public urlApiService: ApiUrlService, public httpClient: HttpClient, private translateService: TranslateService) { }

  ngOnInit() {
    this.subscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateGrid();
    });
    this.requestData();
    this.requestPlayoutInformations();
  }

  requestData() {
    const utcDate = moment().local().format('YYYY-MM-DD HH:mm:ss.000');

    let request: ScreenShowsRequestModel = new ScreenShowsRequestModel();
    request.start = utcDate;
    request.techCinemaId = this.techCinemaId;
    request.techScreenId = this.techScreenId;

    this.httpClient.post(this.urlApiService.getScreenShows(), request).subscribe(
      response => {
        this.updateGrid();
        const result = response as PortalShowInfoModel[];
        this.data = result;
      },
      err => {
        console.log('Error occured.');
      });
  }

  requestPlayoutInformations() {
    const utcDate = moment().local().format('YYYY-MM-DD HH:mm:ss.000');

    let request: ScreenPlayoutInformationsRequestModel = new ScreenPlayoutInformationsRequestModel();
    request.date = utcDate;
    request.techCinemaId = this.techCinemaId;
    request.techScreenId = this.techScreenId;

    this.httpClient.post(this.urlApiService.getScreenPlayoutInformationsRequest(), request).subscribe(
      response => {
        this.updateAllMoviesGrid();
        const res = response as PortalPlayoutInformationModel[];
        let result: PortalPlayoutInformationModel[] = _.uniqBy(res, 'featureTitle');
        result = _.orderBy(result, ['featureTitle'], ['asc']);
        this.movies = _.remove(result, (x: PortalPlayoutInformationModel) => x.featureExclusive === true);
      },
      err => {
        console.log('Error occured.');
      });
  }

  updateGrid() {
    this.columns.length = 0;
    this.columns.push(
      { caption: this.translateService.instant('contentTitle'), dataField: 'contentTitleFeaturePlan' },
      { caption: 'showStart', dataField: 'showStart' },
      { caption: 'audienceSize', dataField: 'audienceSize' },
      { caption: 'showStatus', dataField: 'showStatus' },
      { caption: 'DCP Name', dataField: 'annotationTextFeaturePlan' },
      { caption: 'UUIDFeaturePlan', dataField: 'UUIDFeaturePlan' },
    );
  }

  updateAllMoviesGrid() {
    this.columnsMovie.length = 0;
    this.columnsMovie.push(
      { caption: this.translateService.instant('featureTitle'), dataField: 'featureTitle' },
      { caption: this.translateService.instant('featureDepth'), dataField: 'featureDepth' },
      { caption: 'IMAX', dataField: 'imax' },
      { caption: this.translateService.instant('featureLanguageSubtitles'), dataField: 'featureLanguageSubtitles' },
      { caption: this.translateService.instant('featureLanguageAudio'), dataField: 'featureLanguageAudio' },
      { caption: this.translateService.instant('featureSuisa'), dataField: 'featureSuisa' },
      { caption: 'FilmRRC', dataField: 'featureFilmRRC' },
      { caption: this.translateService.instant('advertisement') , dataField: 'annotationText'},
      { caption: 'techContentDataIdFeature' , dataField: 'techContentDataIdFeature'}
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
