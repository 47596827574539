import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { SiteNoticeDialogComponent } from '../site-notice-dialog/site-notice-dialog.component';

@Component({
  selector: 'app-privacy-policy-dialog',
  templateUrl: './privacy-policy-dialog.component.html',
  styleUrls: ['./privacy-policy-dialog.component.scss']
})
export class PrivacyPolicyDialogComponent implements OnInit {

  currentLanguage = 'de';

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<PrivacyPolicyDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private translateService: TranslateService) {
  }

  ngOnInit() {
    if (_.isUndefined(this.translateService.currentLang)) {
      const defaultLanguage = this.translateService.getDefaultLang();
      if (defaultLanguage) {
        this.currentLanguage = this.translateService.getDefaultLang();
      } else {
        this.currentLanguage = 'de';
      }
    } else {
      this.currentLanguage = this.translateService.currentLang;
    }
  }

  onOkClick() {
    this.dialogRef.close(`${true}`);
  }

  onNoClick(): void {
    this.dialogRef.close(`${false}`);
  }

  onShowSiteNotice() {
    this.dialog.open(SiteNoticeDialogComponent);
  }

}
