export class AudioSystemModel {
    /// <summary>
    /// Name des Audiosystems
    /// </summary>
    name: string;

    screen: number;

    surround_5_1: boolean;

    surround_7_1: boolean;

    dolbyAtmos: boolean;
}
