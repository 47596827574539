import { Component, OnInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-dialog-trigger',
  templateUrl: './dialog-trigger.component.html',
  styleUrls: ['./dialog-trigger.component.css']
})
export class DialogTriggerComponent implements OnInit {

  @Input()
  public triggerTemplate: TemplateRef<any>;

  @ViewChild('contentRef')
  public contentRef: TemplateRef<any>;

  constructor(private _dialog: MatDialog) { }

  ngOnInit() {
  }

  public triggerClick() {
    this._dialog.open(this.contentRef);
  }
}
