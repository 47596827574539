export class BillingContactsResult {
    public contentTitle: string;
    public filmRRC: number;
    public suisaNr: string;
    public lastWeekSum: number;
    public currentWeekSum: number;
    public cjw: number;
    public year: number;
    public week: number;
    public day0: number;
    public day1: number;
    public day2: number;
    public day3: number;
    public day4: number;
    public day5: number;
    public day6: number;
}
