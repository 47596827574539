import { MaintenancePageComponent } from './layouts/maintenance-page/maintenance-page.component';
import { DialogsModule } from './layouts/dialogs/dialogs.module';
import { PageFooterComponent } from './shared/modules/page-footer/page-footer.component';
import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatSnackBarModule, MatButtonModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { TranslatePoHttpLoader } from '@biesbjerg/ngx-translate-po-http-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
// Import DevExtreme and DevExteme-intl
import 'devextreme-intl';
// import it to change locale and load localization messages
import { loadMessages, locale } from 'devextreme/localization';
import * as messagesDe from 'devextreme/localization/messages/de.json';
import * as messagesEn from 'devextreme/localization/messages/en.json';
import * as messagesIt from 'devextreme/localization/messages/it.json';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RequestErrorHandler } from './network/error-handler';
import { AuthInterceptor } from './network/interceptors/auth-interceptor';
import { RequestErrorInterceptor } from './network/interceptors/request-error-interceptor';
import { RoundtripInterceptor } from './network/interceptors/roundtrip-interceptor';
import { SpinnerInterceptor } from './network/interceptors/spinner-interceptor';
import { AuthGuard } from './shared/modules/guard/auth-guard';
import { AuthService } from './shared/services/auth.service';
import { PermissionsService } from './shared/services/permissions.service';
import { UserIdleModule } from 'angular-user-idle';
import { MaintenanceService } from './shared/services/maintenance.service';
import {
  ApplicationInsightsModule,
  AppInsightsService
} from '@markpieszak/ng-application-insights';
import { environment } from '../environments/environment';
import { NotificationsService } from './shared/services/notifications.service';
import {
  DxButtonModule,
  DxFormModule,
  DxTextBoxModule,
  DxTextAreaModule,
  DxSelectBoxModule,
  DxValidatorModule,
  DxTooltipModule
} from 'devextreme-angular';
import { WidgetsModule } from './widgets/widgets.module';

loadMessages(messagesDe);
loadMessages(messagesEn);
loadMessages(messagesIt);

// Set locale according the browser language
locale(navigator.language);

// configuring translation loader

export function createTranslateLoader(http: HttpClient) {
  return new TranslatePoHttpLoader(http, 'assets/i18n', '.po');
}

@NgModule({
  declarations: [AppComponent, PageFooterComponent, MaintenancePageComponent],
  imports: [
    CommonModule,
    BrowserModule,
    LayoutModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatSnackBarModule,
    NgxJsonViewerModule,
    DialogsModule,
    MatButtonModule,
    DxButtonModule,
    DxFormModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxSelectBoxModule,
    DxValidatorModule,
    WidgetsModule,
    DxTooltipModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    UserIdleModule.forRoot({ idle: 1800, timeout: 60, ping: 120 }),
    ApplicationInsightsModule.forRoot({
      instrumentationKey: environment.appInsights.instrumentationKey
    }),
    NgbModule.forRoot()
  ],
  providers: [
    MaintenanceService,
    AuthGuard,
    RequestErrorHandler,
    AppInsightsService,
    NotificationsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RoundtripInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestErrorInterceptor,
      multi: true,
      deps: [RequestErrorHandler, Router, AuthService, PermissionsService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
