import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-cinema-survey-selection-dialog",
  templateUrl: "./cinema-survey-selection-dialog.component.html",
  styleUrls: ["./cinema-survey-selection-dialog.component.scss"]
})
export class CinemaSurveySelectionDialogComponent implements OnInit {
  title: string;
  content: string;
  checkBoxOptions: any;
  formValues: any;
  checkBoxResult = true;

  constructor(
    public dialogRef: MatDialogRef<CinemaSurveySelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title;
    this.content = data.content;
    this.checkBoxOptions = {
      value: true,
      text: this.content,
      onValueChanged: e => {
        this.checkBoxResult = e.value;
      }
    };
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {}

  onOkClick() {
    this.dialogRef.close(`${this.checkBoxResult}`);
  }
}
