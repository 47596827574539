import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { BillingContactsRequest } from '../../../../../../models/billing-contacts-request';
import { BillingContacts } from '../../../../../../models/billing-contacts';
import { BillingContactsDetails } from '../../../../../../models/billing-contacts-details';
import { BillingContactsResult } from '../../../../../../models/billing-contacts-result';
import { ApiUrlService } from '../../../../../../shared/services/api-url.service';
import { PortalApiDataService } from '../../../../../../shared/services/portal-api-data.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { DxGridHeading } from '../../../../../../helpers/dx-grid-heading';
import { DatagridComponent } from '../../../../../../widgets/datagrid/datagrid.component';
import { Subscription } from 'rxjs';
import { DxTotalItem } from '../../../../../../helpers/dx-total-item';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-cinema-audience-tab',
  templateUrl: './cinema-audience-tab.component.html',
  styleUrls: ['./cinema-audience-tab.component.scss']
})
export class CinemaAudienceTabComponent implements OnInit, OnDestroy {

  
  @ViewChild(DatagridComponent)
  public dataGrid: DatagridComponent;

  private _techCinemaId: number;
  private _techScreenId: number;

  public columns: DxGridHeading[] = [];
  data: BillingContactsResult[] = [];

  weekDays: Date[] = [];
  stylingMode = 'outlined';
  private subscription: Subscription;
  public totalItems: DxTotalItem[] = [];
  week: number;

  @Input()
  set techCinemaId(techCinemaId: number) {
    this._techCinemaId = techCinemaId;
  }

  get techCinemaId() {
    return this._techCinemaId;
  }

  @Input()
  set techScreenId(techScreenId: number) {
    this._techScreenId = techScreenId;
  }

  get techScreenId() {
    return this._techScreenId;
  }

  constructor(public urlApiService: ApiUrlService, public httpClient: HttpClient, private translateService: TranslateService) { }

  ngOnInit() {
    if (this.dataGrid) {
      this.dataGrid.grid.instance.clearFilter();
    }

    this.subscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateGrid();
    });
    this.requestData();
  }

  requestData() {
    if (this.techCinemaId) {
      const request: BillingContactsRequest = new BillingContactsRequest();
      request.techCinemaId = this.techCinemaId;
      request.techScreenId = this.techScreenId;
      request.date = moment().utc().format('YYYY-MM-DD HH:mm:ss.000');
      this.httpClient.post(this.urlApiService.getBillingContactsUrl(), request).subscribe(
        data => {
          this.data.length = 0;
          this.weekDays.length = 0;
          const result = data as BillingContacts;
          this.weekDays = result.weekDays;
          const contacts = result.contacts;
          this.week = result.week;
          this.updateGrid();

          for (let index = 0; index < contacts.length; index++) {
            const element: BillingContactsDetails = contacts[index];
            const billingContactsResult: BillingContactsResult = new BillingContactsResult();
            billingContactsResult.contentTitle = element.contentTitle;
            billingContactsResult.lastWeekSum = element.lastWeekSum;
            billingContactsResult.filmRRC = element.filmRRC;
            billingContactsResult.suisaNr = element.suisaNr;
            billingContactsResult.currentWeekSum = element.currentWeek.sum;
            billingContactsResult.day0 = element.currentWeek.dayContacts[0];
            billingContactsResult.day1 = element.currentWeek.dayContacts[1];
            billingContactsResult.day2 = element.currentWeek.dayContacts[2];
            billingContactsResult.day3 = element.currentWeek.dayContacts[3];
            billingContactsResult.day4 = element.currentWeek.dayContacts[4];
            billingContactsResult.day5 = element.currentWeek.dayContacts[5];
            billingContactsResult.day6 = element.currentWeek.dayContacts[6];
            this.data.push(billingContactsResult);
          }
        }
      );
    }
  }

  updateGrid() {

    if (this.weekDays.length === 7) {
      let localLanguage = this.translateService.getDefaultLang();
      if (!_.isUndefined(this.translateService.currentLang)) {
        localLanguage = this.translateService.currentLang;
      }

      moment.locale(localLanguage);
      this.columns.length = 0;
      this.columns.push(
        { caption: this.translateService.instant('contentTitle'), dataField: 'contentTitle' },
        { caption: this.translateService.instant('filmRRC'), dataField: 'filmRRC' },
        { caption: this.translateService.instant('suisaId'), dataField: 'suisaNr' },
        { caption: this.translateService.instant('Vorwoche'), dataField: 'lastWeekSum' },
        { caption: this.translateService.instant('Woche'), dataField: 'currentWeekSum', ownerBand: 5 },
        { caption: this.translateService.instant('Kinowoche') + ' ' + this.week, isBand: true },
        { caption: moment(this.weekDays[0]).format('ddd DD.MM'), dataField: 'day0', ownerBand: 5, headerCellTemplate: this.headerCellTemplate },
        { caption: moment(this.weekDays[1]).format('ddd DD.MM'), dataField: 'day1', ownerBand: 5, headerCellTemplate: this.headerCellTemplate },
        { caption: moment(this.weekDays[2]).format('ddd DD.MM'), dataField: 'day2', ownerBand: 5, headerCellTemplate: this.headerCellTemplate },
        { caption: moment(this.weekDays[3]).format('ddd DD.MM'), dataField: 'day3', ownerBand: 5, headerCellTemplate: this.headerCellTemplate },
        { caption: moment(this.weekDays[4]).format('ddd DD.MM'), dataField: 'day4', ownerBand: 5, headerCellTemplate: this.headerCellTemplate },
        { caption: moment(this.weekDays[5]).format('ddd DD.MM'), dataField: 'day5', ownerBand: 5, headerCellTemplate: this.headerCellTemplate },
        { caption: moment(this.weekDays[6]).format('ddd DD.MM'), dataField: 'day6', ownerBand: 5, headerCellTemplate: this.headerCellTemplate }

      );

      this.totalItems.length = 0;
      this.totalItems.push(
        { summaryType: 'sum', column: 'lastWeekSum', displayFormat: '{0}' },
        { summaryType: 'sum', column: 'currentWeekSum', displayFormat: '{0} ' },
        { summaryType: 'sum', column: 'day0', displayFormat: '{0}' },
        { summaryType: 'sum', column: 'day1', displayFormat: '{0}' },
        { summaryType: 'sum', column: 'day2', displayFormat: '{0}' },
        { summaryType: 'sum', column: 'day3', displayFormat: '{0}' },
        { summaryType: 'sum', column: 'day4', displayFormat: '{0}' },
        { summaryType: 'sum', column: 'day5', displayFormat: '{0}' },
        { summaryType: 'sum', column: 'day6', displayFormat: '{0}' },
      );
    }
  }

  headerCellTemplate(elem, data) {
    console.log(data);
    return elem.innerHTML = '<span style="color: #b1bbc7;">' + data.column.caption + '</span>';
  }

  getCenterDisplayExpr(item) {
    if (!item) {
      return '';
    }
    return item.name1 + ', ' + item.city + ' (' + item.theaterNr + ')';
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
