import { CalculateNextCallRequestRuleDetailsDialogComponent } from './../../layouts/dialogs/components/calculate-next-call-request-rule-details-dialog/calculate-next-call-request-rule-details-dialog.component';
import { JsonDialogComponent } from './../../layouts/dialogs/components/json-dialog/json-dialog.component';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  NgZone,
  HostListener,
  ChangeDetectorRef,
  TemplateRef
} from '@angular/core';
import { NgModelProvider, customControlValueAccessor } from '../../helpers/ng-model-provider';
import { DxDataGridComponent } from 'devextreme-angular';
import { Observable, Subject } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { map, debounceTime } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { VideoDialogComponent } from '../../layouts/dialogs/components/video-dialog/video-dialog.component';
import { PlayoutPdfService } from '../../shared/services/playout-pdf.service';
import * as FileSaver from 'file-saver';
import { Router } from '@angular/router';
import { LastRunReturnCodes } from '../../models/enums/last-run-return-codes.enum';

@Component({
  selector: 'app-datagrid',
  templateUrl: './datagrid.component.html',
  providers: [customControlValueAccessor(DatagridComponent as Component)],
  styleUrls: ['./datagrid.component.css']
})
export class DatagridComponent extends NgModelProvider<any> implements OnInit {
  @ViewChild('grid')
  public grid: DxDataGridComponent;

  @Input()
  public gridName: string;
  j;
  @Input()
  public selectionMode = 'multiple';

  @Input()
  public allMode = 'allPages';

  @Input()
  public checkBoxesMode = 'always';

  @Input()
  public scrollingMode = 'standard';

  @Input()
  public pageSize = 20;

  @Input()
  public allowedPageSizes = [5, 10, 20, 60, 100];

  @Input()
  public source: any[] = [];

  @Input()
  public columns: any[] = [];

  @Input()
  public totalItems: any[] = [];

  @Input()
  public groupItems: any[] = [];

  @Input()
  public calculateCustomSummary: any;

  @Input()
  public showFilterRow = true;

  @Input()
  public showHeaderFilterRow = true;

  @Input()
  public showSearchPanel = true;

  @Input()
  public allowColumnReordering = true;

  @Input()
  public enableColumnChooser = true;

  @Input()
  public enableStateStoring = true;

  @Input()
  public allowCollapsing = false;

  @Input()
  public autoExpandAll = true;

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  public onSelectionChanged: EventEmitter<any[]> = new EventEmitter();

  @Output()
  public refreshData = new EventEmitter();

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  public onRowClicked: EventEmitter<any> = new EventEmitter();

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  public onContentReadyEvent: EventEmitter<any> = new EventEmitter();

  @Output()
  public dataChanged = new EventEmitter();

  @Output()
  public rowInserted = new EventEmitter<any>();

  @Output()
  public rowRemoved = new EventEmitter<any>();

  @Output()
  public rowUpdated = new EventEmitter<any>();

  @Input()
  public triggerTemplate: TemplateRef<any>;

  @ViewChild('contentRef')
  public contentRef: TemplateRef<any>;

  @Input()
  public allowDeleting = false;

  @Input()
  public allowUpdating = false;

  @Input()
  public allowAdding = false;

  @Input()
  public editingMode = 'form';

  @Input()
  public sortingMode = 'multiple';

  @Input()
  public allowClear = false;

  @Input()
  public gridHeight = '100%';

  @Input()
  public showResizeOption = false;

  @Input()
  public enableExcelExport = true;

  @Input()
  public useNativeScrolling = true;

  @Input()
  public excelExportFileName = 'Overview';

  @Input()
  public allowTotalGroupCount = true;

  public navigateToPlayoutViewButtontext = 'Buchung';

  private editSubject = new Subject();

  totalCount: number;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  columnResizing = 'widget';

  constructor(
    private zone: NgZone,
    private cd: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private playoutPdfService: PlayoutPdfService,
    private router: Router
  ) {
    super();
    this.editSubject.pipe(debounceTime(400)).subscribe(_ => {
      this.dataChanged.emit();
    });
  }

  ngOnInit() {}

  public selectionChanged(sel) {
    this.onSelectionChanged.emit(sel.selectedRowsData);
  }

  public onRowInserted(rowData: any) {
    this.editSubject.next();
    this.rowInserted.emit({ data: rowData.data });
  }

  public onRowUpdated(rowData: any) {
    this.editSubject.next();
    this.rowUpdated.emit({ data: rowData.data, key: rowData.key });
  }

  public onRowRemoved(rowData: any) {
    this.editSubject.next();
    this.rowRemoved.emit(rowData.key);
  }

  public onRowPrepared(e) {
    if (e.rowType === 'data') {
      // import 'devextreme/integration/jquery';
      if (e.data.inQueue === true) {
        e.rowElement.style.backgroundColor = '#F5F6CE';
      }
      if (e.data.inProgress === true) {
        e.rowElement.style.backgroundColor = '#CED8F6';
      }
      if (e.data.isLocked === true) {
        e.rowElement.style.backgroundColor = '#F78181';
      }
      if (e.data.runReturnCode === LastRunReturnCodes.endedOk) {
        e.rowElement.style.backgroundColor = '#339933';
      }
      if (e.data.runReturnCode === LastRunReturnCodes.started) {
        e.rowElement.style.backgroundColor = '#e68a00';
      }
      if (e.data.runReturnCode === LastRunReturnCodes.endedWithFailure) {
        e.rowElement.style.backgroundColor = '#cc0000';
      }
    }
  }

  public onToolbarPreparing(e) {
    if (this.allowTotalGroupCount) {
      e.toolbarOptions.items.unshift(
        {
          location: 'before',
          template: 'totalGroupCount',
          locateInMenu: 'auto'
        },
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          showText: 'inMenu',
          options: {
            hint: 'Refresh',
            icon: 'refresh',
            text: 'Refresh',
            onClick: this.onRefreshButtonClick.bind(this)
          }
        }
      );
    }

    if (this.allowClear) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        showText: 'inMenu',
        options: {
          hint: 'Clear Filters',
          icon: 'remove',
          text: 'Clear Filters',
          onClick: this.clearFilters.bind(this)
        }
      });
    }

    if (this.showResizeOption) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxTextBox',
        locateInMenu: 'auto',
        showText: 'inMenu',
        options: {
          hint: 'Resize Grid',
          width: 100,
          text: this.gridHeight,
          onValueChanged: this.groupChanged.bind(this)
        }
      });
    }
  }

  groupChanged(e) {
    this.gridHeight = e.value;
    this.grid.instance.refresh();
  }

  public clearFilters(e) {
    // we don't want to reset everything - just reset filterValues
    // and filterValue.
    // For resetting everything it's sufficient to just
    // set grid.instance.state to {}
    const state = this.grid.instance.state();
    if (!state) {
      return;
    }

    state['searchText'] = '';

    const columns = state['columns'];
    if (!columns) {
      return;
    }

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].filterValue) {
        delete state['columns'][i].filterValue;
      }

      if (columns[i].filterValues) {
        delete state['columns'][i].filterValues;
      }
    }

    this.grid.instance.state(state);
    this.refreshData.emit();
  }

  public onRefreshButtonClick() {
    this.refreshData.emit();
  }

  public clickHandler(sel) {
    this.onRowClicked.emit(sel.data);
  }

  public onContentReady($event) {
    this.onContentReadyEvent.emit($event);
    this.totalCount = this.grid.instance.totalCount();
    this.cd.detectChanges();
  }

  public triggerClick() {
    console.log();
  }

  public onResize($event) {}

  // TODO move this to corresponding classes

  public openVideoDialog(data) {
    const dialogRef = this.dialog.open(VideoDialogComponent, {
      width: '500px',
      data: data.value
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  public openJsonDialog(data) {
    if (data.value) {
      const dialogRef = this.dialog.open(JsonDialogComponent, {
        width: '75%',
        height: '75%',
        data: JSON.parse(data.value)
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    }
  }

  public historicDownloadPDF(data) {
    const request = this.playoutPdfService.generateTranslation(
      data.data.techCinemaId,
      data.data.end
    );

    this.playoutPdfService.createHistoricReport(request).subscribe(
      response => {
        const pdfFile = new Blob([response], { type: 'application/pdf' });
        FileSaver.saveAs(pdfFile, request.techCinemaId + '.pdf');
      },
      err => {
        console.log('Error occured.');
      }
    );
  }

  public openNextCallDetailsDialog(data) {
    if (data.value) {
      const dialogRef = this.dialog.open(CalculateNextCallRequestRuleDetailsDialogComponent, {
        height: '75%',
        width: '75%',
        data: data.value
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    }
  }

  public navigateToPlayoutView(data) {
    this.dialog.closeAll();
    const url = 'playout;theaterNr=' + data.data['originTheaterNr'];
    window.open(url);
  }
}
