export class ProjectorModel {
  /// <summary>
  /// Name des Projektors
  /// </summary>
  name: string;

  /// <summary>
  /// Saal
  /// </summary>
  screen: number;

  /// <summary>
  /// 2k Ready
  /// </summary>
  resolution2k: boolean;

  /// <summary>
  /// 4k Ready
  /// </summary>
  resolution4k: boolean;

  /// <summary>
  /// 3D Ready
  /// </summary>
  ready3D: boolean;

  /// <summary>
  /// Ready Imax
  /// </summary>
  readyImax: boolean;

  /// <summary>
  /// Bildwiederholrate
  /// </summary>
  fps: string;

  /// <summary>
  /// Sonstige Informationen
  /// </summary>
  additionalInformation: string;

  /// <summary>
  /// Projektor Hersteller
  /// </summary>
  projectorManufacteur: string;
}
