export enum CinemaRegistrationStatus {
  /// <summary>
  /// Die Registrierung ist im System aufgelaufen
  /// </summary>
  Pending = 0,

  /// <summary>
  /// Es wurde Notiz von der Registrierung genommen
  /// </summary>
  Acknowledged = 1,

  /// <summary>
  /// Die Registrierung wurde erfolgreich bearbeitet
  /// </summary>
  Approved = 2,

  /// <summary>
  /// Die Registrierung wurde abgelehnt
  /// </summary>
  Rejected = 3,

  /// <summary>
  /// Die Persönlichen/Technischen Registrierungdaten wurde gelöscht
  /// </summary>
  Deleted = 4,

  /// <summary>
  /// Unvollständige Registrierung
  /// </summary>
  Incomplete = 5
}
