export class PortalPlayoutInformationModel {
    annotationText: string;
    blockId: number;
    enabled: boolean;
    featureDepth: string;
    featureExclusive: boolean;
    featureLanguageAudio: string;
    featureLanguageSubtitles: string;
    featureTitle: string;
    fromDate: Date;
    fromTime: Date;
    playoutId: number;
    position: number;
    positionId: number;
    rating: string;
    runningTime: number;
    startingNumber: number;
    techContentDataIdFeature: number;
    techScreenId: number;
    toDate: Date;
    toTime: Date;
    guiCaption: string;
    counter: number;
    translatedBlockId: string;
    translatedStartTime: string;
    translatedPosition: string;
    downloadLink: string;
    mediaFormat: string;
    mute: string;
    firstPlayout?: boolean;
    downloadLinkPreview: string;
    translatedMute: string;
    featureFilmRRC?: number;
    featureSuisa: string;
    event?: boolean;
    imax?: boolean;
    ratingFeature: string;
    techCinemaId: number;
}


