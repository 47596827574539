import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { DownloadLinkCreationRequestModel } from '../../../../models/download-link-creation-request-model';
import { ApiUrlService } from '../../../../shared/services/api-url.service';
import { DownloadStorageContainer } from '../../../../models/enums/download-storage-container.enum';
import * as moment from 'moment';
import { ClipboardService } from 'ngx-clipboard';


@Component({
  selector: 'app-download-link-generator-dialog',
  templateUrl: './download-link-generator-dialog.component.html',
  styleUrls: ['./download-link-generator-dialog.component.scss']
})
export class DownloadLinkGeneratorDialogComponent implements OnInit {

  public techContentDataId: number;
  public annotationText: string;
  toTime: any;
  private fileName: string;
  downloadlink: string;
  mp4hq: string;
  mp4lq: string;
  containerFormatItems = [DownloadStorageContainer[DownloadStorageContainer.dcp], DownloadStorageContainer[DownloadStorageContainer.mp4hq], DownloadStorageContainer[DownloadStorageContainer.mp4lq]];
  containerFormat = DownloadStorageContainer[DownloadStorageContainer.dcp];

  constructor(private translateService: TranslateService, public urlApiService: ApiUrlService, public httpClient: HttpClient, private clipboardService: ClipboardService, public dialogRef: MatDialogRef<DownloadLinkGeneratorDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.techContentDataId = data.techContentDataId;
    this.annotationText = data.annotationText;
    this.fileName = data.url;
    this.mp4lq = data.mp4lq;
    this.mp4hq = data.mp4hq;
    let currentDate =  new Date();
    // add 14 days to current date
    this.toTime = currentDate.setDate(currentDate.getDate() + 14);
  }

  ngOnInit() {

  }

  onClickSend() {
    const request: DownloadLinkCreationRequestModel = new DownloadLinkCreationRequestModel();
    const toDate = moment(this.toTime).utc().format('YYYY-MM-DD HH:mm:ss.000');
    request.date = toDate;
    switch (this.containerFormat) {
      case DownloadStorageContainer[DownloadStorageContainer.dcp]:
        request.fileName = this.fileName;
        break;
      case DownloadStorageContainer[DownloadStorageContainer.mp4lq]:
        request.fileName = this.mp4lq;
        break;
      case DownloadStorageContainer[DownloadStorageContainer.mp4hq]:
        request.fileName = this.mp4hq;
        break;
      default:
        request.fileName = this.fileName;
        break;
    }
    request.storageContainer = this.containerFormat;
    this.httpClient.post(this.urlApiService.getGenerateDownloadLinkUrl(), request).subscribe(res => {
      this.downloadlink = res as string;
    });
  }

  onCopyFrom() {
    this.clipboardService.copyFromContent(this.downloadlink);
  }

}
