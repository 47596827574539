import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-standard-yes-no-dialog",
  templateUrl: "./standard-yes-no-dialog.component.html",
  styleUrls: ["./standard-yes-no-dialog.component.scss"]
})
export class StandardYesNoDialogComponent implements OnInit {
  title: string;
  content: string;

  constructor(
    public dialogRef: MatDialogRef<StandardYesNoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title;
    this.content = data.content;
    this.dialogRef.disableClose = data.disableClose ? true : false;
  }

  ngOnInit() {}

  onOkClick() {
    this.dialogRef.close(`${true}`);
  }

  onNoClick(): void {
    this.dialogRef.close(`${false}`);
  }
}
