export enum LastRunReturnCodes {
        unknown = 0,
        /// <summary>
        /// run started
        /// </summary>
        started = 1,
        /// <summary>
        /// run ended with errors
        /// </summary>
        endedWithFailure = 2,
        /// <summary>
        /// success
        /// </summary>
        endedOk = 99,
}
