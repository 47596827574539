import { CinemaModel } from './../../../../models/cinema-model';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiUrlService } from '../../../../shared/services/api-url.service';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { CinemaRequestModel } from '../../../../models/cinema-request-model';
import { CompanyOperatorModel } from '../../../../models/company-operator-model';
import { CinemaGroupModel } from '../../../../models/cinema-group-model';
import notify from 'devextreme/ui/notify';
import { RequestRulesModel } from '../../../../models/request-rules-model';
import { ScreenModel } from '../../../../models/screen-model';
import { MatDialogRef } from '@angular/material';


@Component({
  selector: 'app-calculate-next-call-request-rule-create-dialog',
  templateUrl: './calculate-next-call-request-rule-create-dialog.component.html',
  styleUrls: ['./calculate-next-call-request-rule-create-dialog.component.scss']
})
export class CalculateNextCallRequestRuleCreateDialogComponent implements OnInit {

  public cinemas: CinemaModel[];
  public screens: ScreenModel[];

  public cinemaGroups: CinemaGroupModel[];
  public companyOperator: CompanyOperatorModel[];

  public selectedCinema: CinemaModel;
  public selectedScreen: ScreenModel;
  public selectedCinemaGroups: CinemaGroupModel;
  public selectedCompanyOperator: CompanyOperatorModel;
  public description: string;
  public isActiveRule: boolean = false;

  public dataModel: RequestRulesModel;

  public buttonOptions: any = {
    text: this.translateService.instant('Neue Regel erstellen'),
    type: "success",
    useSubmitBehavior: true
  }

  constructor(public urlApiService: ApiUrlService, public httpClient: HttpClient, private translateService: TranslateService, public dialogRef: MatDialogRef<CalculateNextCallRequestRuleCreateDialogComponent>) { }

  ngOnInit() {

    const request: CinemaRequestModel = new CinemaRequestModel();
    request.allowPlayout = false;
    request.allowBookingList = true;
    request.onlyActiveScreens = true;

    const cinemas = this.httpClient.post(this.urlApiService.getCinemas(), request);
    const companyGroups = this.httpClient.get(this.urlApiService.getCompanyGroupsUrl());
    const cinemaGroups = this.httpClient.get(this.urlApiService.getCinemaGroupsUrl());

    forkJoin([cinemas, companyGroups, cinemaGroups]).subscribe(results => {
      this.cinemas = results[0] as CinemaModel[];
      this.companyOperator = results[1] as CompanyOperatorModel[];
      this.cinemaGroups = results[2] as CinemaGroupModel[];
    })
  }

  onClickSend() {
    const request = new RequestRulesModel();
    if (this.selectedCinema) {
      request.techCinemaId = this.selectedCinema.techCinemaId;
    }
    if (this.selectedScreen) {
      request.techScreenId = this.selectedScreen.techScreenId;
    }
    if (this.selectedCinemaGroups) {
      request.techCinemaGroupId = this.selectedCinemaGroups.techCinemaGroupId;
    }
    if (this.selectedCompanyOperator) {
      request.techCompanyOperatorId = this.selectedCompanyOperator.techCompanyOperatorId;
    }
    request.bezeichnung = this.description;
    request.active = this.isActiveRule;

    this.httpClient.post(this.urlApiService.getRequestRulesInsertRequestRuleUrl(),
      request
    ).subscribe(
      data => {
        notify({
          message: this.translateService.instant('Die Request Regel wurde erfolgreich angelegt. Danke!'),
          position: {
            my: "center top",
            at: "center top"
          }
        }, "success", 3000);
        this.dialogRef.close();
      },
      err => {
        notify({
          message: this.translateService.instant('Es ist ein Fehler aufgetreten!'),
          position: {
            my: "center top",
            at: "center top"
          }
        }, "error", 3000);
      }
    )
  }

  public onTheaterselectionChanged(event: any) {
    if (event && event.value) {
      this.selectedCinema = event.value as CinemaModel;
      this.screens = this.selectedCinema.screens as ScreenModel[];
      this.screens.sort((a, b) => {
        if (a.name1 < b.name1) { return -1; }
        if (a.name1 > b.name1) { return 1; }
        return 0;
      });
      this.selectedScreen = this.screens[0];
    }
  }

  getCenterDisplayExpr(item) {
    if (!item) {
      return '';
    }

    return item.name1 + ', ' + item.city + ' (' + item.theaterNr + ')';
  }

  getDisplayExpr(item) {
    if (!item) {
      return '';
    }

    return item.name1 + ' (' + item.theaterNr + ')';
  }

  getCinemaGroupDisplayExpr(item) {
    if (!item) {
      return '';
    }

    return item.name1 + ', (' + item.crmGroupId + ')';
  }

  getCompanyOperatorDisplayExpr(item) {
    if (!item) {
      return '';
    }

    return item.companyName1 + ', ' + item.city + ' (' + item.crmCompanyOperatorId + ')';
  }



}
