export const environment = {
    envName: 'test',
    production: false,
    apiEndpoint: 'https://ae-sl-dev-api-portal.azurewebsites.net/',
    authenticationApiEndpoint: 'https://ae-sl-dev-api-authentication.azurewebsites.net/',
    bookingDataAggregatorEndpoint: 'https://dabooking-api-dev.spotlog.media/',
    cinemaMasterAggregatorEndpoint: 'https://dacinema-api-dev.spotlog.media/',
    contentCatalogAggregatorEndpoint: 'https://ae-sl-dev-api-aggregator-cc.azurewebsites.net/',
    userAggregatorEndpoint: 'https://dauser-api-dev.spotlog.media/',
    miscellaneousDataAggregatorEndpoint: 'https://damisc-api-dev.spotlog.media/',
    tokenEndpoint: 'https://ae-sl-dev-api-authentication.azurewebsites.net/',
    appInsights: {
        instrumentationKey: ''
    },
    hubApiUrl: 'https://ae-sl-dev-api-portal.azurewebsites.net/'
};
