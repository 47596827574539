import { CinemaPlayoutScreenDetailsDialogComponent } from './../../../cinema-playout-screen-details-dialog/cinema-playout-screen-details-dialog.component';
import { ScreenModel } from './../../../../../../models/screen-model';
import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { ApiUrlService } from '../../../../../../shared/services/api-url.service';
import { HttpClient } from '@angular/common/http';
import { DxGridHeading } from '../../../../../../helpers/dx-grid-heading';
import { CinemaModel } from '../../../../../../models/cinema-model';
import { DatagridComponent } from '../../../../../../widgets/datagrid/datagrid.component';
import { Subscription } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';

@Component({
  selector: 'app-general-info-tab',
  templateUrl: './general-info-tab.component.html',
  styleUrls: ['./general-info-tab.component.scss']
})
export class GeneralInfoTabComponent implements OnInit, OnDestroy {

  @ViewChild(DatagridComponent)
  public dataGrid: DatagridComponent;

  @Input()
  set techCinemaId(techCinemaId: number) {
    this._techCinemaId = techCinemaId;
    this.requestData();
  }

  get techCinemaId() {
    return this._techCinemaId;
  }

  private subscription: Subscription;
  private _techCinemaId: number;
  public columns: DxGridHeading[] = [];
  data: ScreenModel[] = [];
  cinemaModel: CinemaModel;


  constructor(public urlApiService: ApiUrlService, public httpClient: HttpClient, private translateService: TranslateService, public dialog: MatDialog) { }

  ngOnInit() {
    if (this.dataGrid) {
      this.dataGrid.grid.instance.clearFilter();
    }

    this.subscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateGrid();
    });
  }

  requestData() {
    this.httpClient.post(this.urlApiService.getCinemaById(), this.techCinemaId).subscribe(
      response => {
        this.updateGrid();
        this.cinemaModel = response as CinemaModel;
        if (this.cinemaModel.firstLogin) {
          this.cinemaModel.firstLogin = moment(this.cinemaModel.firstLogin).format('DD.MM.YYYY HH:mm:ss');
        }

        for (let index = 0; index < this.cinemaModel.screens.length; index++) {
          const element = this.cinemaModel.screens[index];
          element.originTheaterNr = this.cinemaModel.theaterNr;
        }
        this.data = this.cinemaModel.screens;
      },
      err => {
        console.log('Error occured.');
      });
  }

  updateGrid() {
    this.columns.length = 0;
    this.columns.push(
      { caption: 'TheaterNr', dataField: 'theaterNr' },
      { caption: this.translateService.instant('nameColumn'), dataField: 'name1', allowSorting: true, sortOrder: 'asc' },
      { caption: this.translateService.instant('active'), dataField: 'active' },
      { caption: 'Aktuelle Buchungen', dataField: 'bookings', allowSorting: false, allowFiltering: false, cellTemplate: 'cellTemplateNavigateToPlayoutView' },
    );
  }

  onRowClicked(data: ScreenModel) {
    const dialogRef = this.dialog.open(CinemaPlayoutScreenDetailsDialogComponent, {
      height: '90%',
      width: '80%',
      data: {
        techCinemaId: this.techCinemaId,
        screenModel: data
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      dialogRef.close();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}



