import { RequestRulesDetailModel } from './../../../../models/request-rules-detail-model';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { ApiUrlService } from '../../../../shared/services/api-url.service';
import { DxGridHeading } from '../../../../helpers/dx-grid-heading';
import { TranslateService } from '@ngx-translate/core';
import notify from 'devextreme/ui/notify';


@Component({
  selector: 'app-calculate-next-call-request-rule-details-dialog',
  templateUrl: './calculate-next-call-request-rule-details-dialog.component.html',
  styleUrls: ['./calculate-next-call-request-rule-details-dialog.component.scss']
})
export class CalculateNextCallRequestRuleDetailsDialogComponent implements OnInit {

  private techRequestRulesId: number;
  columns: DxGridHeading[] = [];
  dataSource: RequestRulesDetailModel[] = [];



  constructor(private urlApiService: ApiUrlService, private httpClient: HttpClient, private translateService: TranslateService, private dialogRef: MatDialogRef<CalculateNextCallRequestRuleDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any) { 
    this.techRequestRulesId = data;
  }

  ngOnInit() {
    this.updateGrid();
    this.requestData();
  }

  requestData() {
    this.httpClient.post(this.urlApiService.getByRequestRuleDetailIdUrl(), this.techRequestRulesId).subscribe(
      data => {
        const result = data as RequestRulesDetailModel[];
        this.dataSource = result;
      }
    );
  }

  updateGrid() {
    this.columns.length = 0;
    this.columns.push(
      { caption: this.translateService.instant('Aktiv'), dataField: 'active', dataType: 'boolean' },
      { caption: this.translateService.instant('SecondsBeforeShow'), dataField: 'secondsBeforeShow' },
      { caption: this.translateService.instant('SecondsToNextCall'), dataField: 'secondsToNextCall' },
      { caption: this.translateService.instant('Bezeichnung'), dataField: 'bezeichnung' }
    );

  }

  public onRowInserted(data: any) {
    const request: RequestRulesDetailModel = new RequestRulesDetailModel();
    request.active = data.data.active;
    request.bezeichnung = data.data.bezeichnung;
    request.techRequestRulesId = this.techRequestRulesId;
    request.secondsBeforeShow = data.data.secondsBeforeShow;
    request.secondsToNextCall = data.data.secondsToNextCall;

    this.httpClient.post(this.urlApiService.getInsertRequestRuleDetailUrl(), request).subscribe(res => {
      notify('New Content added successfully');
      this.requestData();
    }, error => {
      notify('Adding Content failed');
      this.requestData();
    });
  }

  public onRowUpdated(data: any) {
    this.httpClient.patch(this.urlApiService.getUpdateRequestRuleDetailUrl(), data.data).subscribe(res => {
      notify('Content updated successfully');
      this.requestData();
    }, error => {
      notify('Content update failed');
      this.requestData();
    });
  }

  public onRowRemoved(data: RequestRulesDetailModel) {
    this.httpClient.post(this.urlApiService.getDeleteRequestRuleDetailUrl(), data.techRequestRulesDetailId).subscribe(res => {
      notify('Content deleted successfully');
      this.requestData();
    }, error => {
      notify('Content deleting failed');
      this.requestData();
    });
  }

}
