export class RequestRulesModel {
    techRequestRulesId: number;
    techScreenId?: number;
    techCinemaId?: number;
    techCinemaGroupId?: number;
    techCompanyOperatorId?: number;
    createName: string;
    createDate: Date;
    changeName: string;
    changeDate?: Date;
    changeCounter?: number;
    isDeleted: boolean;
    active: boolean;
    bezeichnung: string;
    original_ChangeCounter?: number;
}