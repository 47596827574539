export class RequestRulesDetailModel {
            /// <summary>
        /// Technischer Schlüssel
        /// </summary>
        public techRequestRulesDetailId :number;
        /// <summary>
        /// Technischer Schlüssel
        /// </summary>
        public techRequestRulesId :number;
        /// <summary>
        /// User der den Datensatz erstellt hat
        /// </summary>
        public createName :string;
        /// <summary>
        /// Zeitpunkt der Erstellung
        /// </summary>
        public createDate :Date;
        /// <summary>
        /// User der letzten Änderung
        /// </summary>
        public changeName :string;
        /// <summary>
        /// Zeitpunkt der letzten Änderung
        /// </summary>
        public changeDate? :Date;
        /// <summary>
        /// Änderungszähler um konkurrierende Updates zu verhindern
        /// </summary>
        public changeCounter? :number;
        /// <summary>
        /// Kennzeichen das die Row gelöscht ist
        /// </summary>
        public isDeleted :boolean;
        /// <summary>
        /// AktivKennzeichen 1 = aktiv, 0 = inaktiv
        /// </summary>
        public active :boolean;
        /// <summary>
        /// Rolle
        /// </summary>
        public secondsBeforeShow :number;
        /// <summary>
        /// Vorgabe Zeitraum bis zum nächsten Request des TMS
        /// </summary>
        public secondsToNextCall :number;
        /// <summary>
        /// Bezeichnung der Profilstufe
        /// </summary>
        public bezeichnung :string;
        /// <summary>
        /// 
        /// </summary>
        public original_ChangeCounter? :number;
}
