import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-cinema-playout-details-dialog',
  templateUrl: './cinema-playout-details-dialog.component.html',
  styleUrls: ['./cinema-playout-details-dialog.component.scss']
})
export class CinemaPlayoutDetailsDialogComponent implements OnInit {

  public techCinemaId: number;
  tabs: CinemaPlayoutDetailsTab[] = [];
  public selectedTabId = 1;


  constructor(public dialogRef: MatDialogRef<CinemaPlayoutDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.techCinemaId = data.techCinemaId;
    this.tabs.push({ id: 1, text: 'Übersicht' });
    this.tabs.push({ id: 2, text: 'Erweiterte Informationen' });
    this.tabs.push({ id: 3, text: 'Playout + Technik' });
    this.tabs.push({ id: 4, text: 'Besucherzahlen' });
    this.tabs.push({ id: 5, text: 'Aktuelle Filme' });
  }

  ngOnInit() {
  }

  selectTab(e) {
    this.selectedTabId = this.tabs[e.itemIndex].id;
  }

}

export class CinemaPlayoutDetailsTab {
  id: number;
  text: string;
}
