import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.scss']
})
export class VideoDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<VideoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: string) {
  }

  ngOnInit() {
  }

}

export interface InitPlayoutElement {
  No: number;
  Name: string;
  Position: string;
  DCN: number;
  FSK: number;
  Startzeit: string;
  SaalFilm: string;
  Laenge: number;
  Timerange?: string;
}
