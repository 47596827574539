import { HistoryRequestModel } from './../../../../models/history-request-model';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ApiUrlService } from '../../../../shared/services/api-url.service';
import { HttpClient } from '@angular/common/http';
import { PortalBookingInformationModel } from '../../../../models/portal-booking-information-model';
import { DxGridHeading } from '../../../../helpers/dx-grid-heading';
import * as moment from 'moment';
import { Subscription } from 'rxjs/internal/Subscription';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { TranslationStarttimePlayout } from '../../../../models/enums/translation-starttime-playout.enum';
import { TranslationPositionPlayout } from '../../../../models/enums/translation-position-playout.enum';


@Component({
  selector: 'app-cinema-booking-dialog',
  templateUrl: './cinema-booking-dialog.component.html',
  styleUrls: ['./cinema-booking-dialog.component.scss']
})
export class CinemaBookingDialogComponent implements OnInit, OnDestroy {

  public techContentDataId: number;
  public annotationText: string;
  public columns: DxGridHeading[] = [];
  public content: PortalBookingInformationModel[] = [];
  private subscription: Subscription;
  private fromDate: string;
  private toDate: string;
  private isHistoryData: boolean;

  constructor(private translateService: TranslateService, public urlApiService: ApiUrlService, public httpClient: HttpClient, public dialogRef: MatDialogRef<CinemaBookingDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.techContentDataId = data.techContentDataId;
    this.annotationText = data.annotationText;
    this.isHistoryData = data.isHistroydata;
    if (this.isHistoryData) {
      this.fromDate = data.fromDate;
      this.toDate = data.toDate;
    }
  }


  ngOnInit() {
    this.subscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateColumns();
    });
    this.updateColumns();
    this.requestContents();
  }

  updateColumns() {
    this.columns.length = 0;
    this.columns.push(
      { caption: this.translateService.instant('Advertising'), dataField: 'annotationText' },
      { dataField: 'featureTitle', groupIndex: '3', groupCellTemplate: this.groupingFormat },
      //{ caption: this.translateService.instant('Buchungszeitraum'), dataField: 'bookingPeriod', groupIndex: '1' },
      { caption: this.translateService.instant('Kinowoche'), dataField: 'week', groupIndex: '1' },

      { caption: this.translateService.instant('Kino'), dataField: 'nameKino', groupIndex: '2' },
      { caption: this.translateService.instant('Saal'), dataField: 'nameSaal', groupIndex: '4' },
      {  dataField: 'fromDate', groupIndex: '5', dataType: 'date' , groupCellTemplate: this.groupingFormatDate},
      { caption: 'PlayoutId', dataField: 'playoutId', groupIndex: '0' },
      { caption: this.translateService.instant('timeToStartColumn'), dataField: 'translatedStartTime' },
      { caption: this.translateService.instant('positionColumn'), dataField: 'translatedPosition' },
      { caption: this.translateService.instant('fskColumn'), dataField: 'rating' },
    );
  }

  requestContents() {
    const request = new HistoryRequestModel();
    request.techContentDataIdBooked = this.techContentDataId;
    request.searchInHistorie = this.isHistoryData;
    if (this.isHistoryData) {
      request.fromDate = this.fromDate;
      request.toDate = this.toDate;
    }

    this.httpClient.post(this.urlApiService.getCinemaBookingData(), request).subscribe(
      data => {
        const result = data as PortalBookingInformationModel[];
        for (let index = 0; index < result.length; index++) {
          const element = result[index];
          element.bookingPeriod = moment(element.fromDate).format('DD.MM.YYYY HH:mm') + ' - ' + moment(element.toDate).format('DD.MM.YYYY HH:mm');
          element.translatedStartTime = this.translateService.instant(TranslationStarttimePlayout[element.startingNumber]);
          element.translatedPosition = this.translateService.instant(TranslationPositionPlayout[element.positionId]);
        }
        this.content = result;
      }
    );
  }

  groupingFormat(elem, data) {
    if (data.displayValue === null) {
      return elem.innerHTML = '<span style="color: #b1bbc7;" translate>' + 'Saalbezogene Werbung' + '</span>';
    }
    return elem.innerHTML = '<span style="color: #b1bbc7;">' + data.displayValue + '</span>';
  }

  groupingFormatDate(elem, data) {
    return elem.innerHTML = '<span style="color: #b1bbc7;">' + moment(data.displayValue).format('dddd DD.MM.YYYY') + '</span>';
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
