import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { DxGridHeading } from '../../../../helpers/dx-grid-heading';
import { PortalBookingInformationModel } from '../../../../models/portal-booking-information-model';
import { ApiUrlService } from '../../../../shared/services/api-url.service';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs/internal/Subscription';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { TranslationStarttimePlayout } from '../../../../models/enums/translation-starttime-playout.enum';
import { TranslationPositionPlayout } from '../../../../models/enums/translation-position-playout.enum';
import * as moment from 'moment';
import { HistoryRequestModel } from '../../../../models/history-request-model';

@Component({
  selector: 'app-cinema-booking-feature-dialog',
  templateUrl: './cinema-booking-feature-dialog.component.html',
  styleUrls: ['./cinema-booking-feature-dialog.component.scss']
})
export class CinemaBookingFeatureDialogComponent implements OnInit, OnDestroy {

  public contentTitle: string;
  private techContentDataId: number;
  public columns: DxGridHeading[] = [];
  public content: PortalBookingInformationModel[] = [];
  private subscription: Subscription;
  private fromDate: string;
  private toDate: string;
  private isHistoryData: boolean;

  constructor(private translateService: TranslateService, public urlApiService: ApiUrlService, public httpClient: HttpClient, public dialogRef: MatDialogRef<CinemaBookingFeatureDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.contentTitle = data.contentTitle;
    this.techContentDataId = data.techContentDataId;
    this.isHistoryData = data.isHistroydata;
    if (this.isHistoryData) {
      this.fromDate = data.fromDate;
      this.toDate = data.toDate;
    }
  }

  ngOnInit() {
    this.subscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateColumns();
    });
    this.updateColumns();
    this.requestContents();
  }

  updateColumns() {
    this.columns.length = 0;
    this.columns.push(
      { caption: this.translateService.instant('contentTitle'), dataField: 'featureTitle', groupIndex: '0'  },
      { caption: this.translateService.instant('Advertising'), dataField: 'annotationText', groupIndex: '1' },
      { caption: this.translateService.instant('Buchungszeitraum'), dataField: 'bookingPeriod', groupIndex: '2'  },
      { caption: this.translateService.instant('Kino'), dataField: 'nameKino', groupIndex: '3'  },

      { caption: 'blockId', dataField: 'blockId' },
      { caption: 'city', dataField: 'city' },
      { caption: 'contentId', dataField: 'contentId' },
      { caption: 'enabled', dataField: 'enabled' },
      { caption: 'event', dataField: 'event' },
      { caption: 'featureDepth', dataField: 'featureDepth' },
      { caption: 'featureExclusive', dataField: 'featureExclusive' },
      { caption: 'featureFilmRRC', dataField: 'featureFilmRRC' },
      { caption: 'featureLanguageAudio', dataField: 'featureLanguageAudio' },
      { caption: 'featureLanguageSubtitles', dataField: 'featureLanguageSubtitles' },
      { caption: 'featureSuisa', dataField: 'featureSuisa' },
      { caption: 'mediaFormat', dataField: 'mediaFormat' },
      { caption: 'mute', dataField: 'mute' },
      { caption: 'nameSaal', dataField: 'nameSaal'},
      { caption: 'playoutId', dataField: 'playoutId'},
      { caption: 'position', dataField: 'position' },
      { caption: 'positionId', dataField: 'positionId' },
      { caption: 'rating', dataField: 'rating' },
      { caption: 'runningTime', dataField: 'runningTime' },
      { caption: 'startingNumber', dataField: 'startingNumber' },
      { caption: 'techContentDataId', dataField: 'techContentDataId'  },
      { caption: 'techContentDataIdFeature', dataField: 'techContentDataIdFeature' },
      { caption: 'techScreenId', dataField: 'techScreenId' },
      { caption: 'theaterNrkino', dataField: 'theaterNrkino' },
      { caption: 'theaterNrSaal', dataField: 'theaterNrSaal' },
    );
  }

  requestContents() {
    const request = new HistoryRequestModel();
    request.techContentDataIdFeature = this.techContentDataId;
    request.searchInHistorie = this.isHistoryData;
    if (this.isHistoryData) {
      request.fromDate = this.fromDate;
      request.toDate = this.toDate;
    }
    this.httpClient.post(this.urlApiService.getCinemaBookingFeatureData(), request).subscribe(
      data => {
        const result = data as PortalBookingInformationModel[];
        for (let index = 0; index < result.length; index++) {
          const element = result[index];
          element.bookingPeriod = moment(element.fromDate).format('DD.MM.YYYY HH:mm') + ' - ' + moment(element.toDate).format('DD.MM.YYYY HH:mm');
          element.translatedStartTime = this.translateService.instant(TranslationStarttimePlayout[element.startingNumber]);
          element.translatedPosition = this.translateService.instant(TranslationPositionPlayout[element.positionId]);
        }
        this.content = result;
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
