import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export function customControlValueAccessor(component: Component): any {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => component),
    multi: true
  };
}

const noop = () => {
};

export class NgModelProvider<T> implements ControlValueAccessor {
  private innerValue: T;

  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: T) => void = noop;

  public onValueChanged(value: T) {
  }

  // get accessor
  get value(): T {
    return this.innerValue;
  }

  // set accessor including call the onchange callback
  set value(v: T) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
      this.onValueChanged(v);
    }
  }

  // Set touched on blur
  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: T) {
    if (value !== this.innerValue) {
      this.innerValue = value;
      this.onValueChanged(value);
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
