export class CinemaSurveyModel {
  // Allgemeine Info
  public creationDate?: Date = new Date();
  public cinemaName?: string;
  public theaterNr?: number;
  public companyOperator?: string;
  public cinemaGroup?: string;
  public location?: string;
  public screenCount?: number;
  public serviceProvider?: string;
  public contactServiceProvider?: string;
  public contactWeischerMedia?: string;

  // Ansprechpartner Kino-Technik
  public gender?: number; //id: 0, name_tc: 'Herr' | translate}, {id: 1, name_tc: 'Frau'
  public firstName?: string;
  public lastName?: string;
  public phone?: string;
  public mobilePhone?: string;
  // Technischer Schlüssel der Festnetzvorwahl
  public techPhoneCodeIdLandline: number;
  // Technischer Schlüssel der Mobilvorwahl
  public techPhoneCodeIdMobile: number;

  public mail?: string;

  // Internetzugang
  public bitrate?: number; // [{id: 0, name_tc: '<16 Mbit/s'}, {id: 1, name_tc: '16-50 Mbit/s'}, {id: 2, name_tc: '>50 Mbit/s'}]
  public internetUsage?: number; // [{id: 0, name_tc: 'Exklusiv' | translate}, {id: 1, name_tc: 'Nicht exkl.' | translate}]
  public jointUsage?: string;

  // Internes Netzwerk
  public networkSpeed?: number; //[{id: 0, name_tc: '100 Mbit/s'}, {id: 1, name_tc: '1 Gbit/s'}, {id: 2, name_tc: '>1 Gbit/s'}, {id: 3, name_tc: 'Die Komponenten sind nicht miteinander vernetz' | translate}]

  // Kinosysteme TMS
  public tms?: number; // [{id: 0, name_tc: 'Arts Alliance'}, {id: 1, name_tc: 'DOLBY'}, {id: 2, name_tc: 'EIKONA'}, {id: 3, name_tc: 'SONY' | translate},
  //{id: 4, name_tc: 'UNIQUE'}, {id: 5, name_tc: 'ymagis'}, {id: 6, name_tc: 'Kinoton'}, {id: 7, name_tc: 'Sonstige' }, {id: 8, name_tc: 'Das Kino verwendet kein TMS'}]
  public softwareVersion?: string;
  public otherTms?: string;
  // Kinosysteme Kassensystem
  public accounting?: number; //[{id: 0, name_tc: 'Mars'}, {id: 1, name_tc: 'Compeso'}, {id: 2, name_tc: 'Cinetixx'}, {id: 3, name_tc: 'Vista'}, {id:4, name_tc: 'Sitec'},
  //{id:5, name_tc: 'Ticket'}, {id:6, name_tc: 'Sonstige' | translate}, {id:7, name_tc: 'Das Kino verwendet kein Kassensystem' | translate}]
  public accountingOther?: string;

  // Saalbezogene Technik Screen Server
  public dolbyScreenServer?: boolean = false;
  public dolbyScreenServerOptions?: any; //[{id: 0, name_tc: 'Alle Säle' | translate}, {id: 1, name_tc: 'In den Sälen:' | translate}]
  public dolbyScreens?: string;

  public dolbyIMS2000ScreenServer?: boolean = false;
  public dolbyIMS2000ScreenServerOptions?: number; //[{id: 0, name_tc: 'Alle Säle' | translate}, {id: 1, name_tc: 'In den Sälen:' | translate}]
  public dolbyIMS2000Screens?: string;

  public sonyR320ScreenServer?: boolean = false;
  public sonyR320ScreenServerOptions?: number; //[{id: 0, name_tc: 'Alle Säle' | translate}, {id: 1, name_tc: 'In den Sälen:' | translate}]
  public sonyR320Screens?: string;

  public sonyR515ScreenServer?: boolean = false;
  public sonyR515ScreenServerOptions?: number; //[{id: 0, name_tc: 'Alle Säle' | translate}, {id: 1, name_tc: 'In den Sälen:' | translate}]
  public sonyR515Screens?: string;

  public doremiScreenServer?: boolean = false;
  public doremiScreenServerOptions?: number; //[{id: 0, name_tc: 'Alle Säle' | translate}, {id: 1, name_tc: 'In den Sälen:' | translate}]
  public doremiScreens?: string;

  public necIMS2000ScreenServer?: boolean = false;
  public necIMS2000ScreenServerOptions?: number; //[{id: 0, name_tc: 'Alle Säle' | translate}, {id: 1, name_tc: 'In den Sälen:' | translate}]
  public necIMS2000Screens?: string;

  public barcoAlchemyScreenServer?: boolean = false;
  public barcoAlchemyScreenServerOptions?: number; //[{id: 0, name_tc: 'Alle Säle' | translate}, {id: 1, name_tc: 'In den Sälen:' | translate}]
  public barcoAlchemyScreens?: string;

  public gdcScreenServer?: boolean = false;
  public gdcScreenServerOptions?: number; //[{id: 0, name_tc: 'Alle Säle' | translate}, {id: 1, name_tc: 'In den Sälen:' | translate}]
  public gdcScreens?: string;

  public otherScreenServer?: boolean = false;
  public otherScreenServerOptions?: number; //[{id: 0, name_tc: 'Alle Säle' | translate}, {id: 1, name_tc: 'In den Sälen:' | translate}]
  public otherScreens?: string;

  // Saalbezogene Technik Audio System
  public surround_5_1_AudioSystem?: boolean;
  public surround_5_1_AudioSystemOptions?: number; //[{id: 0, name_tc: 'Alle Säle' | translate}, {id: 1, name_tc: 'In den Sälen:' | translate}]
  public surround_5_1_AudioSystemScreens?: string;

  public surround_7_1_AudioSystem?: boolean;
  public surround_7_1_AudioSystemOptions?: number; //[{id: 0, name_tc: 'Alle Säle' | translate}, {id: 1, name_tc: 'In den Sälen:' | translate}]
  public surround_7_1_AudioSystemScreens?: string;

  public dolbyAtmosAudioSystem?: boolean;
  public dolbyAtmosAudioSystemOptions?: number; //[{id: 0, name_tc: 'Alle Säle' | translate}, {id: 1, name_tc: 'In den Sälen:' | translate}]
  public dolbyAtmosAudioSystemScreens?: string;

  // Projektor Resolution

  public resolution2K?: boolean;
  public resolution2KOptions?: number; //[{id: 0, name_tc: 'Alle Säle' | translate}, {id: 1, name_tc: 'In den Sälen:' | translate}]
  public resolution2KScreens?: string;

  public resolution4K?: boolean;
  public resolution4KOptions?: number; //[{id: 0, name_tc: 'Alle Säle' | translate}, {id: 1, name_tc: 'In den Sälen:' | translate}]
  public resolution4KScreens?: string;

  // Projektor 3D-Fähigkeit
  public is3D?: boolean; //[{id: 0, name_tc: 'kein 3D' | translate}, {id: 1, name_tc: '3D in den Sälen:' | translate}]
  public screens3D?: string;

  // Projektor IMAX
  public isIMAX?: boolean; //[{id: 0, name_tc: 'kein 3D' | translate}, {id: 1, name_tc: '3D in den Sälen:' | translate}]
  public screensIMAX?: string;

  // Bildwechselfrequenz
  public frameRate24_30?: boolean;
  public frameRate24_30_Options?: number; //[{id: 0, name_tc: 'Alle Säle' | translate}, {id: 1, name_tc: 'In den Sälen:' | translate}]
  public frameRate24_30_Screens?: string;

  public frameRate48?: boolean;
  public frameRate48_Options?: number; //[{id: 0, name_tc: 'Alle Säle' | translate}, {id: 1, name_tc: 'In den Sälen:' | translate}]
  public frameRate48_Screens?: string;

  public frameRate60?: boolean;
  public frameRate60_Options?: number; //[{id: 0, name_tc: 'Alle Säle' | translate}, {id: 1, name_tc: 'In den Sälen:' | translate}]
  public frameRate60_Screens?: string;

  // Sonstige saalspezifische Installationen
  public otherScreenInstallations?: boolean;
  public otherScreenInstallationText?: boolean;

  // Besonderheiten
  public notes?: string;

  // M-BOX

  /// <summary>
  /// Lieferadresse für Hardware (Wie Kinoadresse, Sonstige Adresse   1 = Wie Kinoadresse, 0  = "Sonstige Adresse"
  /// </summary>

  public deliverySelectionMBOX?: number;

  /// <summary>
  /// Firmenname MBOX
  /// </summary>

  public companyNameMBOX?: string;

  /// <summary>
  /// AnsprechPartner MBOX
  /// </summary>

  public contactPartnerMBOX?: string;

  /// <summary>
  /// Lieferadresse Straße und Nr MBOX
  /// </summary>

  public deliveryAddressStreetMBOX?: string;

  /// <summary>
  /// Lieferadresse PLZ, Ort MBOX
  /// </summary>

  public deliveryAddressStreetCodeMBOX?: string;

  /// <summary>
  /// Lieferadresse Land MBOX
  /// </summary>

  public deliveryAddressCountryMBOX?: string;

  // Kassensystem M-Box

  /// <summary>
  /// Existiert eine API zu Ihrem Kassensystem? ja=1, nein=0
  /// </summary>

  public existingAPI?: number;

  /// <summary>
  /// Kassen-URL / Username
  /// </summary>

  public cashSystemUrl?: string;

  /// <summary>
  /// Passwort API
  /// </summary>

  public cashSystemPassword?: string;

  // Angaben zur Netzwerkgeschwindigkeit

  /// <summary>
  /// Download Geschwindigkeit (gemessen - MBit/s)
  /// </summary>

  public downloadSpeedResult?: number;

  /// <summary>
  /// Upload Geschwindigkeit(gemessen - MBit/s)
  /// </summary>

  public uploadSpeedResult?: number;

  /// <summary>
  /// Ping/Latenz (gemessen in ms (Millisekunde))
  /// </summary>

  public pingSpeedResult?: number;

  //Angaben zur internen Netzwerkstruktur

  /// <summary>
  /// Grundstruktur Netzwerk von Kino und Projektion (Wie Gemeinsames Netzwerk = 1, Getrenntes Netzwerk = 0
  /// </summary>

  public internalNetworkStructure?: number;

  /// <summary>
  /// Freie IP für M Server
  /// </summary>

  public ipMBoxServer?: string;

  /// <summary>
  /// Subnetmaske
  /// </summary>

  public subnetMask?: string;

  /// <summary>
  /// Default Gateway
  /// </summary>

  public defaultGateway?: string;

  /// <summary>
  /// DNS Server
  /// </summary>

  public dnsServer?: string;

  /// <summary>
  /// Verwenden Sie VLANs
  /// </summary>

  public vlanUsage?: number;

  /// <summary>
  /// Bitte beschreiben Sie ausführlich Ihre Netzwerk / VLAN-Topologie.
  /// </summary>

  public vlanTopo?: string;

  /// <summary>
  /// Verwenden Sie bereits eine VPN-Verbindung in Ihrem Kino?
  /// </summary>

  public vpnUsage?: number;

  /// <summary>
  /// Bitte beschreiben Sie Ihre VPN-Verbindung.
  /// </summary>

  public vpnDescription?: string;

  public showWeischerPlayM?: boolean;
}
