import { SitePolicyDialogComponent } from './../../../layouts/dialogs/components/site-policy-dialog/site-policy-dialog.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { SiteNoticeDialogComponent } from '../../../layouts/dialogs/components/site-notice-dialog/site-notice-dialog.component';
import { PrivacyPolicyDialogComponent } from '../../../layouts/dialogs/components/privacy-policy-dialog/privacy-policy-dialog.component';
import { ContactDialogComponent } from '../../../layouts/dialogs/components/contact-dialog/contact-dialog.component';

@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    console.log('PageFooterComponent');
  }

  onShowSiteNotice() {
    const dialogRef = this.dialog.open(SiteNoticeDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  onShowPrivacyPolicy() {
    const dialogRef = this.dialog.open(PrivacyPolicyDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  onShowContactNotice() {
    const dialogRef = this.dialog.open(ContactDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  onShowSitePolicy() {
    const dialogRef = this.dialog.open(SitePolicyDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}
