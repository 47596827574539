import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Directive, Input, OnInit, OnDestroy } from '@angular/core';
import { MatGridList } from '@angular/material';

// Usage: <mat-grid-list [responsiveCols]="{handset: 2, web: 2, tablet: 4, lg: 6, xl: 8}">
@Directive({
    selector: '[responsiveCols]'
})
export class ResponsiveColsDirective implements OnInit, OnDestroy {


    private countBySize: IResponsiveColumnsMap = { handset: 2, web: 4 };

    public get cols(): IResponsiveColumnsMap {
        return this.countBySize;
    }

    @Input('responsiveCols')
    public set cols(map: IResponsiveColumnsMap) {
        if (map && ('object' === (typeof map))) {
            this.countBySize = map;
        }
    }

    public constructor( private grid: MatGridList, private media: BreakpointObserver) {

    }

    ngOnInit(): void {
        //.observe(['(min-width: 500px)'])
        this.media.observe([
            Breakpoints.Handset,
            Breakpoints.Tablet
        ]).subscribe(result => {
            if (result.matches) {
                this.grid.cols = this.countBySize['handset'];
            } else {
                this.grid.cols = this.countBySize['web'];
            }
        });

    }

    ngOnDestroy(): void {
    }
}

export interface IResponsiveColumnsMap {
    handset?: number;
    web?: number;
    tablet?: number;
    handsetPortrait?: number;
    tabletPortrait?: number;
    webPortrait?: number;
    handsetLandscape?: number;
    tabletLandscape?: number;
    webLandscape?: number;
}

