import { ScreenServerDataModel } from "./screen-server-data-model";
import { ScreenServerModel } from "./screen-server-model";
import { AudioSystemModel } from "./audio-system-model";
import { ProjectorModel } from "./projector-model";

export class ScreenEquipmentModel {
  screenServers: ScreenServerModel[] = [];
  audioSystems: AudioSystemModel[] = [];
  projectors: ProjectorModel[] = [];
  screenIps: ScreenServerDataModel[] = [];
}
