import { Component, OnInit } from '@angular/core';
import { ApiUrlService } from '../../../../shared/services/api-url.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import notify from 'devextreme/ui/notify';
import { SpotlogRolesModel } from '../user-management-master-detail/user-management-master-detail.component';
import { SpotlogRole } from '../../../../models/enums/spotlog-role.enum';


@Component({
  selector: 'app-create-new-user',
  templateUrl: './create-new-user.component.html',
  styleUrls: ['./create-new-user.component.scss']
})
export class CreateNewUserComponent implements OnInit {

  public dataModel: any;

  buttonOptions: any = {
    text: this.translateService.instant('Benutzer erstellen'),
    type: "success",
    useSubmitBehavior: true
  }

  public roles: SpotlogRolesModel[] = [{ id: SpotlogRole.Administrator, name: SpotlogRole[SpotlogRole.Administrator] }
    , { id: SpotlogRole.CinemaRelations, name: SpotlogRole[SpotlogRole.CinemaRelations] },
  { id: SpotlogRole.CinemaStaff, name: SpotlogRole[SpotlogRole.CinemaStaff] }
    , { id: SpotlogRole.TMSsystem, name: SpotlogRole[SpotlogRole.TMSsystem] }];

  public selectedItems: SpotlogRolesModel[] = [];
  public allowDeleting: boolean = false;
  public deleteType: string = "toggle";

  passwordPattern: any = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,15}$/;

  constructor(public urlApiService: ApiUrlService, public httpClient: HttpClient, private translateService: TranslateService) { }

  ngOnInit() {
  }

  onFormSubmit(e) {
    if (this.selectedItems.length > 0) {
      let roleItems: number[] = [];
      for (let index = 0; index < this.selectedItems.length; index++) {
        const element = this.selectedItems[index].id;
        roleItems.push(element);
      }

      this.httpClient.post(this.urlApiService.getCreateNewUserUrl(), {
        username: this.dataModel.adUserLogin, password: this.dataModel.password,
        applicationRoles: roleItems
      }).subscribe(
        data => {
          notify({
            message: this.translateService.instant('Der Benutzer wurden erfolgreich angelegt. Danke!'),
            position: {
              my: "center top",
              at: "center top"
            }
          }, "success", 3000);
        },
        err => {
          notify({
            message: this.translateService.instant('Es ist ein Fehler aufgetreten!'),
            position: {
              my: "center top",
              at: "center top"
            }
          }, "error", 3000);
        }
      )
    } else {
      notify({
        message: this.translateService.instant('Bitte mindestens eine Rolle zuweisen. Danke!'),
        position: {
          my: "center top",
          at: "center top"
        }
      }, "error", 3000);
    }
  }

}
