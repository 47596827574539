import { environment } from '../../../environments/environment';
import { Aggregators } from '../../layouts/administration/tiles/aggregator-start/aggregators.enum';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiUrlService {
  constructor() {}

  public get authenticationUrl(): string {
    return environment.authenticationApiEndpoint + 'application' + '/' + 'token';
  }

  public startAggregator(aggregator: number): string {
    if (Aggregators.ContentCatalog === aggregator) {
      return this.getAggregatorApiUrl(aggregator) + 'Aggregation' + '/' + 'StartLongRun';
    } else {
      return this.getAggregatorApiUrl(aggregator) + 'Aggregation' + '/' + 'Start';
    }
  }

  public startAggregatorMisc(aggregator: number): string {
    return this.getAggregatorApiUrl(aggregator) + 'Aggregation' + '/' + 'Start/' + aggregator;
  }

  public lastSuccessfulRun(aggregator: number): string {
    return this.getAggregatorApiUrl(aggregator) + 'Aggregation' + '/' + 'GetLastSuccessfulRun';
  }

  public getLastRunExtendedStates(aggregator: number): string {
    return this.getAggregatorApiUrl(aggregator) + 'Aggregation' + '/' + 'GetLastRunExtendedStates';
  }

  public getLastRunExtendedStatesMisc(aggregator: number): string {
    return (
      this.getAggregatorApiUrl(aggregator) +
      'Aggregation' +
      '/' +
      'GetLastRunExtendedStates' +
      '/' +
      aggregator
    );
  }

  public getAggregationResults(aggregator: number, techLastRunsId: number): string {
    return (
      this.getAggregatorApiUrl(aggregator) +
      'Aggregation' +
      '/' +
      techLastRunsId +
      '/AggregationResults'
    );
  }

  public getEncryptedToken(): string {
    return environment.tokenEndpoint + 'Token' + '/' + 'Decrypt';
  }

  public getCinemas(): string {
    return this.concatUrl('Cinema', 'GetCinemas');
  }

  public getCinemaGroupsUrl(): string {
    return this.concatUrl('Cinema', 'GetCinemaGroups');
  }

  public getCompanyGroupsUrl(): string {
    return this.concatUrl('Cinema', 'GetCompanyGroups');
  }

  public getCineWeeks(): string {
    return this.concatUrl('CineWeeks', 'GetCineWeeks');
  }

  public getCineWeeks4Release(): string {
    return this.concatUrl('CineWeeks', 'GetCineWeeks4Release');
  }

  public getUpdateCineWeeks4Release(): string {
    return this.concatUrl('CineWeeks', 'UpdateCineWeeks4Release');
  }

  public getPlayoutInformation(): string {
    return this.concatUrl('PlayoutInformation', 'GetPlayoutInformations');
  }

  public getPlayouts(): string {
    return this.concatUrl('Playouts', 'GetPlayouts');
  }

  public getTmsRequestSimulator(): string {
    return this.concatUrl('PlayoutInformation', 'TmsRequestSimulator');
  }

  public getCreateReport(): string {
    return this.concatUrl('Report', 'CreateReport');
  }

  public getCreateMultipleReport(): string {
    return this.concatUrl('Report', 'CreateMultipleReport');
  }

  public getUnknownContent(): string {
    return this.concatUrl('Administration', 'GetUnknownContent');
  }

  public getLastCallFromTms(): string {
    return this.concatUrl('Administration', 'GetLastCallFromTms');
  }

  public getLastUserLogins(): string {
    return this.concatUrl('Administration', 'GetLastUserLogins');
  }

  public getBookingInformations(): string {
    return this.concatUrl('BookingInformation', 'GetBookingInformations');
  }

  public getPostResponses(): string {
    return this.concatUrl('PostResponse', 'GetPostResponses');
  }

  public getPostResponseDetails(): string {
    return this.concatUrl('PostResponse', 'GetPostResponseDetails');
  }

  public getShowLogUrl(): string {
    return this.concatUrl('PreviouslyPlayed', 'GetShowLog');
  }

  public getShows(): string {
    return this.concatUrl('Shows', 'GetShows');
  }

  public getUserInformation(): string {
    return this.concatUrl('UserInformation', 'CurrentUser');
  }

  public getLastAggregatorRun4Cinema(): string {
    return this.concatUrl('BookingInformation', 'GetLastAggregatorRun4Cinema');
  }

  public getPostResponseDetailsAsZip(): string {
    return this.concatUrl('PostResponse', 'GetPostResponseDetailsAsZip');
  }

  public getRecievedResponseDetailsAsZip(): string {
    return this.concatUrl('LastCallFromTms', 'GetRecievedResponseDetailsAsZip');
  }

  public getUpdateUnknownContent(): string {
    return this.concatUrl('Administration', 'UpdateUnknownContent');
  }

  public getVersionCheck(): string {
    return this.concatUrl('VersionCheck');
  }

  public getContents(): string {
    return this.concatUrl('ContentSearch', 'GetContents');
  }

  public getFeatureContents(): string {
    return this.concatUrl('ContentSearch', 'GetFeatureContents');
  }

  public getCinemaBookingData(): string {
    return this.concatUrl('ContentSearch', 'GetCinemaBookingData');
  }

  public getCinemaBookingFeatureData(): string {
    return this.concatUrl('ContentSearch', 'GetCinemaBookingFeatureData');
  }

  public getPortalPreviousPlays(): string {
    return this.concatUrl('PreviouslyPlayed', 'GetPortalPreviousPlays');
  }

  public getAllFutureMoviesData(): string {
    return this.concatUrl('ContentSearch', 'GetAllFutureMoviesData');
  }

  public getGenerateDownloadLinkUrl(): string {
    return this.concatUrl('Administration', 'GenerateDownloadLink');
  }

  public getBillingContactsUrl(): string {
    return this.concatUrl('BillingContacts', 'GetBillingContacts');
  }

  public getUserCinemas(): string {
    return this.concatUrl('Cinema', 'GetUserCinemas');
  }

  public getCinemaById(): string {
    return this.concatUrl('Cinema', 'GetCinemaById');
  }

  public getHistoricCineWeeks(): string {
    return this.concatUrl('PlayoutInformation', 'GetHistoricCineWeeks');
  }

  public getCreateHistoricDownloadPDF(): string {
    return this.concatUrl('PlayoutInformation', 'CreateHistoricDownloadPDF');
  }

  public getLastAggregatorRuns(): string {
    return this.concatUrl('DataAggregationRuns', 'GetLastAggregatorRuns');
  }

  public getRunResults(): string {
    return this.concatUrl('DataAggregationRuns', 'GetRunResults');
  }

  public getScreenHierarchyForCinema(): string {
    return this.concatUrl('Cinema', 'GetScreenHierarchyForCinema');
  }

  public getCenterShows(): string {
    return this.concatUrl('Shows', 'GetCenterShows');
  }

  public getScreenShows(): string {
    return this.concatUrl('Shows', 'GetScreenShows');
  }

  public getCinemaPlayoutInformationsRequest(): string {
    return this.concatUrl('PlayoutInformation', 'GetCinemaPlayoutInformations');
  }

  public getScreenPlayoutInformationsRequest(): string {
    return this.concatUrl('PlayoutInformation', 'GetScreenPlayoutInformations');
  }

  public getPostResponseClientInfo(): string {
    return this.concatUrl('PostResponse', 'GetPostResponseClientInfo');
  }

  public getCinemaRegistrationUrl(): string {
    return this.concatUrl('CinemaRegistration', 'Submit');
  }

  public getSpotlogUsersUrl(): string {
    return this.concatUrl('UserInformation', 'GetSpotlogUsers');
  }

  public getSpotlogUserInformationById(): string {
    return this.concatUrl('UserInformation', 'GetSpotlogUserInformationById');
  }

  public getAssignUserToRolesUrl(): string {
    return this.concatUrl('UserInformation', 'AssignUserToRoles');
  }

  public getChangeUserPasswordUrl(): string {
    return this.concatUrl('UserInformation', 'ChangeUserPassword');
  }

  public getCreateNewUserUrl(): string {
    return this.concatUrl('UserInformation', 'CreateNewUser');
  }

  public getCinemaRegistrationsUrl(): string {
    return this.concatUrl('CinemaRegistration', 'GetCinemaRegistrations');
  }

  public getCinemaRegistrationById(): string {
    return this.concatUrl('CinemaRegistration', 'GetCinemaRegistrationById');
  }

  public getEnableUserUrl(): string {
    return this.concatUrl('UserInformation', 'EnableUser');
  }

  public getDisableUserUrl(): string {
    return this.concatUrl('UserInformation', 'DisableUser');
  }

  public getChangeStatusUrl(): string {
    return this.concatUrl('CinemaRegistration', 'ChangeStatus');
  }

  public getAssignCinemaUrl(): string {
    return this.concatUrl('CinemaRegistration', 'AssignCinema');
  }

  public getUpdateCinemaUrl(): string {
    return this.concatUrl('CinemaRegistration', 'UpdateCinemaRegistration');
  }

  public getCinemaRegistrationsByTechCinemaIdsUrl(): string {
    return this.concatUrl('CinemaRegistration', 'GetCinemaRegistrationsByTechCinemaIds');
  }

  public getRequestRulesGetAllUrl(): string {
    return this.concatUrl('RequestRules');
  }

  public getRequestRulesInsertRequestRuleUrl(): string {
    return this.concatUrl('RequestRules', 'InsertRequestRule');
  }

  public getUpdateRequestRuleUrl(): string {
    return this.concatUrl('RequestRules');
  }

  public getDeleteRequestRuleUrl(): string {
    return this.concatUrl('RequestRules', 'DeleteRequestRule');
  }

  public getByRequestRuleDetailIdUrl(): string {
    return this.concatUrl('RequestRuleDetail', 'GetByRequestRuleId');
  }

  public getInsertRequestRuleDetailUrl(): string {
    return this.concatUrl('RequestRuleDetail', 'InsertRequestRuleDetail');
  }

  public getUpdateRequestRuleDetailUrl(): string {
    return this.concatUrl('RequestRuleDetail');
  }

  public getDeleteRequestRuleDetailUrl(): string {
    return this.concatUrl('RequestRuleDetail', 'DeleteRequestRuleDetail');
  }

  public getPhoneCodesUrl(): string {
    return this.concatUrl('PhoneCode');
  }

  private concatUrl(...paths: string[]): string {
    return environment.apiEndpoint + paths.join('/');
  }

  private getAggregatorApiUrl(aggregator: number): string {
    let apiUrl = '';
    switch (aggregator) {
      case Aggregators.BookingData:
        apiUrl = environment.bookingDataAggregatorEndpoint;
        break;
      case Aggregators.CinemaMasterData:
        apiUrl = environment.cinemaMasterAggregatorEndpoint;
        break;
      case Aggregators.ContentCatalog:
        apiUrl = environment.contentCatalogAggregatorEndpoint;
        break;
      case Aggregators.User:
        apiUrl = environment.userAggregatorEndpoint;
        break;
      case Aggregators.DataAggregatorBillingContacts:
        apiUrl = environment.miscellaneousDataAggregatorEndpoint;
        break;
      default:
        apiUrl = '';
        break;
    }
    return apiUrl;
  }
}
