import { Injectable } from "@angular/core";
import * as moment from "moment";
import { LocalStorageKeyHelper } from "../../helpers/local-storage-key-helper";
import * as jwt from "jsonwebtoken";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  constructor(public router: Router) {}

  logout() {
    localStorage.removeItem(LocalStorageKeyHelper.accessToken);
    localStorage.removeItem(LocalStorageKeyHelper.expiresAt);
    localStorage.removeItem(LocalStorageKeyHelper.permissionToken);

    this.router.navigate(["/login"]);
  }

  logoutToMaintenance() {
    localStorage.removeItem(LocalStorageKeyHelper.accessToken);
    localStorage.removeItem(LocalStorageKeyHelper.expiresAt);
    localStorage.removeItem(LocalStorageKeyHelper.permissionToken);
    this.router.navigate(["/maintenance"]);
  }

  public isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }

  getExpiration() {
    const expiration = localStorage.getItem(LocalStorageKeyHelper.expiresAt);
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  public storeExpirationDate(expiresAt: string) {
    localStorage.setItem(LocalStorageKeyHelper.expiresAt, expiresAt);
  }

  public storeToken(token: string) {
    try {
      // no need  to store the result, we just need this
      // to check if the token is valid (else the method would throw an error)
      jwt.decode(token);
      // AuthHttp makes guse of what is stored in the storage field
      // so we set it here to be able to make use of AuthHttp
      localStorage.setItem(LocalStorageKeyHelper.accessToken, token);
    } catch (e) {
      console.error("could not set token", e);
    }
  }
}
