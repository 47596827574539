
import {tap} from 'rxjs/operators';
import { AuthService } from '../../shared/services/auth.service';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { RequestErrorHandler } from '../error-handler';
import { Router } from '@angular/router';



export class RequestErrorInterceptor implements HttpInterceptor {


    constructor(private requestErrorHandler: RequestErrorHandler, private authService: AuthService) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(tap((event: HttpEvent<any>) => { }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    this.authService.logout();
                }
                console.log(err);
                this.requestErrorHandler.handleError(err);

            }
        }));
    }


}
