export enum TmsCinemaSystem {
    'Arts Alliance' = 0,
    'DOLBY' = 1,
    'EIKONA' = 2,
    'SONY' = 3,
    'UNIQUE' = 4,
    'ymagis' = 5,
    'Kinoton' = 6,
    'Sonstige' = 7,
    'Das Kino verwendet kein TMS' = 8,
    'Weischer M-Box' = 9
}