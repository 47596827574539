import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingSpinnerService {

  private spinnerSubject = new Subject<SpinnerState>();

  spinnerState = this.spinnerSubject.asObservable();

  constructor( @Optional() @SkipSelf() prior: LoadingSpinnerService) {
      if (prior) { return prior; }
  }

  show() {
      this.spinnerSubject.next(<SpinnerState>{ show: true });
  }

  hide() {
      this.spinnerSubject.next(<SpinnerState>{ show: false });
  }
}

export interface SpinnerState {
  show: boolean;
}


