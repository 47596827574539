import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { PrivacyPolicyDialogComponent } from '../privacy-policy-dialog/privacy-policy-dialog.component';

@Component({
  selector: 'app-site-notice-dialog',
  templateUrl: './site-notice-dialog.component.html',
  styleUrls: ['./site-notice-dialog.component.scss']
})
export class SiteNoticeDialogComponent implements OnInit {

  currentLanguage = 'de';

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<SiteNoticeDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private translateService: TranslateService) {

  }

  ngOnInit() {
    if (_.isUndefined(this.translateService.currentLang)) {
      const defaultLanguage = this.translateService.getDefaultLang();
      if (defaultLanguage) {
        this.currentLanguage = this.translateService.getDefaultLang();
      } else {
        this.currentLanguage = 'de';
      }
    } else {
      this.currentLanguage = this.translateService.currentLang;
    }
  }

  onOkClick() {
    this.dialogRef.close(`${true}`);
  }

  onNoClick(): void {
    this.dialogRef.close(`${false}`);
  }

  onShowPrivacyPolicy() {
    this.dialog.open(PrivacyPolicyDialogComponent);
  }


}
