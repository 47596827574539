import { CinemaSurveySelectionDialogComponent } from "./components/cinema-survey-selection-dialog/cinema-survey-selection-dialog.component";
import { CalculateNextCallRequestRuleCreateDialogComponent } from "./components/calculate-next-call-request-rule-create-dialog/calculate-next-call-request-rule-create-dialog.component";
import { CinemaTechSurveyReadOnlyDialogComponent } from "./components/cinema-tech-survey-read-only-dialog/cinema-tech-survey-read-only-dialog.component";
import { CreateNewUserComponent } from "./components/create-new-user/create-new-user.component";
import { UserManagementMasterDetailComponent } from "./components/user-management-master-detail/user-management-master-detail.component";
import { AdminCinemaSurveyDialogComponent } from "./components/admin-cinema-survey-dialog/admin-cinema-survey-dialog.component";
import { CinemaSurveyDialogComponent } from "./components/cinema-survey-dialog/cinema-survey-dialog.component";
import { ScreenLatestMoviesTabComponent } from "./components/cinema-playout-screen-details-dialog/tabs/screen-latest-movies-tab/screen-latest-movies-tab.component";
import { AggregatorRunResultsDetailsDialogComponent } from "./components/aggregator-run-results-details-dialog/aggregator-run-results-details-dialog.component";
import { ScreenPlayoutEngineeringTabComponent } from "./components/cinema-playout-screen-details-dialog/tabs/screen-playout-engineering-tab/screen-playout-engineering-tab.component";
import { LatestMoviesTabComponent } from "./components/cinema-playout-details-dialog/tabs/latest-movies-tab/latest-movies-tab.component";
import { PlayoutEngineeringTabComponent } from "./components/cinema-playout-details-dialog/tabs/playout-engineering-tab/playout-engineering-tab.component";
import { ExtendedInformationTabComponent } from "./components/cinema-playout-details-dialog/tabs/extended-information-tab/extended-information-tab.component";
import { CinemaPlayoutScreenDetailsDialogComponent } from "./components/cinema-playout-screen-details-dialog/cinema-playout-screen-details-dialog.component";
import { PlayoutDownloadArchiveDialogComponent } from "./components/playout-download-archive-dialog/playout-download-archive-dialog.component";
import { GeneralInfoTabComponent } from "./components/cinema-playout-details-dialog/tabs/general-info-tab/general-info-tab.component";
import { CinemaPlayoutDetailsDialogComponent } from "./components/cinema-playout-details-dialog/cinema-playout-details-dialog.component";
import { DownloadLinkGeneratorDialogComponent } from "./components/download-link-generator-dialog/download-link-generator-dialog.component";
import { CinemaBookingFeatureDialogComponent } from "./components/cinema-booking-feature-dialog/cinema-booking-feature-dialog.component";
import { CinemaBookingDialogComponent } from "./components/cinema-booking-dialog/cinema-booking-dialog.component";
import { NgModule } from "@angular/core";
import { MatButtonModule, MatDialogModule } from "@angular/material";
import {
  DxBoxModule,
  DxButtonModule,
  DxFormModule,
  DxTreeListModule,
  DxDateBoxModule,
  DxTextBoxModule,
  DxTextAreaModule,
  DxSelectBoxModule,
  DxTabsModule,
  DxCheckBoxModule,
  DxRadioGroupModule,
  DxAutocompleteModule,
  DxNumberBoxModule,
  DxTooltipModule,
  DxListModule,
  DxDataGridModule,
  DxLookupModule,
  DxValidatorModule,
  DxResponsiveBoxModule
} from "devextreme-angular";
import { NgxJsonViewerModule } from "ngx-json-viewer";
import { SharedModule } from "../../shared/shared.module";
import { ApprovalDialogComponent } from "./components/approval-dialog/approval-dialog.component";
import { ContactDialogComponent } from "./components/contact-dialog/contact-dialog.component";
import { DialogTriggerComponent } from "./components/dialog-trigger/dialog-trigger.component";
import { JsonDialogComponent } from "./components/json-dialog/json-dialog.component";
import { PostResponseDetailsDialogComponent } from "./components/post-response-details-dialog/post-response-details-dialog.component";
import { PrivacyPolicyDialogComponent } from "./components/privacy-policy-dialog/privacy-policy-dialog.component";
import { SiteNoticeDialogComponent } from "./components/site-notice-dialog/site-notice-dialog.component";
import { SitePolicyDialogComponent } from "./components/site-policy-dialog/site-policy-dialog.component";
import { VideoDialogComponent } from "./components/video-dialog/video-dialog.component";
import { StandardYesNoDialogComponent } from "./components/standard-yes-no-dialog/standard-yes-no-dialog.component";
import { WidgetsModule } from "../../widgets/widgets.module";
import { FormsModule } from "@angular/forms";
import { ClipboardModule } from "ngx-clipboard";
import { CinemaAudienceTabComponent } from "./components/cinema-playout-details-dialog/tabs/cinema-audience-tab/cinema-audience-tab.component";
import { CalculateNextCallRequestRuleDetailsDialogComponent } from "./components/calculate-next-call-request-rule-details-dialog/calculate-next-call-request-rule-details-dialog.component";

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    MatDialogModule,
    NgxJsonViewerModule,
    MatButtonModule,
    DxButtonModule,
    DxFormModule,
    DxBoxModule,
    DxTreeListModule,
    WidgetsModule,
    DxDateBoxModule,
    DxTextBoxModule,
    DxButtonModule,
    DxTextAreaModule,
    DxSelectBoxModule,
    ClipboardModule,
    DxTabsModule,
    DxCheckBoxModule,
    DxRadioGroupModule,
    DxNumberBoxModule,
    DxButtonModule,
    DxAutocompleteModule,
    DxTooltipModule,
    DxListModule,
    DxDataGridModule,
    DxLookupModule,
    DxValidatorModule
  ],
  declarations: [
    DialogTriggerComponent,
    VideoDialogComponent,
    JsonDialogComponent,
    SitePolicyDialogComponent,
    PostResponseDetailsDialogComponent,
    SiteNoticeDialogComponent,
    PrivacyPolicyDialogComponent,
    ApprovalDialogComponent,
    ContactDialogComponent,
    StandardYesNoDialogComponent,
    CinemaBookingDialogComponent,
    CinemaBookingFeatureDialogComponent,
    DownloadLinkGeneratorDialogComponent,
    CinemaPlayoutDetailsDialogComponent,
    GeneralInfoTabComponent,
    CinemaAudienceTabComponent,
    PlayoutDownloadArchiveDialogComponent,
    CinemaPlayoutScreenDetailsDialogComponent,
    ExtendedInformationTabComponent,
    PlayoutEngineeringTabComponent,
    LatestMoviesTabComponent,
    ScreenPlayoutEngineeringTabComponent,
    AggregatorRunResultsDetailsDialogComponent,
    ScreenLatestMoviesTabComponent,
    CinemaSurveyDialogComponent,
    UserManagementMasterDetailComponent,
    CreateNewUserComponent,
    AdminCinemaSurveyDialogComponent,
    CinemaTechSurveyReadOnlyDialogComponent,
    CalculateNextCallRequestRuleCreateDialogComponent,
    CalculateNextCallRequestRuleDetailsDialogComponent,
    CinemaSurveySelectionDialogComponent
  ],
  entryComponents: [
    VideoDialogComponent,
    JsonDialogComponent,
    SitePolicyDialogComponent,
    PostResponseDetailsDialogComponent,
    SiteNoticeDialogComponent,
    PrivacyPolicyDialogComponent,
    ApprovalDialogComponent,
    ContactDialogComponent,
    StandardYesNoDialogComponent,
    CinemaBookingDialogComponent,
    CinemaBookingFeatureDialogComponent,
    DownloadLinkGeneratorDialogComponent,
    CinemaPlayoutDetailsDialogComponent,
    PlayoutDownloadArchiveDialogComponent,
    CinemaPlayoutScreenDetailsDialogComponent,
    ExtendedInformationTabComponent,
    PlayoutEngineeringTabComponent,
    LatestMoviesTabComponent,
    ScreenPlayoutEngineeringTabComponent,
    AggregatorRunResultsDetailsDialogComponent,
    ScreenLatestMoviesTabComponent,
    CinemaSurveyDialogComponent,
    UserManagementMasterDetailComponent,
    CreateNewUserComponent,
    AdminCinemaSurveyDialogComponent,
    CinemaTechSurveyReadOnlyDialogComponent,
    CalculateNextCallRequestRuleCreateDialogComponent,
    CalculateNextCallRequestRuleDetailsDialogComponent,
    CinemaSurveySelectionDialogComponent
  ],
  exports: [
    DialogTriggerComponent,
    VideoDialogComponent,
    JsonDialogComponent,
    SitePolicyDialogComponent,
    PostResponseDetailsDialogComponent,
    SiteNoticeDialogComponent,
    PrivacyPolicyDialogComponent,
    ApprovalDialogComponent,
    ContactDialogComponent,
    StandardYesNoDialogComponent,
    CinemaBookingDialogComponent,
    CinemaBookingFeatureDialogComponent,
    DownloadLinkGeneratorDialogComponent,
    CinemaPlayoutDetailsDialogComponent,
    PlayoutDownloadArchiveDialogComponent,
    CinemaPlayoutScreenDetailsDialogComponent,
    ExtendedInformationTabComponent,
    PlayoutEngineeringTabComponent,
    LatestMoviesTabComponent,
    ScreenPlayoutEngineeringTabComponent,
    AggregatorRunResultsDetailsDialogComponent,
    ScreenLatestMoviesTabComponent,
    CinemaSurveyDialogComponent,
    UserManagementMasterDetailComponent,
    AdminCinemaSurveyDialogComponent,
    CreateNewUserComponent,
    CinemaTechSurveyReadOnlyDialogComponent,
    CalculateNextCallRequestRuleCreateDialogComponent,
    CalculateNextCallRequestRuleDetailsDialogComponent,
    CinemaSurveySelectionDialogComponent
  ]
})
export class DialogsModule {}
