import { NotificationModel } from './../../models/notification-model';
import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  @Output() notificationReceived: EventEmitter<NotificationModel> = new EventEmitter();

  constructor() { }

  sendNotification(notification: NotificationModel) {
    this.notificationReceived.emit(notification);
  }

}
