import { startWith } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription, interval } from "rxjs";
import { ApiUrlService } from "./api-url.service";
import { AuthService } from "./auth.service";
import { MatDialog } from "@angular/material";

@Injectable({
  providedIn: "root"
})
export class MaintenanceService implements OnInit, OnDestroy {
  private pingInterval = 30000;
  subscription: Subscription;

  constructor(
    private http: HttpClient,
    private urlService: ApiUrlService,
    private router: Router,
    private authService: AuthService,
    private dialogRef: MatDialog
  ) {
    this.subscription = interval(this.pingInterval)
      .pipe(startWith(0))
      .subscribe(() => {
        this.http.get(this.urlService.getVersionCheck()).subscribe(result => {
          const currentUrl = this.router.url;
          if (result === 1) {
            this.authService.logoutToMaintenance();
          } else if (result === 0 && currentUrl === "/maintenance") {
            this.dialogRef.closeAll();
            this.router.navigate(["/login"]).then(x => {
              location.reload(true);
            });
          }
        });
      });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
