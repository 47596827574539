import { AuthService } from "./auth.service";
import { SpotlogRole } from "../../models/enums/spotlog-role.enum";
import { PermissionCinemasModel } from "../../models/permission-cinemas-model";
import { Injectable, OnInit } from "@angular/core";
import { LocalStorageKeyHelper } from "../../helpers/local-storage-key-helper";
import * as jwt from "jsonwebtoken";
import { SpotlogUserModel } from "../../models/spotlog-user-model";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { ApiUrlService } from "./api-url.service";

@Injectable({
  providedIn: "root"
})
export class PermissionsService {
  private currentUserSubject = new BehaviorSubject<any>({});
  public currentUser = this.currentUserSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private _spotlogUser: SpotlogUserModel;
  public readonly rolesPermittedToAccessActions: SpotlogRole[] = [
    SpotlogRole.Administrator
  ];

  public set spotlogUser(spotlogUser: SpotlogUserModel) {
    this.currentUserSubject.next(spotlogUser);
    this._spotlogUser = spotlogUser;
  }

  public get spotlogUser() {
    return this._spotlogUser;
  }

  public get isPermittedToAccessBookingList() {
    const value = localStorage.getItem(LocalStorageKeyHelper.allowBookingList);
    if (value === "true") {
      return true;
    }
    return false;
  }

  public get isPermittedToAccessPlayouts() {
    const value = localStorage.getItem(LocalStorageKeyHelper.allowPlayout);
    if (value === "true") {
      return true;
    }
    return false;
  }

  public set isPermittedToAccessBookingList(value: boolean) {
    localStorage.setItem(
      LocalStorageKeyHelper.allowBookingList,
      value ? "true" : "false"
    );
  }

  public set isPermittedToAccessPlayouts(value: boolean) {
    localStorage.setItem(
      LocalStorageKeyHelper.allowPlayout,
      value ? "true" : "false"
    );
  }

  public get isPermittedToAccessActions(): boolean {
    const roles = this.getRoles();
    if (!roles) {
      return false;
    }

    return this.isSubsetOf(this.rolesPermittedToAccessActions, roles);
  }

  public get hasCinemaRelationsRole(): boolean {
    const roles = this.getRoles();
    if (!roles) {
      return false;
    }

    return this.isSubsetOf([SpotlogRole.CinemaRelations], roles);
  }

  public get hasCinemaStaffRole(): boolean {
    const roles = this.getRoles();
    if (!roles) {
      return false;
    }

    return this.isSubsetOf([SpotlogRole.CinemaStaff], roles);
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    public httpClient: HttpClient,
    private apiUrlService: ApiUrlService
  ) {
    if (this.getCurrentPermissionTokenDecoded()) {
      this.httpClient
        .get(this.apiUrlService.getUserInformation())
        .subscribe(user => {
          const spotlogUser: SpotlogUserModel = user as SpotlogUserModel;
          this.spotlogUser = spotlogUser;
        });
    }
  }

  public getRoles(): SpotlogRole[] {
    const token = this.getCurrentPermissionTokenDecoded();
    if (!token) {
      return null;
    }
    const roles: SpotlogRole[] = [];

    const splittedRoles = token.ApplicationRoles.split(";");

    for (let index = 0; index < splittedRoles.length; index++) {
      const element = splittedRoles[index];
      switch (element) {
        case "0":
          roles.push(SpotlogRole.Unknown);
          break;
        case "1":
          roles.push(SpotlogRole.TMSsystem);
          break;
        case "2":
          roles.push(SpotlogRole.CinemaStaff);
          break;
        case "8":
          roles.push(SpotlogRole.CinemaRelations);
          break;
        case "9":
          roles.push(SpotlogRole.Administrator);
          break;
        default:
          roles.push(SpotlogRole.Unknown);
          break;
      }
    }
    return roles;
  }

  public getUserName() {
    const token = this.getCurrentPermissionTokenDecoded();
    if (!token) {
      return null;
    }

    return token.name;
  }

  public getCinemaList() {
    const token = this.getCurrentPermissionTokenDecoded();
    if (!token) {
      return null;
    }

    return JSON.parse(token.CinemaList);
  }

  public getCinemaPermissionsList(): PermissionCinemasModel[] {
    const cinemaList = this.getCinemaList();
    if (!cinemaList) {
      return null;
    }
    return cinemaList.Permission4Cinemas as PermissionCinemasModel[];
  }

  public getCinemaIds(): number[] {
    const techCinemaIds: number[] = [];
    for (
      let index = 0;
      index < this.getCinemaPermissionsList().length;
      index++
    ) {
      const element = this.getCinemaPermissionsList()[index];
      techCinemaIds.push(element.TechCinemaId);
    }
    return techCinemaIds;
  }

  public getSpotlogUserId() {
    const cinemaList = this.getCinemaList();
    if (!cinemaList) {
      return null;
    }
    return cinemaList.TechSpotlogUserId;
  }

  public storePermissionToken(token: any) {
    try {
      // no need  to store the result, we just need this
      // to check if the token is valid (else the method would throw an error)
      jwt.decode(token);
      // AuthHttp makes guse of what is stored in the storage field
      // so we set it here to be able to make use of AuthHttp
      localStorage.setItem(LocalStorageKeyHelper.permissionToken, token);
    } catch (e) {
      console.error("could not set token", e);
    }
  }

  public getCurrentPermissionToken(): string {
    return localStorage.getItem(LocalStorageKeyHelper.permissionToken);
  }

  public getCurrentPermissionTokenDecoded(logError = true): any {
    try {
      return jwt.decode(this.getCurrentPermissionToken());
    } catch (e) {
      if (logError) {
        console.log(
          "an error occured while trying to decode the current token. probably there is no token yet",
          e
        );
      }

      return null;
    }
  }

  private isSubsetOf(needles: SpotlogRole[], haystack: SpotlogRole[]): boolean {
    return needles.some(needle => haystack.indexOf(needle) >= 0);
  }

  public checkPermission(permissions: string[]) {
    let hasPermission = false;

    if (
      this._spotlogUser &&
      this._spotlogUser.portalPermissions &&
      this._spotlogUser.portalPermissions.length > 0
    ) {
      for (const checkPermission of permissions) {
        const permissionFound = this._spotlogUser.portalPermissions.find(
          x => x.toUpperCase() === checkPermission.toUpperCase()
        );
        if (permissionFound) {
          hasPermission = true;
        }
      }
    }

    return hasPermission;
  }
}
