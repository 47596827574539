import { MaintenanceService } from './shared/services/maintenance.service';
import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private maintenanceService: MaintenanceService
  ) {
    iconRegistry.addSvgIcon(
      'logo',
      sanitizer.bypassSecurityTrustResourceUrl('../../assets/images/WeischerPlay_Logo.svg')
    );
    iconRegistry.addSvgIcon(
      'weischerDownloadLogo',
      sanitizer.bypassSecurityTrustResourceUrl('../../assets/images/WeischerDownload_Logo.svg')
    );
    //const notificationHub =  new NotificationHub('notification');
  }
}
