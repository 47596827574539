export class HistoricDownloadPdfRequestModel {
    /// <summary>
    /// Technischer Schlüssel des Kinos, für welche historische Kinowochen ermittelt werden sollen
    /// </summary>
    techCinemaId: number;
    /// <summary>
    /// Datum für das historische PDF erstellt werden sollen
    /// </summary>
    lastDayCineWeek?: string;

    /// <summary>
    /// Texte für Positionplayout
    /// </summary>
    textPositionPlayout: { [x: number]: string } = {};

    /// <summary>
    /// Texte für Blöcke
    /// </summary>
    textBlock: { [x: number]: string } = {};

    /// <summary>
    /// Texte für zeit (ganztags, 18 Uhr, ...)
    /// </summary>
    textZeitschiene: { [x: number]: string } = {};

    /// <summary>
    /// Texte für Saal oder Film
    /// </summary>
    textSaalFilm: { [x: string]: string } = {};

    /// <summary>
    /// Texte für Stumm
    /// </summary>
    textMute: { [x: string]: string } = {};

    /// <summary>
    /// Sonstige Texte wie Spaltenüberschriften, "Einschaltanweisung", ...
    /// </summary>
    textOther: { [x: string]: string } = {};
}

