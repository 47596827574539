import { Component, OnInit, Input } from '@angular/core';
import { ApiUrlService } from '../../../../../../shared/services/api-url.service';
import { HttpClient } from '@angular/common/http';
import { CinemaModel } from '../../../../../../models/cinema-model';
import * as moment from 'moment';
import { PostResponseRequestModel } from '../../../../../../models/post-response-request-model';
import { ClientInfo } from '../../../../../../models/client-info';


@Component({
  selector: 'app-playout-engineering-tab',
  templateUrl: './playout-engineering-tab.component.html',
  styleUrls: ['./playout-engineering-tab.component.scss']
})
export class PlayoutEngineeringTabComponent implements OnInit {

  @Input()
  set techCinemaId(techCinemaId: number) {
    this._techCinemaId = techCinemaId;
  }

  get techCinemaId() {
    return this._techCinemaId;
  }

  private _techCinemaId: number;

  cinemaModel: CinemaModel;
  clientInfo: ClientInfo;

  constructor(public urlApiService: ApiUrlService, public httpClient: HttpClient) { }

  ngOnInit() {
    this.requestData();
  }

  requestData() {
    this.httpClient.post(this.urlApiService.getCinemaById(), this.techCinemaId).subscribe(
      response => {
        this.cinemaModel = response as CinemaModel;
      },
      err => {
        console.log('Error occured.');
      });

      const fromDate = moment('2018-12-25').format('YYYY-MM-DD HH:mm:ss.000');
      const toDate = moment().format('YYYY-MM-DD HH:mm:ss.000');
  
      const request: PostResponseRequestModel = new PostResponseRequestModel();
      request.fromDate = fromDate;
      request.toDate = toDate;
      request.techCinemaId = this.techCinemaId;
  
      this.httpClient.post(this.urlApiService.getPostResponseClientInfo(), request).subscribe(
        data => {
          this.clientInfo = data as ClientInfo;
        },
        err => {
          console.log('Error occured.');
        }
      );
  }

}
