import { LocalStorageKeyHelper } from '../../helpers/local-storage-key-helper';
// tslint:disable-next-line:max-line-length
import { HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrlService } from '../../shared/services/api-url.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private urlService: ApiUrlService) { }


    // tslint:disable-next-line:max-line-length
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        if (req.url === this.urlService.getVersionCheck()) {
            return next.handle(req);
        }

        const accessToken = localStorage.getItem(LocalStorageKeyHelper.accessToken);

        if (accessToken) {
            const cloned = req.clone({
                headers: req.headers.set('Authorization',
                    'Bearer ' + accessToken)
            });

            return next.handle(cloned);
        } else {
            return next.handle(req);
        }
    }
}
