export class ScreenModel {
    public techScreenId: number;
    public theaterNr: string;
    public name1: string;
    public name2: string;
    public active: boolean;
    public allowPlayout: boolean;
    public allowBookinglist: boolean;
    public originTheaterNr: string;
}
