export enum TranslationPositionPlayout {
  'Einlass Block: Opener' = 990,
  'Einlass Block: Kompensation' = 980,
  'Einlass Block: Komp. Teil 1' = 975,
  'Einlass Block: Komp. Teil 2' = 974,
  'Einlass Block: Komp. Teil 3' = 973,
  'Einlass Block: Werbefilm' = 950,
  'Einlass Block: Teil 1' = 935,
  'Einlass Block: Teil 2' = 934,
  'Einlass Block: Teil 3' = 933,
  'Einlass Block: Closer' = 910,
  'Lokal: Opener stumm' = 895,
  'Lokal: CC stumm (kitag)' = 890,
  'Lokal: Kino stumm' = 880,
  'Lokal WW stumm' = 870,
  'Lokal: Closer stumm' = 865,
  'Event stumm' = 863,
  'Lokal: Opener tönend' = 860,
  'Lokal: CC tönend (kitag)' = 850,
  'Lokal: Kino tönend' = 840,
  'Lokal: WW tönend' = 830,
  'Lokal: Closer tönend' = 825,
  'WW Trailer saalbezogen' = 795,
  'WW Trailer filmbezogen' = 790,
  'Opener Werbung I' = 785,
  'Starter Werbeprogramm' = 784,
  'WW Eigenwerbung' = 780,
  'Werbefilm' = 778,
  'Regionalwerbung' = 775,
  'Kompensation' = 770,
  'Kompensation Teil 1' = 765,
  'Kompensation Teil 2' = 764,
  'Kompensation Teil 3' = 763,
  'Lokaler Block: Werbefilm' = 750,
  'Lokaler Block: Teil 1' = 725,
  'Lokaler Block: Teil 2' = 724,
  'Lokaler Block: Teil 3' = 723,
  'Closer Werbung I' = 710,
  'Filmtrailer Pre-Presenter I' = 706,
  'Filmtrailer Presenter I' = 705,
  'Opener Werbung II' = 595,
  'Nationaler Block: Opener' = 590,
  'Kompensatione' = 580,
  'Kompensation Teil1' = 575,
  'Kompensation Teil2' = 574,
  'Kompensation Teil3' = 573,
  'National' = 550,
  'National Teil 1' = 535,
  'National Teil 2' = 534,
  'National Teil 3' = 533,
  'Nationaler Block: Closer' = 520,
  'Closer Werbung II' = 510,
  'Event tönend' = 503,
  'Eis 1' = 505,
  'Eis 1 (Allonge Kinopolis)' = 504,
  'Filmtrailer Pre-Presenter II' = 406,
  'Filmtrailer Presenter II' = 405,
  'Eis 2' = 402,
  '3D Brille auf!' = 395,
  '3D Opener Werbung III' = 390,
  '3D Block: Opener' = 380,
  '3D Kompensation' = 370,
  'KompensationTeil 1' = 365,
  'KompensationTeil 2' = 364,
  'KompensationTeil 3' = 363,
  '3D National' = 350,
  '3D National Teil 1' = 335,
  '3D National Teil 2' = 334,
  '3D National Teil 3' = 333,
  '3D Block: Closer' = 320,
  '3D Closer' = 310,
  '3D Eis 3' = 307,
  '3D Filmtrailer Pre-Presenter' = 306,
  '3D Filmtrailer Presenter ' = 305,
  '3D Eis 4' = 240,
  'Premium -1' = 232,
  'Premium' = 231,
  'Presenter' = 230,
  '3D Premium -1' = 222,
  '3D Premium' = 221,
  '3D Presenter' = 220,
  'Saal-Presenter CxX' = 216,
  '3D Saal-Presenter CxX' = 215,
  'Kinobetreiber Logo' = 211,
  '3D Kinobetreiber Logo' = 210,
  'Pause01_Intro' = 190,
  'Pause02_Presenter_Intro' = 180,
  'Pause03_Werbeblock_1' = 170,
  'Pause04_Unterhaltung_mit_Ton' = 160,
  'Pause05_Unterhaltung_ohne_Ton' = 155,
  'Pause06_kitag_ohne_Ton' = 150,
  'Pause07_Countdown_ohne_Ton' = 145,
  'Pause08_Countdown_mit_Ton' = 140,
  'Pause09_Presenter_Outro' = 135,
  'Pause10_Outro' = 130
}
