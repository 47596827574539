import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangelogInfoComponent } from './changelog-info/changelog-info.component';
import { MatListModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatListModule
  ],
  declarations: [ChangelogInfoComponent],
  exports: [ChangelogInfoComponent]
})
export class ChangelogModule { }
