import { ChangeUserPasswordRequestModel } from './../../../../models/change-user-password-request-model';
import { AssignUserToRolesRequestModel } from './../../../../models/assign-user-to-roles-request-model';
import { SpotlogRole } from './../../../../models/enums/spotlog-role.enum';
import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { DxFormComponent, DxListComponent } from 'devextreme-angular';
import { MAT_DIALOG_DATA } from '@angular/material';
import { SpotlogUserModel } from '../../../../models/spotlog-user-model';
import { ApiUrlService } from '../../../../shared/services/api-url.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import notify from 'devextreme/ui/notify';
import { DxGridHeading } from '../../../../helpers/dx-grid-heading';


@Component({
  selector: 'app-user-management-master-detail',
  templateUrl: './user-management-master-detail.component.html',
  styleUrls: ['./user-management-master-detail.component.scss']
})
export class UserManagementMasterDetailComponent implements OnInit, AfterViewInit {


  @ViewChild(DxFormComponent) form: DxFormComponent

  @ViewChild('userRoles') list: DxListComponent

  public dataModel: SpotlogUserModel;
  private techSpotlogUserId: number;
  public password: string;
  public colCountByScreen: Object;

  public selectedItems: SpotlogRolesModel[] = [];
  public allowDeleting: boolean = false;
  public deleteType: string = "toggle";
  public tasks: SpotlogRolesModel[] = [{ id: SpotlogRole.Administrator, name: SpotlogRole[SpotlogRole.Administrator] }
    , { id: SpotlogRole.CinemaRelations, name: SpotlogRole[SpotlogRole.CinemaRelations] },
  { id: SpotlogRole.CinemaStaff, name: SpotlogRole[SpotlogRole.CinemaStaff] }
    , { id: SpotlogRole.TMSsystem, name: SpotlogRole[SpotlogRole.TMSsystem] }];

  buttonOptions: any = {
    text: this.translateService.instant('Ändern'),
    type: "success",
    useSubmitBehavior: true
  }

  assignUserToRolesButtonOptions: any = {
    text: this.translateService.instant('Rollen ändern'),
    type: "success",
    useSubmitBehavior: false,

  }

  public spotlogUserCinemasData: any[] = [];
  public columns: DxGridHeading[] = [];
  public portalPermissions: string[] = [];

  constructor(public urlApiService: ApiUrlService, public httpClient: HttpClient, private translateService: TranslateService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.techSpotlogUserId = data.techSpotlogUserId;
    this.colCountByScreen = {
      md: 2,
      sm: 1
    };
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.updateSpotlogUserCinemasDataGrid();
    this.requestUserData();
  }

  requestUserData() {
    this.httpClient.post(this.urlApiService.getSpotlogUserInformationById(), this.techSpotlogUserId).subscribe(
      data => {
        this.dataModel = data as SpotlogUserModel;
        this.spotlogUserCinemasData = this.dataModel.spotlogUserCinemas;
        this.portalPermissions = this.dataModel.portalPermissions;
        this.selectedItems = [];
        for (let index = 0; index < this.dataModel.applicationRoles.length; index++) {
          const element = this.dataModel.applicationRoles[index] as SpotlogRole;
          switch (element) {
            case SpotlogRole.Administrator:
              this.list.instance.selectItem(0);
              break;
            case SpotlogRole.CinemaRelations:
              this.list.instance.selectItem(1);
              break;
            case SpotlogRole.CinemaStaff:
              this.list.instance.selectItem(2);
              break;
            case SpotlogRole.TMSsystem:
              this.list.instance.selectItem(3);
              break;
            default:
              break;
          }

        }
      },
      err => {
        console.log('Error occured: ' + err);
      }
    )
  }

  updateSpotlogUserCinemasDataGrid() {
    this.columns.length = 0;
    this.columns.push(
      { caption: 'TheaterNr', dataField: 'theaterNr' },
      { caption: 'Name', dataField: 'name1' },
      { caption: 'Postleitzahl', dataField: 'zipCode' },
      { caption: 'Stadt', dataField: 'city' },
      { caption: 'Strasse', dataField: 'street' },
      { caption: 'Land', dataField: 'country' },
    );
  }

  assignUserToRoles(e) {
    let currentRoles = this.selectedItems;
    let request: AssignUserToRolesRequestModel = new AssignUserToRolesRequestModel();
    request.techSpotlogUserId = this.techSpotlogUserId;
    request.applicationRoles = [];
    for (let index = 0; index < currentRoles.length; index++) {
      const element = currentRoles[index].id;
      request.applicationRoles.push(element)
    }

    this.httpClient.post(this.urlApiService.getAssignUserToRolesUrl(), request).subscribe(
      data => {
        notify({
          message: this.translateService.instant('Ihre Daten wurden erfolgreich gespeichert. Danke!'),
          position: {
            my: "center top",
            at: "center top"
          }
        }, "success", 3000);
      },
      err => {
        notify({
          message: this.translateService.instant('Es ist ein Fehler aufgetreten!'),
          position: {
            my: "center top",
            at: "center top"
          }
        }, "error", 3000);
      }
    )

  }

  onPasswordChange(e) {
    let newPasword = this.password;
    const regexp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,15}$/);
    const passwordIsValid = regexp.test(newPasword);

    if (passwordIsValid) {

      let request: ChangeUserPasswordRequestModel = new ChangeUserPasswordRequestModel();
      request.techSpotlogUserId = this.techSpotlogUserId;
      request.newPassword = newPasword;
      this.httpClient.post(this.urlApiService.getChangeUserPasswordUrl(), request).subscribe(
        data => {
          notify({
            message: this.translateService.instant('Passwort wurde erfolgreich geändert. Danke!'),
            position: {
              my: "center top",
              at: "center top"
            }
          }, "success", 3000);
        },
        err => {
          notify({
            message: this.translateService.instant('Es ist ein Fehler aufgetreten! Passwort wurde nicht geändert'),
            position: {
              my: "center top",
              at: "center top"
            }
          }, "error", 3000);
        }
      )
    } else {
      notify({
        message: this.translateService.instant('Ihr Passwort muss mindestens 8 und maximal 15 Zeichen lang sein und muss mindestens einen Großbuchstaben, einen Kleinbuchstaben und eine Ziffer oder ein Sonderzeichen enthalten.'),
        position: {
          my: "center top",
          at: "center top"
        }
      }, "error", 3000);
    }
  }

  form_fieldDataChanged(e) {

    /*if (e.dataField == 'screenCount') {
      if (e.value > 0) {
      }
    }*/
  }

  onFormSubmit(e) {
    let formData = this.form.formData as SpotlogUserModel;
    if (formData.active) {
      this.httpClient.post(this.urlApiService.getEnableUserUrl(), this.techSpotlogUserId).subscribe(
        data => {
          notify({
            message: this.translateService.instant('USer wurde erfolgreich aktiviert. Danke!'),
            position: {
              my: "center top",
              at: "center top"
            }
          }, "success", 3000);
        },
        err => {
          notify({
            message: this.translateService.instant('Es ist ein Fehler aufgetreten!'),
            position: {
              my: "center top",
              at: "center top"
            }
          }, "error", 3000);
        }
      )

    } else {
      this.httpClient.post(this.urlApiService.getDisableUserUrl(), this.techSpotlogUserId).subscribe(
        data => {
          notify({
            message: this.translateService.instant('User wurde erfolgreich deaktiviert. Danke!'),
            position: {
              my: "center top",
              at: "center top"
            }
          }, "success", 3000);
        },
        err => {
          notify({
            message: this.translateService.instant('Es ist ein Fehler aufgetreten!'),
            position: {
              my: "center top",
              at: "center top"
            }
          }, "error", 3000);
        }
      )
    }
    console.log(e);
  }

  screen(width) {
    return width < 720 ? "sm" : "md";
  }

}

export class SpotlogRolesModel {
  id: SpotlogRole;
  name: string;
}
