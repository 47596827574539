
import {map} from 'rxjs/operators';
import { HistoricDownloadPdfRequestModel } from './../../models/historic-download-pdf-request-model';
import { TranslationStarttimePlayout } from './../../models/enums/translation-starttime-playout.enum';
import { PdfDocumentModel } from './../../models/pdf-document-model';
import { PdfRowsModel } from './../../models/pdf-rows-model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { PlayoutInformationRequestModel } from '../../models/playout-information-request-model';
import { PlayoutsRequestModel } from '../../models/playouts-request-model';
import { PortalPlayoutInformationModel } from '../../models/portal-playout-information-model';
import { ApiUrlService } from './api-url.service';
import { CinemaModel } from '../../models/cinema-model';
import { ScreenModel } from '../../models/screen-model';
import { CineWeekModel } from '../../models/cine-week-model';
import { TranslationPositionPlayout } from '../../models/enums/translation-position-playout.enum';
import { TranslationBlock } from '../../models/enums/translation-block.enum';

@Injectable({
  providedIn: 'root'
})
export class PlayoutPdfService {

  constructor(public httpClient: HttpClient, public urlService: ApiUrlService, private translateService: TranslateService) { }

  createReport(pdfDocumentModel: PdfDocumentModel): Observable<any> {

    return this.httpClient.post(this.urlService.getCreateReport(), pdfDocumentModel,
      {
        reportProgress: true,
        responseType: 'blob'
      }
    ).pipe(map((response: any) => {
      return <any[]>response;
    }
    ));
  }

  createReports(pdfDocumentModels: PdfDocumentModel[]): Observable<any> {

    return this.httpClient.post(this.urlService.getCreateMultipleReport(), pdfDocumentModels,
      {
        reportProgress: true,
        responseType: 'blob'
      }
    ).pipe(map((response: any) => {
      return <any[]>response;
    }
    ));
  }

  createHistoricReport(request: HistoricDownloadPdfRequestModel): Observable<any> {

    return this.httpClient.post(this.urlService.getCreateHistoricDownloadPDF(), request,
      {
        reportProgress: true,
        responseType: 'blob'
      }
    ).pipe(map((response: any) => {
      return <any[]>response;
    }
    ));
  }

  getPlayoutInformation(techScreenId: number, selectedCineWeek: any): Observable<PortalPlayoutInformationModel[]> {
    const utcDate = moment(selectedCineWeek).local().format('YYYY-MM-DD HH:mm:ss.000');

    const playoutInformationRequest: PlayoutInformationRequestModel = new PlayoutInformationRequestModel();
    playoutInformationRequest.TechScreenId = techScreenId;
    playoutInformationRequest.Date = utcDate;

    // tslint:disable-next-line:max-line-length
    return this.httpClient.post(this.urlService.getPlayoutInformation(), playoutInformationRequest).pipe(map((response: PortalPlayoutInformationModel[]) => <PortalPlayoutInformationModel[]>response));

  }

  getFirstPlayoutInformation(techCinemaId: number, cineWeekStart: any): Observable<PortalPlayoutInformationModel[]> {
    const playoutsRequest: PlayoutsRequestModel = new PlayoutsRequestModel();
    playoutsRequest.TechCinemaId = techCinemaId;
    const utcDate = moment(cineWeekStart).local().format('YYYY-MM-DD HH:mm:ss.000');
    playoutsRequest.Date = utcDate;

    // tslint:disable-next-line:max-line-length
    return this.httpClient.post(this.urlService.getPlayouts(), playoutsRequest).pipe(map((response: PortalPlayoutInformationModel[]) => <PortalPlayoutInformationModel[]>response));
  }

  getColumns(isFirstPlayout: boolean = false): string[] {
    const result: string[] = [];
    if (!isFirstPlayout) {
      result.push(this.translateService.instant('BlockId'));
      result.push(this.translateService.instant('orderColumn'));
    }
    result.push(this.translateService.instant('Name'));
    if (!isFirstPlayout) {
      result.push(this.translateService.instant('Position'));
    }
    // Playout Id
    result.push(this.translateService.instant('dcnColumn'));
    result.push(this.translateService.instant('Altersfreigabe'));
    if (!isFirstPlayout) {
      result.push('startingNumber');
      result.push(this.translateService.instant('Starttime'));
      result.push(this.translateService.instant('Saal/Film'));
    }
    result.push(this.translateService.instant('Stumm'));
    result.push(this.translateService.instant('RunningTime'));
    return result;
  }

  getColumnNames(isFirstPlayout: boolean = false): string[] {
    const result: string[] = [];
    if (!isFirstPlayout) {
      result.push('blockId');
      result.push('counter');
    }
    result.push('annotationText');
    if (!isFirstPlayout) {
      result.push('position');
    }
    result.push('playoutId');
    result.push('rating');
    if (!isFirstPlayout) {
      result.push('startingNumber');
      result.push('startTime');
      result.push('featureExclusive');
    }
    result.push('mute');
    result.push('runningTime');
    return result;
  }

  getMovies(data: PortalPlayoutInformationModel[], cinema: CinemaModel): PortalPlayoutInformationModel[] {
    let movieTitles: PortalPlayoutInformationModel[] = [];
    const allMovies: PortalPlayoutInformationModel = new PortalPlayoutInformationModel();
    allMovies.featureTitle = this.translateService.instant('screenBookingTitle');
    allMovies.techContentDataIdFeature = -1;
    allMovies.featureExclusive = true;
    allMovies.guiCaption = allMovies.featureTitle;
    let result: PortalPlayoutInformationModel[] = _.uniqBy(data, 'techContentDataIdFeature');
    result = _.orderBy(result, ['featureTitle'], ['asc']);
    for (let index = 0; index < result.length; index++) {

      let subtitle = '';
      if (result[index].featureLanguageSubtitles) {
        subtitle = '[' + this.translateService.instant('Subtitle') + ':' + result[index].featureLanguageSubtitles + ']';
      }
      const featureLanguageAudioResult = _.isEmpty(result[index].featureLanguageAudio) ? '' : result[index].featureLanguageAudio + ', ';
      result[index].guiCaption = result[index].featureTitle + '(' + featureLanguageAudioResult + result[index].featureDepth + ')' + subtitle;

      if (cinema.country === 'CH' && result[index].featureSuisa !== '') {
        result[index].guiCaption += ', Suisa: ' + result[index].featureSuisa;
      }
      if (cinema.country === 'DE' && result[index].featureFilmRRC) {
        result[index].guiCaption += ', FilmRRC: ' + result[index].featureFilmRRC;
      }
      if (result[index].event) {
        result[index].guiCaption += ', Event';
      }
    }
    result.unshift(allMovies);
    movieTitles = _.remove(result, (x: PortalPlayoutInformationModel) => x.featureExclusive === true);

    return movieTitles;
  }

  generatePdfRows(playouts: PortalPlayoutInformationModel[], isFirstPlayout: boolean = false): PdfRowsModel[] {
    const result: PdfRowsModel[] = [];
    for (let index = 0; index < playouts.length; index++) {
      const pdfRowsModel: PdfRowsModel = new PdfRowsModel();
      const element = playouts[index];
      if (!isFirstPlayout) {
        pdfRowsModel.columnValues.push(element.translatedBlockId);
        pdfRowsModel.columnValues.push(element.counter.toString());
      }
      pdfRowsModel.columnValues.push(element.annotationText);
      if (!isFirstPlayout) {
        pdfRowsModel.columnValues.push(element.translatedPosition);
      }
      pdfRowsModel.columnValues.push(element.playoutId.toString());
      pdfRowsModel.columnValues.push(element.rating);
      if (!isFirstPlayout) {
        pdfRowsModel.columnValues.push(element.startingNumber.toString());
        pdfRowsModel.columnValues.push(element.translatedStartTime);
        pdfRowsModel.columnValues.push(element.featureExclusive ? 'F' : 'S');
      }
      pdfRowsModel.columnValues.push(element.translatedMute);
      pdfRowsModel.columnValues.push(element.runningTime.toString());
      result.push(pdfRowsModel);
    }
    return result;
  }

  generatedHeader1(cinemaModel: CinemaModel, screenModel: ScreenModel, isFirstPlayout = false): string {
    if (isFirstPlayout) {
      return this.translateService.instant('Einschaltanweisung für Theater', { value: cinemaModel.theaterNr, value1: cinemaModel.name1 });
    }
    return this.translateService.instant('Einschaltanweisung für Theater', { value: cinemaModel.theaterNr, value1: screenModel.name1 });
  }
  generatedHeader2(cinemaModel: CinemaModel): string {
    return cinemaModel.city;
  }
  generatedHeader3(cineWeekModel: CineWeekModel) {
    return this.translateService.instant('Spielwoche', { 0: cineWeekModel.week, 1: cineWeekModel.year });
  }
  generatedHeader4(cineWeekModel: CineWeekModel) {
    return moment(cineWeekModel.displayStart).local().format('DD.MM') + ' - ' + moment(cineWeekModel.displayEnd).local().format('DD.MM.YYYY');
  }
  generatedHeader5() {

  }

  private translatePosition(positionId: number) {
    return this.translateService.instant(TranslationPositionPlayout[positionId]);
  }

  private translateBlockId(blockId: number) {
    return this.translateService.instant(TranslationBlock[blockId]);
  }

  private translateStartTime(id: number) {
    return this.translateService.instant(TranslationStarttimePlayout[id]);
  }

  generateTranslation(techCinemaId: number, lastDayCineWeek: string): HistoricDownloadPdfRequestModel {
    let result = new HistoricDownloadPdfRequestModel();
    result.techCinemaId = techCinemaId;
    result.lastDayCineWeek = lastDayCineWeek;

    result.textOther['BlockId'] = this.translateService.instant('BlockId');
    result.textOther['orderColumn'] = this.translateService.instant('orderColumn');
    result.textOther['Name'] = this.translateService.instant('Name');
    result.textOther['Position'] = this.translateService.instant('Position');
    result.textOther['dcnColumn'] = this.translateService.instant('dcnColumn');
    result.textOther['Altersfreigabe'] = this.translateService.instant('Altersfreigabe');
    result.textOther['Starttime'] = this.translateService.instant('Starttime');
    result.textOther['RunningTime'] = this.translateService.instant('RunningTime');
    result.textOther['Einschaltanweisung für Theater'] = this.translateService.instant('Einschaltanweisung für Theater', {value: '{0}', value1: '{1}'});
    result.textOther['Spielwoche'] = this.translateService.instant('Spielwoche', {0: '{0}', 1: '{1}'});
    result.textOther['screenBookingTitle'] = this.translateService.instant('screenBookingTitle');
    result.textOther['Subtitle'] = this.translateService.instant('Subtitle');
    result.textOther['Saal/Film'] = this.translateService.instant('Saal/Film');
    result.textOther['Stumm'] = this.translateService.instant('Stumm');

    result.textSaalFilm['Film'] = 'F';
    result.textSaalFilm['Saal'] = 'S';

    result.textMute['true'] = this.translateService.instant('yesShort');
    result.textMute['false'] = this.translateService.instant('noShort');
    result.textMute[' '] = this.translateService.instant('noShort');

    for (let item in TranslationBlock) {
      if (Number(item)) {
        result.textBlock[Number(item)] = this.translateBlockId(Number(item));
      }
    }
    for (let item in TranslationPositionPlayout) {
      if (Number(item)) {
        result.textPositionPlayout[Number(item)] = this.translatePosition(Number(item));
      }
    }
    for (let item in TranslationStarttimePlayout) {
      if (Number(item)) {
        result.textZeitschiene[Number(item)] = this.translateStartTime(Number(item));
      }
    }
    return result;
  }
}
