import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./shared/modules/guard/auth-guard";
import { MaintenancePageComponent } from "./layouts/maintenance-page/maintenance-page.component";

export const routes: Routes = [
  { path: "", loadChildren: "./layouts/layouts.module#LayoutsModule" },
  { path: "login", loadChildren: "./login/login.module#LoginModule" },
  { path: "maintenance", component: MaintenancePageComponent },
  { path: ":technikformular", loadChildren: "./login/login.module#LoginModule" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
